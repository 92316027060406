@import '../../../../assets/styles/color';

.DocMasterDataDrawer {
  & .Divider {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: $border-color;
    margin: 2rem 0 30px 0;
  }

  & .Path {
    font-size: 12px;
    font-weight: 600;
    color: $form-label-color;
  }
  & .PathValue {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
    color: $main-text-color;
    & .NewPathValue {
      color: $primary-color;
    }
  }
}