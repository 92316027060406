@import '../../../../assets/styles/color';

.NotificationDropdown {
    display: flex;
    align-items: center;
}

.MenuClickable {
    cursor: pointer;
    position: relative;
    & .Badge {
        position: absolute;
        right: -4px;
        top: -4px;
        background-color: $danger-color;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
    }

    & .MenuIcon {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, .1);

        &:hover {
            background-color: rgba(255, 255, 255, .2);
        }

        & .NotifIcon {
            width: 18px;
            height: 18px;
        }
    }
}

.DropdownContainer {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 0;
    left: 0;
    top: 0;
}

.Dropdown {
    position: relative;
    display: inline-block;
    transition: transform .3s ease-in-out;

    & ul {
        visibility: hidden;
        opacity: 0;
        transition: visibility .3s ease-out, opacity .2s ease-out;
        list-style-type: none;
        padding: 0;
        right: -70px;
        top: 0px;
        width: 360px;
        background-color: $card-bg-color;
        font-weight: bold;
        position: absolute;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1000;
        border-radius: 4px;
        overflow: auto;
        max-height: calc(100vh - 85px);
    }

    & .HeaderNotifWrapper {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding: 15px;

        & .Header {
            font-size: 20px;
            font-weight: '600';
        }

        & .Mark {
            color: $primary-color;
            font-size: 14px;
            text-decoration: underline;
            cursor: pointer;
            user-select: none;
        }
    }

    & li {
        margin: 0;
        cursor: pointer;
        border-top: 1px solid $main-container-bg-color;
        color: $card-text-color;
        text-decoration: none;
        // border-left: 3px solid $card-bg-color;
        border-radius: 4px;
        &.Unread {
            background-color: #1665d833;
            &:hover {
                background-color: #1665d855;
            }
        }
        &.Read {
            background-color: #2b2b30;
                &:hover {
                background-color: #35353cBB;
            }
        }
    }

    & li:hover {
        // background: $main-container-bg-color;
        // border-left: 3px solid $primary-color;
        // border-radius: 4px;
    }

    & ul::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: 76px;
        border-width: 15px;
        border-style: solid;
        border-color: transparent transparent $card-bg-color transparent;
    }
}

.DropdownIn {
    transform: translateY(23px);

    & ul {
        visibility: visible;
        opacity: 1;
    }
}

.DropdownOut {
    transform: translateY(10px);

    & ul {
        visibility: hidden;
        opacity: 0;
    }
}

.Content {
    white-space: pre-wrap;
    color: $sub-text-color;
    word-break: break-word;
    &.Unread {
        color: $main-text-color;
    }
}

.Time {
    margin-top: 10px;
    font-size: 12px;
    color: $sub-text-color;
    &.Unread {
        color: $primary-color;
    }
}

.Logout {
    color: $danger-color;
}

.Divider {
    background-color: $border-color;
    height: 40px;
    width: 1px;
    margin: 0 30px;
}

.ModalContent {
    color: $modal-text-color;
    padding: 30px;
    & .Topic {
        font-weight: 600;
        font-size: 40px;
        color: $primary-color;
    }
    & .Posted {
        font-size: 12px;
        margin-top: 5px;
        color: $sub-text-color;
    }
    & .ContentDivider {
        border: 0;
        border-top: 2px solid $primary-color;
    }
    & .Content {
        font-size: 18px;
        margin-top: 30px;
        color: $modal-text-color;
        line-height: 27px;
    }
}