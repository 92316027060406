@import "../../../../assets/styles/color";


.WidgetDrawer {
  & .LineFormGroup {
    margin-bottom: 38px;
    display: flex;
  }

  & p.Title {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $form-label-color;
    margin-bottom: 10px;
  }

  & label.Title {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $form-label-color;
    margin-bottom: 10px;
  }

  & p.FormulaOutput {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    // color: $form-label-color;
    color: $main-text-color;
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 1%;
  }

  & .FormGroup {
    margin-top: 15px;
    & .FormField {
      -webkit-appearance: none;
      appearance: none;
      display: block;
      font-family: inherit;
      width: 100%;
      padding: 10px 16px;
      font-size: 14px;
      border-radius: 4px;
      border: solid 2px $border-color;
      background-color: $main-container-bg-color;
      color: $main-text-color;
      transition: border-color .15s ease-in-out;
      box-sizing: border-box;
      outline: none;
      resize: none;
      &:hover, &:focus {
        border-color: $primary-color;
      }
    }
  }

  .AddPowerPriceButton {
    color: $primary-color;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    border: 1px dashed $primary-color;
    text-align: center;
    padding: 10px 0;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px 0;
  
    & .AddIcon {
      margin-right: 10px;
      vertical-align: middle;
    }
    & .ButtonName {
      display: inline-block;
    }
  }

  & .UploadPlaceholder {
    background: "#fbfbfd";
    width: "100%";
    height: "240px";
    display: 'flex';
    flex-direction: "column";
    justify-content: "center";
    align-items: "center";
    cursor: "pointer";
  }
}

.UploadPlaceholder {
  background: "#fbfbfd";
  width: "100%";
  height: "240px";
  display: 'flex';
  flex-direction: "column";
  justify-content: "center";
  align-items: "center";
  cursor: "pointer";
}

