@import "../../../../../assets/styles/color";

.HeaderSection {
    padding: 20px 30px;
    border-bottom: 1px solid $border-color;
  
    & .HeaderText {
      color: $main-text-color;
      font-size: 20px;
      font-weight: 600;
    }
    & .SubHeaderText {
      color: $sub-text-color;
      font-size: 12px;
      font-weight: 500;
      margin-top: 5px;
    }

    & .HeaderButtonWrappper {
        position: absolute;
        color: $sub-text-color;
        right: 23px;
        top: 12px;
        font-size: 32px;
        display: flex;
        align-items: flex-start;
    
        & .Btn {
          cursor: pointer;
          margin: 5px;
        }
    }
}


.BodySection {
    height: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto auto auto;
    grid-auto-rows: 110px;
  
    & .DataCard {
        border: 1px solid $border-color;
        border-radius: 5px;
        box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
        text-align: center;
        padding: 10px;

        & .DataCardName {
            font-size: 14px;
            font-weight: 600;
            color: $main-text-color;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        & .DataCardValue {
            font-size: 23px;
            font-weight: 600;
            color: $main-text-color;
            margin-top: 12px;
        }

        & .DataCardTime {
            font-size: 12px;
            font-weight: 500;
            color: $sub-text-color;
            margin-top: 5px;
        }
    }
}