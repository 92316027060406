@import "../../../../assets/styles/color";

.Auth {
  background-color: $main-container-bg-color;
  height: 100vh;
  & .AuthBoxContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    max-width: 889px;
    max-height: 652px;
    border-radius: 5px;
    background-color: #ffffff;
  }
}

.ResetPass {
  display: flex;
  height: 100%;
}

.None {
  display: none;
}

.MiddleCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.LeftSection {
  flex: 1;
  & .AuthWrapper {
    padding: 100px 40px 40px 40px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    position: relative;
    & .AuthTitle {
      font-size: 22px;
      font-weight: bold;
      color: $auth-title-color;
    }
    & .AuthSubTitle {
      color: $sub-text-color;
      margin: 5px 0 20px 0;
    }
    & .FormContainer {
      display: flex;
      flex-direction: column;
      width: 70%;
      height: 100%;
      color: $sub-text-color;
      & p {
        font-weight: 500;
      }
      & .SwapText {
        cursor: pointer;
        color: $primary-color;
        font-weight: 600;
      }
    }
  }
}

.RightSection {
  position: relative;
  width: 565px;
  & .Footer{
    position: absolute;
    bottom: 40px;
    left: 64px;
    font-size: 16px;
    font-weight: 600;
  }
  & p {
    color: $main-text-color;
  }
}

.FormGroup {
  margin-bottom: 1rem;

  & label.Title {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: $sub-text-color;
    margin-bottom: 0.5rem;
  }
  & .FormField {
    display: block;
    font-family: inherit;
    width: 100%;
    padding: 10px 16px;
    font-size: 14px;
    border-radius: 4px;
    border: solid 1px #e2e5ed;
    color: $auth-title-color;
    transition: border-color 0.15s ease-in-out;
    box-sizing: border-box;
    outline: none;
    resize: none;
    &:hover {
      border-color: $primary-color;
    }
    &:focus {
      border-color: $primary-color;
    }
    &.Error {
      border-color: $danger-color;
    }
  }
  & .ErrorText {
    color: $danger-color;
    font-size: 12px;
    font-weight: 500;
    line-height: 2;
  }
  & .SeePassword {
    position: absolute;
    top: 10px;
    right: 12px;
    cursor: pointer;
  }
}

.ErrorMessage {
  color: $danger-color;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}
