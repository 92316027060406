@import '../../../../assets/styles/color';

.PageUrlInput {
  background-color: $navigation-bg-color;
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid $border-color;
  color: $primary-color;
  font-size: 14px;
  font-family: inherit;
  transition: border-color .15s ease-in-out;
  box-sizing: border-box;
  outline: none;
  resize: none;
  width: 100%;
  padding: 8px 16px;
  &:hover, &:focus {
    border-bottom-color: $primary-color;
  }
  &.Error {
    border-bottom: 1px solid $danger-color;
  }
}

.ErrorMessage {
  color: $danger-color;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}
