@import "../../../../assets/styles/color";

.ModbusWrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  
  & .Title {
    flex: 1;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $form-label-color;
  }
}