@import "../../../../assets/styles/color";

.LabelGroup {
    display: flex;
    margin-bottom: 1rem;

    & .Label {
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: $form-label-color;
    }

    & .Info {
        margin: auto 10px;
    }

    & .ValueTitle {
        font-size: 16px;
        font-weight: 600;

        & span {
            font-size: 14px;
            opacity: 0.75;
        }
    }
}

.Divider {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: $border-color;
    margin: 16px 0;
}

.ChildrenText {
    font-size: 14px;
    font-weight: 600;
    color: $main-text-color;
}

.DeleteChildButton {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
}

.Warning {
    font-size: 11px;
    font-weight: 500;
    color: $danger-color;
    margin-top: 5px;
}