@import '../../../assets/styles/color';

.VirtualDevice {
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  min-height: calc(100vh - 71px);

  & .VirtualDeviceTopics {
    flex: 0 1 500px;
    background-color: $navigation-bg-color;
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;

    & .VirtualDeviceFix {
      position: fixed;
      width: 500px;
      overflow: overlay;
      height: calc(100% - 70px);
      & .TopMenuVirtualDevice {
        display: flex;
        border-bottom: 1px solid $border-color;
        & .MenuWrapper {
          flex: 1;
          display: flex;
          margin-left: 30px; 
          & .Menu {
            display: flex;
            align-items: center;
            border-bottom: 3px solid $primary-color;
            & .MenuTitle {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
        & .AddButtonWrapper {
          margin: 14px 30px;
          width: 220px;
        }
      }
    }
  }
  & .VirtualDeviceDetails {
    flex: 1;
    padding: 30px;
  }
}

.LoadingCard{
  --card-padding: 30px;
  --card-height: 82px;
  --card-skeleton: linear-gradient(#222226 var(--card-height), transparent 0);

  --desc-line-height: 12px;
  --desc-line-skeleton: linear-gradient(rgba(107, 109, 112, 0.15) var(--desc-line-height), transparent 0);
  --desc-line-1-width:100px;
  --desc-line-1-position: var(--card-padding) 27px;
  --desc-line-2-width:160px;
  --desc-line-2-position: var(--card-padding) 47px;

  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(var(--card-height) - 40px);

  width: 100%; //demo
  height: var(--card-height);
  border-bottom: 1px solid $border-color;
  border-radius: 5px;

  &:empty::after {
    content:"";
    display:block;
    width: 100%;
    height: 100%;
    border-radius:6px;
    // box-shadow: 0 10px 45px rgba(0,0,0, .1);

    background-image:
      linear-gradient(
        90deg, 
        rgba(#222226, 0) 0, 
        rgba(#222226, .8) 50%, 
        rgba(#222226, 0) 100%
      ),                          //animation blur
      var(--desc-line-skeleton),  //desc1
      var(--desc-line-skeleton),  //desc2
      var(--card-skeleton)        //card
    ;

    background-size:
      var(--blur-size),
      var(--desc-line-1-width) var(--desc-line-height),
      var(--desc-line-2-width) var(--desc-line-height),
      100% 100%
    ;
    
    background-position:
      -100% 50%,                      //animation
      var(--desc-line-1-position),  //desc1
      var(--desc-line-2-position),  //desc2
      0 0                           //card
    ;

    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  to {
    background-position:
      100% 50%,       
      var(--desc-line-1-position),
      var(--desc-line-2-position),
      0 0
    ;
  }
}