@import '../../../../assets/styles/color';

.ListWrapper {
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 350px));
  grid-gap: 30px;

  & .VariableBox {
    width: 350px;
    min-height: 150px;
    border-radius: 4px;
    background-color: $card-bg-color;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    & .TopSection {
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;
      & .Name {
        flex: 1;
        color: $main-text-color;
        font-size: 24px;
        font-weight: bold;
      }

      & .Icon {
        cursor: pointer;
        width: 15px;
      }
    }
  }

  & .VariableValue {
    flex: 1;
    color: $sub-text-color;
    font-size: 16px;
    word-break: break-all;
  }

   & .SubText {
    margin-top: 10px;
    color: $sub-text-color;
    font-size: 11px;
   }
}