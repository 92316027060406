@import '../../../../assets/styles/color';

.Auth {
  background-color: $main-container-bg-color;
  height: 100vh;
  & .AuthBoxContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    max-width: 889px;
    max-height: 652px;
    border-radius: 5px;
    background-color: #ffffff;
    
    & .SwapSignUp {
      text-align: center;
      margin-top: 35px;
      font-size: 14px;
      color: $card-text-color;
      & .SwapText {
        cursor: pointer;
        color: $primary-color;
        font-weight: 600;
      }
    }
  }
}



