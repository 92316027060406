@import "../../../assets/styles/color";

.ProfileContainer {
  margin: 0 30px 30px 0;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px $border-color;
  background-color: $navigation-bg-color;
  font-family: Montserrat;
  color: $card-text-color;

  & .Header {
    padding: 20px 30px;
    border-bottom: 1px solid $card-bg-color;
    & .Title {
      color: $card-text-color;
      font-size: 18px;
      font-weight: 600;
    }
    & .SubTitle {
      color: $sub-text-color;
      font-size: 14px;
    }
  }
  & .FormInput {
    padding: 30px;
    & .FormGroup {
      flex: 1;
      margin-bottom: 10px;
    }
  }
  & .Footer {
    padding: 20px 30px 5px 30px;
    border-top: 1px solid $card-bg-color;
    & .ButtonWrapper {
      width: 145px;
    }
  }
}

label.Title {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: $form-label-color;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.FormField {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  font-family: inherit;
  width: 100%;
  padding: 10px 16px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 2px $border-color;
  background-color: $main-container-bg-color;
  color: $main-text-color;
  transition: border-color 0.15s ease-in-out;
  box-sizing: border-box;
  outline: none;
  resize: none;
  &:disabled{
    color: $form-label-color;
  }
}

.ErrorText {
  color: $danger-color;
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
}

.ErrorMessage {
  color: $danger-color;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}

.None {
  display: none;
}
