@import '../../../../assets/styles/color';

.VerifyEmail {
  display: flex;
  height: 100vh;
  & .LeftSection {
    flex: 1;
    background-color: $light-bg-color;
    color: $navigation-bg-color;
    padding: 12%;
    text-align: left;
    display: flex;
    flex-direction: column;
    & .Header {
      font-size: 50px;
      margin-bottom: 60px;
      font-weight: 600;
    }

    & .Description {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.75;
      & .Email {
        color: $auth-button-color;
        font-weight: 600;
      }
    }
  }

  & .RightSection {
    position: relative;
    width: 565px;
    & .Footer{
      position: absolute;
      bottom: 98px;
      left: 124px;
      font-size: 22px;
      font-weight: 600;
      & p {
        color: $main-text-color;
      }
    }
  }
}