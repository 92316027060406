@import "../../../../../assets/styles/color";

.DeleteButton {
	border: 2px solid $danger-color;
	color: $danger-color;
	border-radius: 4px;
	margin: 29px 0 20px 10px;
	padding: 36px 7px;
	font-weight: bold;
	cursor: pointer;
	&.ForSequence {
		margin: 0 0 0 10px;
		padding: 10px 7px;
	}
}

.SwitchTypeWrapper {
	padding: 15px 15px 10px 15px;
	flex: 1;
	background-color: $main-container-bg-color;
	border: 2px solid $border-color;
	border-radius: 4px;
	cursor: pointer;
	&.Selected {
	  border-color: $primary-color;
	}
  
	& .Title {
	  color: $form-label-color;
	  font-size: 14px;
	  font-weight: 500;
	  &.Selected {
		color: $card-text-color;
	  }
	}
  }