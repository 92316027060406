@import "../../../../assets/styles/color";

.EventHistory {
    position: relative;
}

.TableWrapper {
    margin: 30px 0;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid $border-color;
    background-color: $navigation-bg-color;
    overflow-x: auto;
    & table {
      border-collapse: collapse;
      width: 100%;
      text-align: left;
    }

    & tr {
      overflow-x: scroll;
    }
          
          & tbody tr:hover {
              background-color: $border-color;
          }
    
    & th, td {
      padding: 10px;
    }
    
    & th {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      color: $form-label-color;
    }

    & td {
      font-size: 14px;
      font-weight: 500;
    }
}

.Note {
    font-weight: 500;
    font-size: 11px;
    color: $sub-text-color;
}

.LevelLegend {
    margin-right: 8px;
    width: 6px;
    height: 6px;
    border-radius: 4px;
    &.Info {
        background-color: $primary-color;
    }
    &.Alert {
        background-color: $yellow-color;
    }
    &.Warning {
        background-color: $orange-color;
    }
    &.Critical {
        background-color: $danger-color;
    }
    &.Breakdown {
        background-color: $green-color;
    }
}

.LevelBg {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 4px;
    &.Info {
        background-color: #1665d820;
    }
    &.Alert {
        background-color: #facf5520;
    }
    &.Warning {
        background-color: #f6ab2f20;
    }
    &.Critical {
        background-color: #e6492d20;
    }
    &.Breakdown {
        background-color: #34aa4420;
    }
}

.LevelLabel {
    &.Info {
        color: $primary-color;
    }
    &.Alert {
       color: $yellow-color;
    }
    &.Warning {
        color: $orange-color;
    }
    &.Critical {
        color: $danger-color;
    }
    &.Breakdown {
        color: $green-color;
    }
}