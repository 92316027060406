@import '../../../../assets/styles/color';

.Card {
  width: 255px;
  height: 315px;
  border-radius: 5px;
  background-color: $card-bg-color;
  border: 1px solid $card-bg-color;
  justify-self: center;
  box-sizing: border-box;
  position: relative;
  transition: transform 0.15s ease-in;
  overflow: hidden;
  &:hover {
    border: 1px solid $primary-color;
    cursor: pointer;
  }

  &.CardAddingStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & .AddIcon {
      margin-bottom: 20px;
    }
  }
  &.CardFlexStyle {
    display: flex;
    flex-direction: column;
  }
  & .CardBody {
    padding: 0 30px;
    &.Flex {
      flex: 1;
    }
    & .CardTitle {
      color: $card-text-color;
      font-size: 18px;
      margin-top: 50px;
      margin-bottom: 15px;
      font-weight: 600;
    }
    & .CardSubTitle {
      color: $sub-text-color;
      font-size: 14px;
    }
  }

  & .CardFooter {
    margin: 0 30px;
    border-top: 1px solid $main-container-bg-color;
    flex: 0 1 100px;
    & .FooterWrapper {
      display: flex;
      top: 50%;
      position: relative;
      transform: translateY(-50%);
    }
    & span {
      color: $sub-text-color;
      font-size: 12px;
      line-height: 18px;
    }
    & .IconWrapper {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
    & .MembersTeamProfilePicture {
      width: 38px;
      vertical-align: middle;
      border-radius: 50%;
      &.More {
        position: relative;
        background-color: $primary-color;
        border-radius: 50%;
        font-weight: 600;
        color: $main-text-color;
        display: inline-block;
        text-align: center;
        line-height: 38px;
      }
      &.Over {
        margin-left: -8px;
      }
    }
  }
}