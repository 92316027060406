@import "../../../../assets/styles/color";

.VerifiedEmail {
  height: 100vh;
}

.MiddleCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}