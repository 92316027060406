@import '../../../../assets/styles/color';

.StartStopPoModal {
  margin-top: 60px;
  color: $modal-text-color;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 99;
  & .Title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.21;
    margin-bottom: 34px;
    & .Green {
      color: $green-color;
    }
    & .Red {
      color: $danger-color;
    }
  }
  & .Description {
    font-size: 16px;
    line-height: 1.6;
    opacity: 0.7;
  }
  & .YesButtonWrapper {
    width: 145px;
    margin-top: 34px;
    display: inline-block;
  }
  & .CancelButton {
    font-size: 14px;
    color: $form-label-color;
    cursor: pointer;
  }
}

.Background {
  height: 215px;
  width: 100%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
}

.ScannerTopicWrapper {
  // background-color: white;
  height: 60px;
  position: absolute;
  bottom: 0;
  width: 100%;
  & .ScannerTopic {
    position: relative;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: white;
    top: 50%;
    transform: translateY(-50%);
  }
}

.CameraFrame {
  width: 75%;
  height: 65%;
  position: fixed;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // border-radius: 15px;
  // border: 4px;
  // border-style: groove;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  background: linear-gradient(to right, white 4px, transparent 4px) 0 0, 
  linear-gradient(to right, white 4px, transparent 4px) 0 100%, 
  linear-gradient(to left, white 4px, transparent 4px) 100% 0, 
  linear-gradient(to left, white 4px, transparent 4px) 100% 100%, 
  linear-gradient(to bottom, white 4px, transparent 4px) 0 0, 
  linear-gradient(to bottom, white 4px, transparent 4px) 100% 0, 
  linear-gradient(to top, white 4px, transparent 4px) 0 100%, 
  linear-gradient(to top, white 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}