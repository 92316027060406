@import '../../../../assets/styles/color';

.Card {
  width: 255px;
  height: 45vw;
  max-height: 370px;
  min-height: 217px;
  border-radius: 5px;
  background-color: $card-bg-color;
  border: 1px solid $card-bg-color;
  justify-self: center;
  box-sizing: border-box;
  position: relative;
  transition: transform 0.15s ease-in;
  overflow: hidden;
  &:hover {
    border: 1px solid $primary-color;
    cursor: pointer;
    & .ArrowIcon {
      transform: translateX(5px);
    }
    & > .CardHover {
      -webkit-transition: all 0.2s, -webkit-transform 0.15s;
      transition: all 0.2s, transform 0.15s;
      top: 0;
    }
  }

  & .FavoritedPage {
    position: absolute;
    top: 3px;
    right: 2px;
  }

  & .CardHover {
    top: -100%;
    position: absolute;
    background-color: rgba(34, 34, 38, 0.85);
    z-index: 50;
    height: 50px;
    right: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    &.MemberCardHover {
      width: 106px;
    }
    &.AdminCardHover {
      width: 159px;
    }
    & .Line {
      width: 1px;
      height: 50px;
      background-color: $navigation-bg-color;
    }
    & .Icon {
      vertical-align: text-top;
      width: 20px;
    }
    & .FavIcon {
      vertical-align: text-top;
      width: 25px;
    }
    & .ActionButtons {
      flex: 1;
      color: $primary-color;
      letter-spacing: 0.3px;
      height: 100%;
      line-height: 50px;
      &:hover {
        background-color: rgba(34, 34, 38, 0.5);
        border-radius: 4px;
      }
      & .Loading {
        position: relative;
        top: 20%; 
        opacity: 0.5;
      }
    }
  }

  &.CardAddingStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & .AddIcon {
      margin-bottom: 20px;
      width: 120px;
      align-self: center;
    }
  }
  &.CardFlexStyle {
    display: flex;
    flex-direction: column;
  }
  & .CardBody {
    padding: 0 30px;
    &.Flex {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    & .CardTitle {
      color: $card-text-color;
      font-size: 18px;
      margin-bottom: 15px;
      font-weight: 600;
    }
    & .CardSubTitle {
      color: $sub-text-color;
      font-size: 14px;
    }
  }
  & .CoverImage {
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  & .LinkIcon {
    position: absolute;
    right: 32%;
    top: 20%;
  }
  & .CardFooter {
    margin: 0 30px;
    border-top: 1px solid $main-container-bg-color;
    flex: 0 1 60px;
    & .FooterWrapper {
      display: flex;
      top: 50%;
      position: relative;
      transform: translateY(-50%);
    }
    & span {
      color: $primary-color;
      font-size: 14px;
      font-weight: bold;
    }
    & .ArrowWrapper {
      flex: 1;
      text-align: right;
      & .ArrowIcon {
        vertical-align: middle;
        transition: transform 0.25s ease-in;
      }
    }
  }
}

.CardMobile {
  width: calc(100vw - 20px);
  height: 120vw;
  border-radius: 5px;
  background-color: $card-bg-color;
  border: 1px solid $card-bg-color;
  justify-self: center;
  box-sizing: border-box;
  position: relative;
  transition: transform 0.15s ease-in;
  overflow: hidden;
  &:hover {
    border: 1px solid $primary-color;
    cursor: pointer;
    & .ArrowIcon {
      transform: translateX(5px);
    }
    & > .CardHover {
      -webkit-transition: all 0.2s, -webkit-transform 0.15s;
      transition: all 0.2s, transform 0.15s;
      top: 0;
    }
  }

  & .FavoritedPage {
    position: absolute;
    top: 3px;
    right: 2px;
  }

  & .CardHover {
    top: -100%;
    position: absolute;
    background-color: rgba(34, 34, 38, 0.85);
    z-index: 50;
    height: 50px;
    right: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    &.MemberCardHover {
      width: 106px;
    }
    &.AdminCardHover {
      width: 159px;
    }
    & .Line {
      width: 1px;
      height: 50px;
      background-color: $navigation-bg-color;
    }
    & .Icon {
      vertical-align: text-top;
      width: 20px;
    }
    & .FavIcon {
      vertical-align: text-top;
      width: 25px;
    }
    & .ActionButtons {
      flex: 1;
      color: $primary-color;
      letter-spacing: 0.3px;
      height: 100%;
      line-height: 50px;
      &:hover {
        background-color: rgba(34, 34, 38, 0.5);
        border-radius: 4px;
      }
      & .Loading {
        position: relative;
        top: 20%; 
        opacity: 0.5;
      }
    }
  }

  &.CardAddingStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & .AddIcon {
      margin-bottom: 20px;
    }
  }
  &.CardFlexStyle {
    display: flex;
    flex-direction: column;
  }
  & .CardBody {
    padding: 0 30px;
    &.Flex {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    & .CardTitle {
      color: $card-text-color;
      font-size: 18px;
      margin-bottom: 15px;
      font-weight: 600;
    }
    & .CardSubTitle {
      color: $sub-text-color;
      font-size: 14px;
    }
  }
  & .CoverImage {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  & .LinkIcon {
    position: absolute;
    right: 32%;
    top: 20%;
  }
  & .CardFooter {
    margin: 0 30px;
    border-top: 1px solid $main-container-bg-color;
    flex: 0 1 60px;
    & .FooterWrapper {
      display: flex;
      top: 50%;
      position: relative;
      transform: translateY(-50%);
    }
    & span {
      color: $primary-color;
      font-size: 14px;
      font-weight: bold;
    }
    & .ArrowWrapper {
      flex: 1;
      text-align: right;
      & .ArrowIcon {
        vertical-align: middle;
        transition: transform 0.25s ease-in;
      }
    }
  }
}