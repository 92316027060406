@import '../../../../assets/styles/color';

.RemoveMemberModal {
  margin-top: 70px;
  color: $modal-text-color;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 99;
  & .Title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.21;
    margin: 0 15px 34px 15px;
  }
  & .Description {
    font-size: 16px;
    line-height: 1.6;
    opacity: 0.7;
  }
  & .SelectWrapper{
    margin: 30px 60px 0 60px;
  }
  & .YesButtonWrapper {
    width: 145px;
    margin-top: 34px;
    display: inline-block;
  }
  & .CancelButton {
    font-size: 14px;
    color: $form-label-color;
    cursor: pointer;
  }
}

.Background {
  height: 357px;
  width: 100%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
}