@import '../../../assets/styles/color';

.DocumentsContainer {
    width: 100%;
    height: calc(100vh - 60px);
    position: relative;
    & .Documents {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        & .TopSection {
            height: 68px;
            width: 100%;
            display: flex;
            background-color: $navigation-bg-color;
            border-bottom: 1px solid $border-color;

            & .MenuWrapper {
                flex: 1;
                display: flex;
                margin-left: 30px; 
                & .Menu {
                display: flex;
                align-items: center;
                border-bottom: 3px solid $primary-color;
                & .MenuTitle {
                    font-size: 14px;
                    font-weight: 600;
                    color: $sub-text-color;
                }
                }
            }

            & .AddButtonWrapper {
                margin: 14px 30px;
                width: 200px;
            }
        }

        & .ContentSection {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 55px 20px;

            & .SearchSection {
                padding: 10px;
                display: grid;
                gap: 10px;
                grid-template-columns: auto auto auto;
                align-items: center;
                background-color: $navigation-bg-color;
                border: 1px solid $border-color;
                border-radius: 5px;
                -webkit-border-radius: 5px; 
                -moz-border-radius: 5px;
        
                & input {
                    height: 40px;
                    border-radius: 5px;
                    -webkit-border-radius: 5px; 
                    -moz-border-radius: 5px;
                    border: 1px solid $border-color;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 500;
                    outline: none !important;
                    resize: none;
                    caret-color: $main-text-color;
                    color: $card-text-color;
                    background-color: $main-container-bg-color;
                    padding-left: 10px;
                    padding-right: 10px;
        
                    &:focus {
                        border: 1px solid $primary-color;
                    }
                }
        
                & .SearchInput {
                    grid-column: 1 / span 3;
                    background: url(../../../assets/icons/search.svg) no-repeat scroll 10px 50%;
                    background-color: $main-container-bg-color;
                    text-indent: 40px;
                }
        
                & .SearchDate {
                    display: flex;
                    align-items: center;
                    gap: 10px;
        
                    & .SelectBox {
                        flex: 1;
                        

                        &::-webkit-calendar-picker-indicator {
                        filter: invert(0.75);
                        }
                        &::-webkit-calendar-picker-indicator:hover {
                        filter: invert(0);
                        }
                    }
                }
            }
        
            & .BodySection {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 10px;
                width: 100%;

                & .EmptyDocument {
                    font-size: 18px;
                    line-height: 1.6;
                    color: #fff;
                    width: 100%;
                    padding-top: 18px;
                    padding-bottom: 18px;
                    border: #1665d8 2px dashed;
                    border-radius: 6px;
                    text-align: center;
                    font-weight: 500;
                }

                & table.DocumentsTable {
                    width: 100%;
                    border-collapse: collapse;
                    border-spacing: 0;
                    border: 1px solid $border-color;
                    border-radius: 5px;
                    -webkit-border-radius: 5px; 
                    -moz-border-radius: 5px;
                    background-color: $main-container-bg-color;
                    color: $card-text-color;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                    // text-transform: uppercase;
                    letter-spacing: 1px;
                    padding: 10px;
                    
                    & tr {
                        height: 40px;
                        
                        & th {
                            border-bottom: 1px solid $border-color;
                            border-top: 1px solid $border-color;
                            background-color: $navigation-bg-color;
                            color: $sub-text-color;
                            font-family: Montserrat;
                            font-size: 12px;
                            font-weight: 500;
                            text-align: center;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            padding: 10px;
                        }
                        & td {
                            border-bottom: 1px solid $border-color;
                            border-top: 1px solid $border-color;
                            background-color: $main-container-bg-color;
                            color: $card-text-color;
                            font-family: Montserrat;
                            font-size: 14px;
                            font-weight: 500;
                            text-align: center;
                            // text-transform: uppercase;
                            letter-spacing: 1px;
                            padding: 10px;
                            
                            & img {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}