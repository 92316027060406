// prepare with form.scss

@import "../../../../assets/styles/color";

.FormGroup {
  position: relative;
  margin-bottom: 1rem;

  & label.Title {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: $form-label-color;
    text-transform: uppercase;
    margin-bottom: 0.7rem;
    &.LabelIn {
      position: absolute;
      text-transform: initial;
      top: 15px;
      left: 16px;
    }
  }

  & .SeePassword {
    position: absolute;
    top: 10px;
    right: 12px;
    cursor: pointer;
  }

  & .FormField {
    -webkit-appearance: none;
    appearance: none;
    display: block;
    font-family: inherit;
    width: 100%;
    padding: 10px 16px;
    min-height: 45px; // for chrome, and date icon in beside form makes it more height
    font-size: 14px;
    border-radius: 4px;
    border: solid 2px $border-color;
    background-color: $main-container-bg-color;
    color: $main-text-color;
    transition: border-color 0.15s ease-in-out;
    box-sizing: border-box;
    outline: none;
    resize: none;
    &.TextRight {
      text-align: right;
    }
    &.WhiteMode {
      border: 2px solid $auth-input-border-color;
      background-color: $main-text-color;
      color: $auth-title-color;
      &.Error {
        border: 2px solid $danger-color;
      }
    }
    &:hover:enabled,
    &:focus:enabled {
      border-color: $primary-color;
    }
    &.Error {
      border: solid 2px $danger-color;
    }
    &.Disabled {
      opacity: 0.5;
    }
  }
  & .WithIcon {
    padding-left: 56px;
  }
  & .WithSuffixIcon {
    padding-right: 30px;
  }
  & .IconWrapper {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 40px;
    height: 41px;
    background-color: $card-bg-color;
    display: flex;
    align-items: center;
    justify-content: center;
    & .Icon {
      position: relative;
    }
  }
  & .SuffixIconWrapper {
    position: absolute;
    top: 2px;
    left: calc(100% - 30px);
    width: 15px;
    height: 41px;
    padding-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .SuffixIcon {
      position: relative;
      width: 15px;
      cursor: pointer;
    }
  }
  & .ErrorText {
    color: $danger-color;
    font-size: 12px;
    font-weight: 500;
    line-height: 2;
  }

  & .RadioField {
    position: relative;
    margin-bottom: 1rem;
    display: block;
    width: 100%;
    padding: 16px 30px;
    border-radius: 4px;
    border: solid 2px $border-color;
    background-color: $main-container-bg-color;
    transition: border-color 0.15s ease-in-out;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    &.Selected {
      border-color: $primary-color;
      background-color: rgba(22, 101, 216, 0.1);
    }
    & .RadioIconWrapper {
      display: inline-block;
      width: 42px;
      height: 42px;
      background-color: $border-color;
      border-radius: 50%;
      vertical-align: middle;
      &.Selected {
        background-color: $primary-color;
      }
      & .RadioIcon {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    & .RadioLabel {
      margin-left: 17px;
    }
    & .RadioButtonStyle {
      position: absolute;
      top: 26px;
      right: 30px;
    }
  }
}
