@import "../../../../assets/styles/color";

.EditOrganization {
  & .HeaderWrapper {
    height: 50px;
    display: flex;
    border-bottom: 1px solid $card-text-color;
    padding: 24px 30px 0 24px;
    & .Header {
      flex: 1;
      font-size: 20px;
      color: $auth-title-color;
      font-weight: 600;
      line-height: 1.5;
    }
    & .HeaderTap {  
      flex: 1;
      display: flex;
      .SelectedHeader {
        cursor: pointer;
        font-size: 18px;
        color: $auth-title-color;
        font-weight: 600;
        line-height: 1.5;
        border-bottom: 3px solid $primary-color;
      }
      .UnSelectedHeader {
        cursor: pointer;
        font-size: 18px;
        color: $auth-title-color;
        font-weight: 400;
        line-height: 1.5;
        border-bottom: 1px solid $card-text-color;
      }
    }
  }
  .SwitchButton {
    text-align: right;
    width: 100%;
    height: 38px;
    border-radius: 4px;
    font-family: inherit;
    font-size: 14px;
    font-weight: bold;
    outline: none;
    background: none;
    cursor: pointer;
    user-select: none;
    border: none;
    color: $primary-color;
    & .IconButton {
      vertical-align: text-bottom;
      margin-right: 10px;
    }
  }
  
}
