@import '../../../assets/styles/color';

.ProductionDashboard {
  // padding: 30px;
}

.TopSection {
  height: 68px;
  background-color: $navigation-bg-color;
  display: flex;
  border-bottom: 1px solid $border-color;
  & .MenuWrapper {
    flex: 1;
    display: flex;
    margin-left: 30px; 
    & .ActiveMenu {
      width: 150px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 3px solid $primary-color;
      margin-right: 32px;
      & .MenuTitle {
        font-size: 14px;
        font-weight: 600;
        color: $sub-text-color;
      }
    }
    & .Menu {
      width: 150px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 32px;
      border-bottom: 3px solid $navigation-bg-color;
      & .MenuTitle {
        font-size: 14px;
        color: $form-label-color;
      }
    }
  }
}

.TopBox {
  background-color: $navigation-bg-color;
  border-radius: 4px;
  margin-bottom: 30px;
  & .HeaderTitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.56;
    color: $main-text-color;
    padding: 20px 30px;
    border-bottom: 1px solid $border-color;
  }
  & .PoStatusWrapper {
    display: flex;
    & .EachStatusArea {
      padding: 30px;
      flex: 1;
      &:not(:last-child) {
        border-right: 1px solid $border-color;
      }
      & .StatusTitle {
        font-size: 12px;
        font-weight: 500;
        color: $sub-text-color;
        margin-bottom: 10px;
      }
      & .StatusValue {
        display: flex;
        align-items: center;
        font-size: 30px;
        color: #f3f3f3;
      }
      & .ProgressBox {
        margin-left: 10px;
        border-radius: 4px;
        padding: 0 6px;
        height: 25px;
        color: $main-text-color;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        &.Inc {
          background-color: $green-color;
        }
        &.Dec {
          background-color: $danger-color;
        }
      }
    }
  }
}

.ScreenButton {
  cursor: pointer;
  margin: auto 35px auto 0;
  & .ScreenWrapper {
    display: flex;
    & .ScreenTitle {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 500;
      color: $sub-text-color;
    }
  }
}

.MinimizeScreen {
  display: block;
}

.FullScreen {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100%);
  z-index: 1000;
  min-height: calc(100% - 70px);
  background-color: $main-container-bg-color;
  min-height: calc(100vh - 60px);
}

.VerticalDivider {
  width: 2px;
  height: 100%;
  background-color: $border-color;
  margin-right: 30px;
}