@import '../../../../assets/styles/color';

.MemberCard {
  border-bottom: 1px solid $main-container-bg-color;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  &.Open {
    border-left: 4px solid $primary-color;
  }

  & .MemberWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 20px 30px;
    & .ProfilePicture {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      margin-right: 20px;
      overflow: hidden;
    }
    & .NameAndInformation {
      flex: 1;
      & .FullName {
        font-size: 14px;
        font-weight: 500;
        color: $card-text-color;
        line-height: 1.57;
      }
      & .OtherInformation {
        font-size: 14px;
        color: $form-label-color;
        line-height: 1.57;
        & .MiddleDot {
          margin: 0 10px;
        }
      }
    }
    & .ActionButtons {
      & .ArrowIcon {
        transform: rotate(180deg);
        transition: transform 0.25s ease-in;
        vertical-align: super;
        &.ToggleDown {
          transform: rotate(0deg);
        }
      }
    }
  }
  
  & .CollapseContainer {
    background-color: $navigation-bg-color;
    // visibility: hidden;
    // min-height: 0px;
    // height: 0px;
    // transition-duration: 228ms;
    font-size: 14px;
    font-weight: 500;
    & .DetailHeader {
      display: flex;
      background-color: $card-bg-color;
      color: $sub-text-color;
      padding: 20px 30px;
      border-top: 1px solid $navigation-bg-color;
    }
    & .DetailBody {
      display: flex;
      color: $form-label-color;
      border-bottom: 1px solid $card-bg-color;
      padding: 12px 30px;
      & .Cell200px {
        width: 200px;
        line-height: 28px;
      }
      & .VisitWrapper {
        width: 120px;
        &:hover {
          & .ArrowIcon {
            transform: translateX(5px);
          }
        }
        & .ArrowIcon {
          transition: transform 0.25s ease-in;
          margin: 10px;
          vertical-align: middle;
        }
        & .VisitPage {
          cursor: pointer;
          font-size: 12px;
          line-height: 27px;
          color: $primary-color;
          text-decoration: underline;
        }
      }
      & .MembersPageProfilePicture {
        width: 28px;
        vertical-align: middle;
        border-radius: 50%;
        &.More {
          position: relative;
          background-color: $primary-color;
          border-radius: 50%;
          font-weight: 600;
          color: $main-text-color;
          display: inline-block;
          text-align: center;
          line-height: 28px;
        }
        &.Over {
          margin-left: -8px;
        }
      }
    }
  }
}