@import '../../../../assets/styles/color';

.Container {
  & .Group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    & .Box {
      padding: 0 10px;
      min-width: 30px;
      height: 50px;
      border: 1px solid $form-label-color;
      background-color: $card-bg-color;
      border-radius: 2px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
    } 
    & .Symbol {
      width: 20px;
      height: 20px;
      border: 1px solid $form-label-color;
      background-color: $card-bg-color;
      border-radius: 2px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      user-select: none;
      position: relative;
    }
    & .Parentheses {
      font-size: 60px;
      padding-bottom: 8px;
    }
    & .OperatorGroup {
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 130px;
        height: 1px;
        background: $sub-text-color;
        left: -200px;
        top: 50%;
        position: absolute;
      }
      &:after {
        content: "";
        display: block;
        width: 130px;
        height: 1px;
        background: $sub-text-color;
        right: -200px;
        top: 50%;
        position: absolute;
      }
    }
  }
}

.AddButton {
  margin-left: 5px;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  background-color: $primary-color;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $main-text-color;
  cursor: pointer;
}

.CloseIcon {
  cursor: pointer;
  position: absolute;
  right: 40px;
}

.ButtonsWrapper {
  padding: 45px 30px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 75px;
  border-top: 1px solid $form-label-color;
}