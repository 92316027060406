@import "../../../../assets/styles/color";

.PresetDrawer {
  // transform: translateX(-100%);
  
  // transition: width 3s;

  & .LineFormGroup {
    margin-bottom: 38px;
    display: flex;
  }

  & label.Title {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $form-label-color;
    margin-bottom: 10px;
  }

  & .Divider {
    width: 99%;
    background-color: #2b2b30;
    border: 1px solid #2b2b30;
  }

  & .ReportDetail {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $form-label-color;
    // margin-bottom: 10px;
    padding-left: 1%;

    & span, li {
      color: $main-text-color;
    }

    & .Title {
      width: 68px;
      height: 18px;
      // margin: 0 236px 12px 0;
      // font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $form-label-color;
      cursor: default;
      // padding-left: -5%;
      // background-color: rosybrown;
      margin-left: -5%;
    }
  }

  & .AutoGenWrapper {
    margin-top: 24px;
    // background-color: red;

    & .Title {
      width: 68px;
      height: 18px;
      // margin: 0 236px 12px 0;
      // font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $form-label-color;
      cursor: default;
    }

    & .SwitchBox {
      // background-color: lightgreen;
      // // width: 90%;
      // padding-left: 10%;
      // padding-right: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 304px;
      // height: 38px;
      // margin: 12px 0 0;
      padding: 0 16px;
      border-radius: 4px;
      border: solid 1px $border-color;
      background-color: $main-container-bg-color;
      // margin-bottom: 12px;
    }

    & .SwitchBox p {
      flex: 1;
      color: $form-label-color;
      font-size: 14px;
      font-weight: bold;
      cursor: default;
    }
  }

  & .FormGroup {
    margin-top: 15px;
    & .FormField {
      -webkit-appearance: none;
      appearance: none;
      display: block;
      font-family: inherit;
      width: 100%;
      padding: 10px 16px;
      font-size: 14px;
      border-radius: 4px;
      border: solid 2px $border-color;
      background-color: $main-container-bg-color;
      color: $main-text-color;
      transition: border-color .15s ease-in-out;
      box-sizing: border-box;
      outline: none;
      resize: none;
      &:hover, &:focus {
        border-color: $primary-color;
      }
    }

    & .SelectBox {
      flex: 1;
      width: 100%;
      padding: 0 16px;
      background-color: $main-container-bg-color;
      color: $widget-text-color;
      height: 38px;
      border-radius: 4px;
      font-family: inherit;
      font-size: 14px;
      font-weight: 500;
      border: 1px solid $border-color;
      box-sizing: border-box;
      outline: none;
      resize: none;
      &::-webkit-calendar-picker-indicator {
        filter: invert(0.75);
      }
      &::-webkit-calendar-picker-indicator:hover {
        filter: invert(0);
      }
    }
  }

  .AddPowerPriceButton {
    color: $primary-color;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    border: 1px dashed $primary-color;
    text-align: center;
    padding: 10px 0;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px 0;
  
    & .AddIcon {
      margin-right: 10px;
      vertical-align: middle;
    }
    & .ButtonName {
      display: inline-block;
    }
  }
}

