@import "../../../../assets/styles/color";

.gm-style {
  & .gm-style-iw {
    background-color: $card-bg-color;
    min-width: 200px;
    min-height: 60px;
    border-radius: 4px;
    & ::-webkit-scrollbar {
      width: 0px !important;
      height: 0px !important;
    }

    & ::-webkit-scrollbar-thumb {
      border-radius: 10px !important;
      background: $sub-text-color !important;
    }

    & ::-webkit-scrollbar-thumb:hover {
      background: $form-label-color !important;
    }
  }
  & .gm-style-iw-t::after {
    background: $card-bg-color;
  }
}

.gm-style div div div div div div div div {
  padding: 0;
  margin: 0;
  padding: 0;
  top: 0;
  color: $card-text-color;
  font-family: Montserrat;
}

.InfoWindow {
  padding: 10px;
  & .InfoName {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  & .InfoDescription{
    margin-bottom: 15px;
  }
}
