@import '../../../assets/styles/color';

.Notification {
  display: flex;
  flex-direction: column;

  & .NotificationTopics {
    flex: 0 1 500px;
    background-color: $navigation-bg-color;
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;

    & .NotificationFix {
      position: fixed;
      width: 500px;
      overflow: overlay;      
    }
  }
  & .NotificationDetails {
    flex: 1;
    padding: 30px;
  }
}

.NotificationBody {
  display: flex;
  min-height: calc(100vh - 128px);
  margin-top: 68px;
}

.NotificationTopBar {
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  position: fixed;
  width: calc(100% - 230px);
  margin-top: 1px;
  z-index: 1;
  & .TopMenuNotification {
    display: flex;
    flex: 1;
    border-bottom: 1px solid $border-color;
    background-color: $navigation-bg-color;
    & .AddButtonWrapper {
      margin: 14px 30px;
      width: 200px;
    }
  }
}

.MenuWrapper {
  flex: 1;
  display: flex;
  margin-left: 30px; 
  height: 66px;
  & .ActiveMenu {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 3px solid $primary-color;
    margin-right: 40px;
    & .MenuTitle {
      font-size: 14px;
      font-weight: 600;
      color: $sub-text-color;
    }
  }
  & .Menu {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 40px;
    border-bottom: 3px solid $navigation-bg-color;
    & .MenuTitle {
      font-size: 14px;
      color: $form-label-color;
    }
  }
}

.LoadingCard{
  --card-padding: 30px;
  --card-height: 82px;
  --card-skeleton: linear-gradient(#222226 var(--card-height), transparent 0);

  --desc-line-height: 12px;
  --desc-line-skeleton: linear-gradient(rgba(107, 109, 112, 0.15) var(--desc-line-height), transparent 0);
  --desc-line-1-width:100px;
  --desc-line-1-position: var(--card-padding) 27px;
  --desc-line-2-width:160px;
  --desc-line-2-position: var(--card-padding) 47px;

  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(var(--card-height) - 40px);

  width: 100%; //demo
  height: var(--card-height);
  border-bottom: 1px solid $border-color;
  border-radius: 5px;

  &:empty::after {
    content:"";
    display:block;
    width: 100%;
    height: 100%;
    border-radius:6px;
    // box-shadow: 0 10px 45px rgba(0,0,0, .1);

    background-image:
      linear-gradient(
        90deg, 
        rgba(#222226, 0) 0, 
        rgba(#222226, .8) 50%, 
        rgba(#222226, 0) 100%
      ),                          //animation blur
      var(--desc-line-skeleton),  //desc1
      var(--desc-line-skeleton),  //desc2
      var(--card-skeleton)        //card
    ;

    background-size:
      var(--blur-size),
      var(--desc-line-1-width) var(--desc-line-height),
      var(--desc-line-2-width) var(--desc-line-height),
      100% 100%
    ;
    
    background-position:
      -100% 50%,                      //animation
      var(--desc-line-1-position),  //desc1
      var(--desc-line-2-position),  //desc2
      0 0                           //card
    ;

    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  to {
    background-position:
      100% 50%,       
      var(--desc-line-1-position),
      var(--desc-line-2-position),
      0 0
    ;
  }
}

.ModalHeaderWrapper {
  display: flex;
  border-bottom: 1px solid $card-text-color;
  padding: 24px 40px 24px 30px;
  align-items: center;
  & .ModalHeader {
    flex: 1;
    font-size: 20px;
    color: $auth-title-color;
    font-weight: 600;
    line-height: 1.5;
  }
}

.HistoriesWrapper {
  border-collapse: collapse;
  width: 100%;
  & .HistoriesHeader {
    text-align: left;
    color: $form-label-color;
    font-size: 12px;
    font-weight: 600;
  }
  & .HistoriesInfo {
    color: $auth-title-color;
    font-size: 14px;
    font-weight: 600;
  }
}

.MessageWrapper {
  background-color: $light-bg-color;
  border-bottom: 1px solid $card-text-color;
  padding: 20px 0;
  & .MessageTitle {
    color: $auth-title-color;
    font-size: 17px;
    font-weight: 500;
    padding: 0 30px;
    display: flex;
    cursor: pointer;
    & .ArrowIcon {
      transform: rotate(0deg);
      transition: transform 0.25s ease-in;
      &.ToggleUp {
        transform: rotate(180deg);
      }
    }
  }

  & .MessageBoxWrapper {
    display: flex;
    padding: 10px 20px;
    &.Close {
      display: none;
    }
    & .MessageBox {
      background-color: $main-text-color;
      border: 1px solid $card-text-color;
      padding: 10px 20px;
      flex: 1;
      margin: 0 10px;
      &.TrueBox {
        border-left: 3px solid $green-color;
        & .TrueTitle {
          color: $green-color;
          font-weight: bold;
          font-size: 22px;
        }
      }
      &.FalseBox {
        border-left: 3px solid $danger-color;
        & .FalseTitle {
          color: $danger-color;
          font-weight: bold;
          font-size: 22px;
        }
      }
      & .ConditionText {
        font-size: 14px;
        font-weight: 500;
        color: $form-label-color;
        margin-top: 5px;
      }
    }
  }
}

.NotFoundText {
  color: $sub-text-color;
  font-weight: 600;
  text-align: center;
  margin-top: 30px;
}

.LoadingCardWhite {
  --card-padding: 30px;
  --card-height: 82px;
  --card-skeleton: linear-gradient(white var(--card-height), transparent 0);

  --desc-line-height: 12px;
  --desc-line-skeleton: linear-gradient(rgba(107, 109, 112, 0.15) var(--desc-line-height), transparent 0);
  --desc-line-1-width:100px;
  --desc-line-1-position: var(--card-padding) 27px;
  --desc-line-2-width:160px;
  --desc-line-2-position: var(--card-padding) 47px;

  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(var(--card-height) - 40px);

  width: 100%; //demo
  height: var(--card-height);

  &:empty::after {
    content:"";
    display:block;
    width: 100%;
    height: 100%;

    background-image:
      linear-gradient(
        90deg, 
        rgba(white, 0) 0, 
        rgba(white, .8) 50%, 
        rgba(white, 0) 100%
      ),                          //animation blur
      var(--desc-line-skeleton),  //desc1
      var(--desc-line-skeleton),  //desc2
      var(--card-skeleton)        //card
    ;

    background-size:
      var(--blur-size),
      var(--desc-line-1-width) var(--desc-line-height),
      var(--desc-line-2-width) var(--desc-line-height),
      100% 100%
    ;
    
    background-position:
      -100% 50%,                      //animation
      var(--desc-line-1-position),  //desc1
      var(--desc-line-2-position),  //desc2
      0 0                           //card
    ;

    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
}