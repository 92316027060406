@import '../../../../assets/styles/color';

.SelectBox {
    flex: 1;
    width: 100%;
    padding: 0 16px;
    background-color: $main-container-bg-color;
    color: $widget-text-color;
    height: 38px;
    border-radius: 4px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid $border-color;
    box-sizing: border-box;
    outline: none;
    resize: none;
      
      &::-webkit-calendar-picker-indicator {
      filter: invert(0.75);
    }
    &::-webkit-calendar-picker-indicator:hover {
      filter: invert(0);
      }
  }
  input[type=date]::-webkit-calendar-picker-indicator:hover {
    background-color: $border-color;
  }

  .Title {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $form-label-color;
    margin-bottom: .7rem;
  }

  .CalendarIcon {
      position: absolute;
      top: 9px;
      left: 18px;
  }
  
  .ViewButton {
      display: inline-block;
      width: 200px;
      margin-left: 20px;
  }
  
  .TableWrapper {
      margin: 30px 0;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
      border: 1px solid $border-color;
      background-color: $navigation-bg-color;
      overflow-x: auto;
      & table {
        border-collapse: collapse;
        width: 100%;
        text-align: left;
      }
  
      & tr {
        overflow-x: scroll;
      }
			
			& tbody tr:hover {
				background-color: $border-color;
			}
      
      & th, td {
        padding: 15px;
      }
      
      & th {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: $form-label-color;
      }
  
      & td {
        font-size: 14px;
        font-weight: 500;
      }
  }
  
  .SearchBarWrapper {
    display: flex;
    width: 50vw;
    @media screen and (max-width: 1440px) {
      width: 100%;
    }
  }
  
  .SearchInputWrapper {
    position: relative;
    display: flex;
    flex: 1;
    & .AdvancedSearch {
      font-weight: 500;
      font-size: 12px;
      text-decoration: underline;
      color: $primary-color;
      cursor: pointer;
      position: absolute;
      right: 18px;
      top: 11px;
    }
    & .SearchIconInput {
      position: absolute;
      top: 13px;
      left: 15px
    }
    & .SearchExpand {
      color: $card-text-color;
      font-family: inherit;
      font-weight: 500;
      font-size: 14px;
      -webkit-box-sizing: content-box;
      outline: none;
      border: solid 1px $border-color;
      padding: 10px 140px 10px 40px;
      width: 100%;
      background-color: $card-bg-color;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      
      -webkit-transition: all .5s;
      -moz-transition: all .5s;
      transition: all .5s;
    }
    & input:-moz-placeholder {
      color: #999;
    }
    & input::-webkit-input-placeholder {
      color: #999;
    }
  }

  .AutoFillBox {
    background-color: $card-bg-color;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    border-radius: 4px;
    color: $auth-title-color;
    font-size: 12px;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
        &.Selected {
        color: $main-text-color;
    }
}