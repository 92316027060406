@import "../../../../assets/styles/color";

.tooltip {
  position: relative;
  display: inline-block;

  & .tooltiptext {
    width: 290px;
    background-color: $main-text-color;
    color: $form-label-color;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    z-index: 1000;
    bottom: 100%;
    left: -80px;
    margin-bottom: 50px;
    box-shadow: 5px 6px 18px #888888;
    border: solid 1px $card-text-color;
  }

  & .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: 70px;
    border-width: 15px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }

  & .admintooltiptext {
    width: 290px;
    background-color: $main-text-color;
    color: $form-label-color;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    border-radius: 4px;
    padding: 5px 0;
    position: absolute;
    z-index: 1000;
    top: -100px;
    right: 20px;
    box-shadow: 5px 6px 18px #888888;
  }

  & .admintooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent transparent #fff;
  }
}
