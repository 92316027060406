@import './assets/styles/color';

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', 'Prompt', 'Roboto', "Helvetica Neue", sans-serif;
  color: $main-text-color;
  background-color: $main-container-bg-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.MainContentPadding {
  padding: 55px 20px;
}

.ErrorText {
  color: $danger-color;
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $sub-text-color;
}

::-webkit-scrollbar-thumb:hover {
  background: $form-label-color;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: inherit !important;

  & .fc-daygrid-day-number {
    border-radius: 20px;
    font-weight: bold;
    background-color: $primary-color;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.fc .fc-list-sticky .fc-list-day>* {
  background-color: #2c3e50 !important;
  border: none !important;
}

.fc-list-table .fc-custom-time-start,
.fc-list-table .fc-custom-time-end {
  display: none;
}

.fc-theme-standard .fc-popover {
  background: #000 !important;
}

.fc .fc-more-popover .fc-popover-body {
  padding: 0 !important;
}

.fc-list-table .fc-list-event:hover td {
  cursor: pointer;
  background-color: $border-color !important;
}

.g6-component-contextmenu {
  display: none !important;
}