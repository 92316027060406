@import "../../../assets/styles/color";

.TopSection {
  display: flex;
  align-items: center;
  padding-bottom: 26px;
  border-bottom: 1px solid $border-color;
  margin: 0 30px;
  & .PageName {
    font-size: 18px;
    font-weight: 500;
    color: $card-text-color;
    margin-left: 20px;
    vertical-align: top;
  }
  & .CustomGrid{
    margin-right: 30px;
  }
  & .ScreenButton {
    cursor: pointer;
    margin-right: 35px;
    & .ScreenWrapper {
      display: flex;
      & .ScreenTitle {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
        color: $sub-text-color;
      }
    }
  }
}

.NoPagesWrapper {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  & .NoPagesInfo {
    font-size: 20px;
    font-weight: 500;
    color: $card-text-color;
    margin: 45px 0;
  }
  & .NoPagesButtonWrapper {
    width: 200px;
    margin: 0 auto;
  }
}

.MinimizeScreen {
  display: block;
  padding-top: 55px;
}

.FullScreen {
  position: absolute;
  padding-top: 55px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  background-color: $main-container-bg-color;
}
