@import "../../../assets/styles/color";

.TimelineDrawer {
  & .FormulaFormGroup {
    margin-top: 15px;
  }

  & label.Title {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $form-label-color;
    margin-bottom: 10px;
  }

  & .StatusLabelError {
    border: solid 1px $danger-color !important;
    color: $danger-color !important;
  }

  & .StatusLabel {
    position: relative;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    border: solid 1px $primary-color;
    border-radius: 5px;
    color: $primary-color;

    & .StatusColor {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-right: 5px;
    }

    & .Arrow {
      float: right;
      margin-top: 5px;
      transform: rotate(180deg);
      transition: transform 0.25s ease-in;
      vertical-align: super;
      &.Open {
        transform: rotate(0deg);
      }
    }

    & .DeleteIcon {
      position: absolute;
      right: 30px;
      top: 8px;
    }
  }

  & .AddStatusBtn {
    text-align: center;
    border: dashed 1px $primary-color;
    border-radius: 5px;
    margin-top: 10px;
    height: 37px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      margin-right: 10px;
    }

    & p {
      color: $primary-color;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  & .ColorThresholdWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    & .Label {
      font-size: 12px;
      font-weight: 600;
      color: $main-text-color;
      width: 150px;
    }
    & .DangerLabel {
      font-size: 12px;
      font-weight: 500;
      color: #ff5454;
      flex: 1;
      text-align: center;
    }
    & .WarningLabel {
      font-size: 12px;
      font-weight: 500;
      color: #ffa000;
      flex: 1;
      text-align: center;
    }
  }

}
