@import "../../../../assets/styles/color";

.StructureWrapper {
    background-color: $navigation-bg-color;
    border: 1px solid $border-color;
    border-radius: 4px;
    margin-bottom: 30px;
    & .HeaderWrapper {
        display: flex;
        align-items: baseline;
        padding: 20px 30px;
        border-bottom: 1px solid $border-color;
        & .Name {
            color: $card-text-color;
            font-size: 18px;
            font-weight: 600;
            margin-left: 15px;
        }
        & .Detail {
            color: $form-label-color;
            font-size: 14px;
            font-weight: 500;
            margin-left: 15px;
        }
        & .Icons {
            width: 20px;
            height: 20px;
            vertical-align: text-top;
            cursor: pointer;
        }
    }
    & .StructureTable {
        padding: 10px 30px;
        display: flex;
        align-items: center;
        & .ColIcon {
            width: 16px;
            height: 16px;
            cursor: pointer;
        }
    }
    & .Notice {
        font-size: 11px;
        font-weight: 500;
        color: $sub-text-color;
        margin-bottom: 10px;
        margin-left: 30px;
    }
}

.ColsWrapper {
    flex: 0 1 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
    overflow: auto;
}
.ColBox {
    flex: 1;
    padding: 10px;
    padding-left: 15px;
    border-radius: 4px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    &.Editable {
        background-color: $border-color;
    }
    & .ColumnName {
        flex: 1;
        color: $sub-text-color;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
    }
}

.DragHandle { 
    margin-right: 5px;
    padding: 0 5px;
    &:hover, &:focus {
        background-color: $navigation-bg-color;
        border-radius: 4px;
        cursor: move;
    }
}