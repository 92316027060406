@import "../../../../assets/styles/color";

.Label {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: $form-label-color;
  margin-bottom: 0.5rem;
}
