@import '../../../../assets/styles/color';

.MemberSearchTopic {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: $form-label-color;
  margin-bottom: .5rem;
}

.MembersWrapper {
  margin-top: 12px;
}

.ProfilePictureWrapper {
  display: inline-block;
  position: relative;
  &.CanDelete:hover {
    & .MemberProfilePicture {
      opacity: 0.7;
      cursor: pointer;
    }
    & .DeleteMemberButton {
      display: block;
      cursor: pointer;
    }
  }
  
  & .MemberProfilePicture {
    width: 38px;
    height: 38px;
    margin-bottom: 7px;
    margin-right: 12px;
    border-radius: 50%;
    &.Owner { 
      border: 2px solid $yellow-color;
    }
  }
  & .DeleteMemberButton {
    display: none;
    position: absolute;
    right: 8px;
    top: 0;
  }
}

.MembersNotMatch {
  color: $green-color;
  font-size: 12px;
  font-weight: 500;
}