@import "../../../../assets/styles/color";

.Button {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  margin-bottom: 1rem;
  user-select: none;
  position: relative;
  &.Disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
  &.Primary {
    border: 1px solid #1461d2;
    background-image: linear-gradient(to top, $primary-color, #1f6fe5);
    color: $main-text-color;
  }
  &.BorderBlue {
    border: 1px solid $primary-color;
    background-color: unset;
    color: $primary-color;
  }
  &.BorderRed {
    border: 1px solid $danger-color;
    background-color: unset;
    color: $danger-color;
  }
  &.BorderGreen {
    border: 1px solid #2d9c3c;
    background-color: unset;
    color: #2d9c3c;
  }
  &.BorderWhite {
    border: 1px solid $card-text-color;
    background-color: unset;
    color: $card-text-color;
  }
  &.Green {
    border: 1px solid #2d9c3c;
    background-image: linear-gradient(to top, #34aa44, #38b249);
    color: $main-text-color;
  }
  &.Red {
    border: 1px solid #e73c1e;
    box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.08);
    background-image: linear-gradient(to bottom, #e73c1e, #cf3014);
    color: $main-text-color;
  }
  &.White {
    box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
    border: solid 1px #d8dce6;
    background-image: linear-gradient(to top, #f6f7f9, $main-text-color);
    color: $auth-title-color;
  }
  &.Blue {
    box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
    border: 1px solid $auth-button-color;
    background-color: $auth-button-color;
    color: $main-text-color;
  }
  &.Yellow {
    border: 1px solid $yellow-color;
    // background-image: linear-gradient(to top, $yellow-color, $yellow-color);
    background-color: $yellow-color;
    color: $main-text-color;
  }
  &.Orange {
    border: 1px solid $orange-color;
    // background-image: linear-gradient(to top, $orange-color, $orange-color);
    background-color: $orange-color;
    color: $main-text-color;
  }
  &.YellowBorder {
    border: 1px solid $orange-color;
    background-color: $main-container-bg-color;
    color: $orange-color;
  }

  &.NoMargin {
    margin: 0;
  }

  & .IconButton {
    vertical-align: text-bottom;
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  & .LoadingWrapper {
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }

  &.BorderGray {
    border: 1px solid #6b6c6f;
    background-color: unset;
    color: $card-text-color;
  }

  &.Gray {
    border: 1px solid $card-bg-color;
    background-color: $card-bg-color;
    color: #565B67;
    &.Active {
      color: $main-text-color;
    }
  }
}
