@import "../../../../assets/styles/color";

.HeaderTableWrapper {
	border: 1px solid $border-color;
	background-color: $navigation-bg-color;
  padding: 20px 30px;
    & .MainSection {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & .HeaderText {
            font-size: 22px;
            font-weight: 600;
            color: $card-text-color;
            margin: 6px 0;
            margin-right: 30px;
            display: flex;
            align-items: center;
        }
    }
}

.AutoFillBoxWrapper {
  display: flex;
  & .AutoFillBox {
    background-color: $card-bg-color;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    border-radius: 4px;
    color: $auth-title-color;
    font-size: 12px;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
    height: 38px;
        &.Selected {
        color: $main-text-color;
    }
  }
}

.TableWrapper {
  margin: 30px 0;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  border: 1px solid $border-color;
  background-color: $navigation-bg-color;
  overflow-x: auto;
  & table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
  }

  & tr {
    overflow-x: scroll;
    cursor: pointer;
  }
  
  & tbody tr:hover {
    background-color: $border-color;
  }
  
  & th, td {
    padding: 15px;
  }
  
  & th {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $form-label-color;
  }

  & td {
    font-size: 14px;
    font-weight: 500;
    
  }
}

.Complete {
  color: $green-color !important;
  background-color: #34aa4422 !important;
  text-align: center;
}
.Incomplete {
  color: $danger-color !important;
  background-color: #e6492d22 !important;
  text-align: center;
}

.Manual {
  color: $main-text-color !important;
  background-color: #9ea0a533 !important;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .LeftSection {
    flex-direction: column;
    align-items: flex-start !important;
  }
}