.LoadingPresetCard {
  --card-padding: 58px;
  --card-height: 118px;
  --card-skeleton: linear-gradient(#2b2b30 var(--card-height), transparent 0);
  
  --avatar-size: 62px;
  --avatar-position: var(--card-padding) 25px;
  --avatar-skeleton: radial-gradient(26px, rgba(157, 159, 164, 0.15)  100%, transparent 0);
  
  --desc-line-height: 18px;
  --desc-line-skeleton: linear-gradient(rgba(107, 109, 112, 0.4) var(--desc-line-height), transparent 0);
  --desc-line-1-width:100px;
  --desc-line-1-position: 152px 30px;
  --desc-line-2-width:240px;
  --desc-line-2-position: 152px 64px;

  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(var(--card-height) - 40px);

  width: 100%; //demo
  height: var(--card-height);
  // height: 112px;
  border: none;
  border-radius: 5px;
  margin-bottom: 24px;

  &:empty::after {
    content:"";
    display:block;
    width: 100%;
    height: 100%;
    border-radius:6px;
    box-shadow: 0 10px 45px rgba(0,0,0, .1);

    background-image:
      linear-gradient(
        90deg, 
        rgba(#2b2b30, 0) 0, 
        rgba(#2b2b30, .8) 50%, 
        rgba(#2b2b30, 0) 100%
      ),                          //animation blur
      var(--desc-line-skeleton),  //desc1
      var(--desc-line-skeleton),  //desc2
      var(--avatar-skeleton), //avatar
      var(--card-skeleton)        //card
    ;

    background-size:
      var(--blur-size),
      var(--desc-line-1-width) var(--desc-line-height),
      var(--desc-line-2-width) var(--desc-line-height),
      var(--avatar-size) var(--avatar-size),
      100% 100%
    ;
    
    background-position:
      -10% 50%,                      //animation
      var(--desc-line-1-position),  //desc1
      var(--desc-line-2-position),  //desc2
      var(--avatar-position),       //avatar
      0 0                           //card
    ;

    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  to {
    background-position:
      100% 50%,        
      var(--desc-line-1-position),
      var(--desc-line-2-position),
      var(--avatar-position),
      0 0
    ;
  }
}
