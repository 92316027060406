@import '../../../assets/styles/color';

.ClockWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    & .Time {
        font-size: 30px;
        font-weight: 600;
    }
    & .Date {
        font-size: 12px;
        font-weight: 500;
        color: $sub-text-color;
    }
}