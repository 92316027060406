
@import "../../../../assets/styles/color";

.Snackbar {
  // position: fixed;
  // bottom: 100px;
  // right: 20px;
  z-index: 1001;
  transition: all 0.2s ease-in-out;
  &.On {
    visibility: visible;
    opacity: 1;
    transform: scale(1, 1);
  }
  &.Off {
    transform: scale(0.1, 0.1);
    visibility: hidden;
    opacity: 1;
  }
  & .Wrapper {
    position: relative;
    background-color: $main-text-color;
    width: 350px;
    // min-height: 150px;
    color: $modal-text-color;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
    display: flex;
    &.More {
      border-radius: 0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    &.More1 {
      width: 330px;
      margin-left: 20px;
      padding: 9px;
      box-shadow: inset 0px 0px 14px #00000088;
    }
    &.More2 {
      width: 310px;
      margin-left: 40px;
      padding: 0;
      height: 16px;
      box-shadow: inset 0px 0px 14px #00000088;
    }
    & .IconWrapper {
      margin: 0 30px 0 20px;
      display: flex;
      align-items: center;
    }
    & .Content {
      flex: 1;
      & .Title {
        color: black;
        font-weight: 600;
        font-size: 36px;
      }
      & .Details {
        color: $modal-text-color;
        font-size: 14px;
        font-weight: 600;
      }
      & .Navigation {
        margin-top: 15px;
        color: $sub-text-color;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  & .CloseBtn {
    position: absolute;
    color: $modal-text-color;
    font-size: 32px;
    top: 0px;
    right: 10px;
  }
}

.ShowLess {
  display: inline-block;
  color: #000;
  background-color: #fff;
  border-radius: 20px;
  padding: 5px 20px 5px 12px;
  font-size: 12px;
  font-weight: 500;
}

.ArrowLess {
  transform: rotate(180deg);
  margin-right: 8px;
}