@import '../../../assets/styles/color';

.Devices {
  & .TopSection {
    height: 68px;
    background-color: $navigation-bg-color;
    display: flex;
    border-bottom: 1px solid $border-color;
    padding: 10px 30px 15px 30px;
    align-items: end;
    gap: 20px;

    & .SearchWrapper {
      flex: 1;

      & .SearchInput{
        width: 100%;
        height: 38px;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
        border: none;
        color: $main-text-color;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        background: url(../../../assets/icons/search.svg) no-repeat scroll 30px 50%;
        background-color: $card-bg-color;
        text-indent: 70px;
      }

      & .SearchInputIncorrect {
        border: 1px solid $danger-color;
      }

      & input[type="text"]:focus{
        outline: none;
      }

      & .Hint {
        font-size: 12px;
        color: $sub-text-color;
        margin-bottom: 10px;
      }
    }

    & .AddButtonWrapper {
      width: 200px;
    }
  }

  & .DevicesTable {
    padding: 20px;
  }
}