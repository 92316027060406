@import "../../../assets/styles/color";

.Rule {
  & .Flex {
    display: flex;
    & .LeftTextField {
      flex: 1;
      margin-right: 15px;
      & label.ThresholdTitle {
        display: inline-block;
        font-size: 12px;
        font-weight: bold;
        color: $form-label-color;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
      }
    }
    & .RightTextField {
      flex: 1;
    }
  }
  & .AddButton {
    margin-left: 10px;
    flex: 0 1 60px;
    & .IconButton {
      margin-left: 7px;
      padding: 9px 0;
      cursor: pointer;
    }
  }
}
