
.LoadingCard {
  --card-padding: 20px;
  --card-height: 120vw;
  --card-skeleton: linear-gradient(#2b2b30 max(var(--card-height), 370px), transparent 0);
  
  --avatar-size: 53px;
  --avatar-position: var(--card-padding) 61px;
  --avatar-skeleton: radial-gradient(36px, rgba(157, 159, 164, 0.15)  100%, transparent 0);

  --title-height: 8px;
  --title-width: 53px;
  --title-position: var(--card-padding) 192px;
  --title-skeleton: linear-gradient(rgba(107, 109, 112, 0.15) var(--title-height), transparent 0);
  
  --desc-line-height: 12px;
  --desc-line-skeleton: linear-gradient(rgba(107, 109, 112, 0.4) var(--desc-line-height), transparent 0);
  --desc-line-1-width:100px;
  --desc-line-1-position: var(--card-padding) 223px;
  --desc-line-2-width:160px;
  --desc-line-2-position: var(--card-padding) 240px;
  --desc-line-3-width:160px;
  --desc-line-3-position: var(--card-padding) 257px;

  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(max(var(--card-height), 370px) - 40px);

  width: 15vw; //demo
  height: var(--card-height);
  max-width: 255px;
  max-height: 370px;
  min-width: 150px;
  min-height: 217px;
  border: none;
  border-radius: 5px;

  &:empty::after {
    content:"";
    display:block;
    width: 100%;
    height: 100%;
    border-radius:6px;
    box-shadow: 0 10px 45px rgba(0,0,0, .1);

    background-image:
      linear-gradient(
        90deg, 
        rgba(#2b2b30, 0) 0, 
        rgba(#2b2b30, .8) 50%, 
        rgba(#2b2b30, 0) 100%
      ),                          //animation blur
      var(--title-skeleton),      //title
      var(--desc-line-skeleton),  //desc1
      var(--desc-line-skeleton),  //desc2
      var(--desc-line-skeleton),  //desc3
      var(--avatar-skeleton), //avatar
      var(--card-skeleton)        //card
    ;

    background-size:
      var(--blur-size),
      var(--title-width) var(--title-height),
      var(--desc-line-1-width) var(--desc-line-height),
      var(--desc-line-2-width) var(--desc-line-height),
      var(--desc-line-3-width) var(--desc-line-height),
      var(--avatar-size) var(--avatar-size),
      100% 100%
    ;
    
    background-position:
      -150% 0,                      //animation
      var(--title-position),        //title
      var(--desc-line-1-position),  //desc1
      var(--desc-line-2-position),  //desc2
      var(--desc-line-3-position),  //desc3
      var(--avatar-position),       //avatar
      0 0                           //card
    ;

    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
}

.LoadingCardNoPic{
  --card-padding: 20px;
  --card-height: 120vw;
  --card-skeleton: linear-gradient(#2b2b30 max(var(--card-height), 370px), transparent 0);

  --title-height: 8px;
  --title-width: 53px;
  --title-position: var(--card-padding) 76px;
  --title-skeleton: linear-gradient(rgba(107, 109, 112, 0.15) var(--title-height), transparent 0);
  
  --desc-line-height: 12px;
  --desc-line-skeleton: linear-gradient(rgba(107, 109, 112, 0.4) var(--desc-line-height), transparent 0);
  --desc-line-1-width:100px;
  --desc-line-1-position: var(--card-padding) 107px;
  --desc-line-2-width:160px;
  --desc-line-2-position: var(--card-padding) 124px;
  --desc-line-3-width:160px;
  --desc-line-3-position: var(--card-padding) 142px;
  --desc-line-4-width:160px;
  --desc-line-4-position: var(--card-padding) 240px;

  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(max(var(--card-height), 370px) - 40px);

  width: 15vw; //demo
  height: var(--card-height);
  max-width: 255px;
  max-height: 370px;
  min-width: 150px;
  min-height: 217px;
  border: none;
  border-radius: 5px;

  &:empty::after {
    content:"";
    display:block;
    width: 100%;
    height: 100%;
    border-radius:6px;
    box-shadow: 0 10px 45px rgba(0,0,0, .1);

    background-image:
      linear-gradient(
        90deg, 
        rgba(#2b2b30, 0) 0, 
        rgba(#2b2b30, .8) 50%, 
        rgba(#2b2b30, 0) 100%
      ),                          //animation blur
      var(--title-skeleton),      //title
      var(--desc-line-skeleton),  //desc1
      var(--desc-line-skeleton),  //desc2
      var(--desc-line-skeleton),  //desc3
      var(--desc-line-skeleton),  //desc4
      var(--card-skeleton)        //card
    ;

    background-size:
      var(--blur-size),
      var(--title-width) var(--title-height),
      var(--desc-line-1-width) var(--desc-line-height),
      var(--desc-line-2-width) var(--desc-line-height),
      var(--desc-line-3-width) var(--desc-line-height),
      var(--desc-line-4-width) var(--desc-line-height),
      100% 100%
    ;
    
    background-position:
      -150% 0,                      //animation
      var(--title-position),        //title
      var(--desc-line-1-position),  //desc1
      var(--desc-line-2-position),  //desc2
      var(--desc-line-3-position),  //desc3
      var(--desc-line-4-position),  //desc4
      0 0                           //card
    ;

    background-repeat: no-repeat;
    animation: loadingnopic 1.5s infinite;
  }
}

.LoadingCardMobile {
  --card-padding: 20px;
  --card-height: 120vw;
  --card-skeleton: linear-gradient(#2b2b30 var(--card-height), transparent 0);
  
  --avatar-size: 53px;
  --avatar-position: var(--card-padding) 61px;
  --avatar-skeleton: radial-gradient(36px, rgba(157, 159, 164, 0.15)  100%, transparent 0);

  --title-height: 8px;
  --title-width: 53px;
  --title-position: var(--card-padding) 192px;
  --title-skeleton: linear-gradient(rgba(107, 109, 112, 0.15) var(--title-height), transparent 0);
  
  --desc-line-height: 12px;
  --desc-line-skeleton: linear-gradient(rgba(107, 109, 112, 0.4) var(--desc-line-height), transparent 0);
  --desc-line-1-width:100px;
  --desc-line-1-position: var(--card-padding) 223px;
  --desc-line-2-width:160px;
  --desc-line-2-position: var(--card-padding) 240px;
  --desc-line-3-width:160px;
  --desc-line-3-position: var(--card-padding) 257px;

  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(var(--card-height) - 40px);

  width: calc(100vw - 30px); //demo
  height: var(--card-height);
  border: none;
  border-radius: 5px;

  &:empty::after {
    content:"";
    display:block;
    width: 100%;
    height: 100%;
    border-radius:6px;
    box-shadow: 0 10px 45px rgba(0,0,0, .1);

    background-image:
      linear-gradient(
        90deg, 
        rgba(#2b2b30, 0) 0, 
        rgba(#2b2b30, .8) 50%, 
        rgba(#2b2b30, 0) 100%
      ),                          //animation blur
      var(--title-skeleton),      //title
      var(--desc-line-skeleton),  //desc1
      var(--desc-line-skeleton),  //desc2
      var(--desc-line-skeleton),  //desc3
      var(--avatar-skeleton), //avatar
      var(--card-skeleton)        //card
    ;

    background-size:
      var(--blur-size),
      var(--title-width) var(--title-height),
      var(--desc-line-1-width) var(--desc-line-height),
      var(--desc-line-2-width) var(--desc-line-height),
      var(--desc-line-3-width) var(--desc-line-height),
      var(--avatar-size) var(--avatar-size),
      100% 100%
    ;
    
    background-position:
      -150% 0,                      //animation
      var(--title-position),        //title
      var(--desc-line-1-position),  //desc1
      var(--desc-line-2-position),  //desc2
      var(--desc-line-3-position),  //desc3
      var(--avatar-position),       //avatar
      0 0                           //card
    ;

    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
}

.LoadingCardNoPicMobile{
  --card-padding: 20px;
  --card-height: 120vw;
  --card-skeleton: linear-gradient(#2b2b30 var(--card-height), transparent 0);

  --title-height: 8px;
  --title-width: 53px;
  --title-position: var(--card-padding) 76px;
  --title-skeleton: linear-gradient(rgba(107, 109, 112, 0.15) var(--title-height), transparent 0);
  
  --desc-line-height: 12px;
  --desc-line-skeleton: linear-gradient(rgba(107, 109, 112, 0.4) var(--desc-line-height), transparent 0);
  --desc-line-1-width:100px;
  --desc-line-1-position: var(--card-padding) 107px;
  --desc-line-2-width:160px;
  --desc-line-2-position: var(--card-padding) 124px;
  --desc-line-3-width:160px;
  --desc-line-3-position: var(--card-padding) 142px;
  --desc-line-4-width:160px;
  --desc-line-4-position: var(--card-padding) 240px;

  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(var(--card-height) - 40px);

  width: calc(100vw - 30px); //demo
  height: var(--card-height);
  border: none;
  border-radius: 5px;

  &:empty::after {
    content:"";
    display:block;
    width: 100%;
    height: 100%;
    border-radius:6px;
    box-shadow: 0 10px 45px rgba(0,0,0, .1);

    background-image:
      linear-gradient(
        90deg, 
        rgba(#2b2b30, 0) 0, 
        rgba(#2b2b30, .8) 50%, 
        rgba(#2b2b30, 0) 100%
      ),                          //animation blur
      var(--title-skeleton),      //title
      var(--desc-line-skeleton),  //desc1
      var(--desc-line-skeleton),  //desc2
      var(--desc-line-skeleton),  //desc3
      var(--desc-line-skeleton),  //desc4
      var(--card-skeleton)        //card
    ;

    background-size:
      var(--blur-size),
      var(--title-width) var(--title-height),
      var(--desc-line-1-width) var(--desc-line-height),
      var(--desc-line-2-width) var(--desc-line-height),
      var(--desc-line-3-width) var(--desc-line-height),
      var(--desc-line-4-width) var(--desc-line-height),
      100% 100%
    ;
    
    background-position:
      -150% 0,                      //animation
      var(--title-position),        //title
      var(--desc-line-1-position),  //desc1
      var(--desc-line-2-position),  //desc2
      var(--desc-line-3-position),  //desc3
      var(--desc-line-4-position),  //desc4
      0 0                           //card
    ;

    background-repeat: no-repeat;
    animation: loadingnopic 1.5s infinite;
  }
}

@keyframes loading {
  to {
    background-position:
      350% 0,        
      var(--title-position),  
      var(--desc-line-1-position),
      var(--desc-line-2-position),
      var(--desc-line-3-position),
      var(--avatar-position),
      0 0
    ;
  }
}

@keyframes loadingnopic {
  to {
    background-position:
      350% 0,        
      var(--title-position),  
      var(--desc-line-1-position),
      var(--desc-line-2-position),
      var(--desc-line-3-position),
      var(--desc-line-4-position),
      0 0
    ;
  }
}
