@import '../../../../assets/styles/color';

.Card {
  width: 100%;
  min-height: 315px;
  border-radius: 5px;
  background-color: $card-bg-color;
  border: 1px solid $card-bg-color;
  justify-self: center;
  box-sizing: border-box;
  position: relative;
  transition: transform 0.15s ease-in;
  overflow: hidden;
  padding-bottom: 20px;
  &:hover {
    border: 1px solid $primary-color;
    cursor: pointer;
  }

  &.CardAddingStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & .AddIcon {
      margin-bottom: 20px;
      width: 120px;
      align-self: center;
    }
  }
  &.CardFlexStyle {
    display: flex;
    flex-direction: column;
  }
  & .CardBody {
    padding: 0 30px;
    &.Flex {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    & .CardTitle {
      color: $card-text-color;
      font-size: 18px;
      margin-top: 30px;
      margin-bottom: 15px;
      font-weight: 600;
    }
    & .StatusDeviceWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      & .CardSubTitle {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        color: $card-text-color;
        font-size: 14px;
        font-weight: 500;
      }
    }
    & .StatusBox {
      display: inline-block;
      border-radius: 4px;
      padding: 5px 10px;
      margin: 5px;
      & .ReasonText {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  
  .ShadowText {
    text-shadow: 1px 0 0 #4b4b52, -1px 0 0 #4b4b52, 0 1px 0 #4b4b52, 0 -1px 0 #4b4b52, 1px 1px #4b4b52, -1px -1px 0 #4b4b52, 1px -1px 0 #4b4b52, -1px 1px 0 #4b4b52;
  }
  .ShadowBox {
    box-shadow: 1px 0 0 #4b4b52, -1px 0 0 #4b4b52, 0 1px 0 #4b4b52, 0 -1px 0 #4b4b52, 1px 1px #4b4b52, -1px -1px 0 #4b4b52, 1px -1px 0 #4b4b52, -1px 1px 0 #4b4b52;
  }
}