@import '../../../assets/styles/color';

.Members {
  & .TopSection {
    background-color: $navigation-bg-color;
    display: flex;
    border-bottom: 1px solid $border-color;
    & .MenuWrapper {
      flex: 1;
      display: flex;
      margin-left: 30px; 
      & .Menu {
        display: flex;
        align-items: center;
        border-bottom: 3px solid $primary-color;
        & .MenuTitle {
          font-size: 14px;
          font-weight: 600;
          color: $sub-text-color;
        }
      }
    }
    & .AddButtonWrapper {
      display: flex;
      align-items: center;
      margin: 14px 30px 14px 0;
      width: 200px;
    }
  }

  & .MembersTable {
    background-color: $card-bg-color;
    border-radius: 4px;
    & .MemberCard {
      border-bottom: 1px solid $main-container-bg-color;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    }
    & .SearchWrapper {
      display: flex;
      padding: 20px 30px;
      & .SearchInput {
        font-size: 14px;
        font-weight: 500;
        color: $sub-text-color;
        -webkit-appearance: none;
        appearance: none;
        font-family: inherit;
        width: 100%;
        border: none;
        background-color: $card-bg-color;
        box-sizing: border-box;
        outline: none;
        resize: none;
      }
    }
    .LoadingWideCard {
      background-color: $main-container-bg-color;
    }
  }
}