@import '../../../../../assets/styles/color';

.LightStyle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);

  & .Value {
    display: inline-block;
    font-size: 24px;
    font-weight: 500;
    color: white;
  }

  & .Unit {
    display: inline-block;
    margin-left: 15px;
    font-size: 16px;
    color: $sub-text-color;
  }
}

.BgStyle {
  position: relative;
  height: 100%;
  border-radius: 4px;
  text-align: center;

  & .BgTextWrapper {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);

    & .Value {
      display: inline-block;
      font-size: 48px;
      font-weight: 500;
      color: $main-text-color;
    }
  
    & .Unit {
      font-size: 24px;
      color: $card-text-color;
    }
  }
}