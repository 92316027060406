@import '../../../../assets/styles/color';

.Nav {
  // display: -moz-flex;
  // display: -webkit-flex;
  // display: flex;
  // flex-direction: column;
  // -ms-flex-wrap: wrap;
  // flex-wrap: wrap;
  // padding: 0;
  // margin: 0;
  // list-style: none;
  // height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  height: calc(100% - 190px);
  overflow: overlay;
}

.NavItem {
  display: flex;
  text-decoration: none;
  cursor: pointer;

  &.Active {
    & .ActiveLink {
      background-color: $primary-color;
      border-radius: 5px;
      margin: 0 11px 0 7px;
      padding-left: 33px;
    }
    & .LinkBorder {
      border-left: 4px solid $primary-color;
    }
  }

  & .NavLink {
    display: flex;
    height: 53px;
    color: white;
    width: 100%;
    text-decoration: none;
    padding-left: 40px;
    flex: 1;
    & .MenuTitle {
      flex: 1;
      font-size: 14px;
      line-height: 53px;
      font-weight: bold;
      margin-left: 16px;
      white-space: nowrap;
      user-select: none;
    }
    
    & .MenuIcon {
      height: 20px;
      width: 20px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      transform: -moz-translateY(-50%);
      transform: -webkit-translateY(-50%);
    }
    
    & .SubMenuIcon {
      position: relative;
      height: 7px;
      width: 12px;
      right: 30px;
      top: 45%;
      transform: translateY(-50%);
      transform: -moz-translateY(-50%);
      transform: -webkit-translateY(-50%);
      transition: transform 0.15s ease-in;
        &.Close {
        transform: rotate(0);
      }
      &.Open {
        transform: rotate(180deg);
      }
      &.OpenActive {
        transform: rotate(180deg);
        right: 19px;
      }
    }
  }

  & .SubMenuWrapper {
    background-color: $main-container-bg-color;
    margin: 0px 1px;
    transition: max-height 0.3s ease-in;

    & .NavSubMenu {
      text-decoration: none;
      &.Active {
        & .SubMenuLabel {
          color: $primary-color;
        }
      }
      & .SubMenuLabel {
        padding: 15px;
        padding-left: 70px;
        font-size: 14px;
        font-weight: 500;
        color: $sub-text-color;
        &:hover {
          color: $primary-color;
        }
      }
    }
    
    & .SubMenuDivider {
      height: 1px;
      background-color: $border-color;
      margin: 0 35px;
    }
    &.Close {
      overflow: hidden;
      max-height: 0;
    }
    &.Open {
      max-height: 500px;
    }
  }

  .LinkBorder {
    border-left: 4px solid $navigation-bg-color;
    border-radius: 4px;
    height: 53px;
    width: 10px;
    padding: 0;
    box-sizing: border-box;
  }
}

.comingsoon {
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  margin-left: 12px;
  color: $form-label-color;
}

@media screen and (max-width: 1024px) {
  .Nav {
    height: calc(100% - 145px);
  }

  .NavItem.Active .ActiveLink {
    padding-left: 17px;
  }
  .NavItem .NavLink {
    padding-left: 24px;
  }
  
  .Nav:hover .MenuTitleMobile {
    display: block;
  }

  .MenuTitleMobile {
    display: none;
  }

  .Nav:hover .SubMenuIcon {
    display: block;
  }
  .SubMenuIcon {
    display: none;
  }
}