@import '../../../assets/styles/color';

.NoteType {
  background-color: $card-bg-color;
  border-radius: 4px;

  & .NoteTypeCard {
    // & tr:not(:first-child)
    border-bottom: 4px solid $main-container-bg-color;
    padding: 20px 30px;
    display: flex;
    & .NoteTypeInfos {
      flex: 1;
      width: 60%;
      display: flex;
      flex-direction: column;
      & .NoteTypeName {
        font-size: 16px;
        font-weight: 600;
        color: $card-text-color;
        line-height: 1.57;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 90%;
        & .NoteTypePlans {
          font-size: 14px;
          font-weight: 500;
          display: inline-block;
        }
      }
      & .NoteTypeTime {
        font-size: 12px;
        color: $form-label-color;
        line-height: 1.57;
      }
    }
  }
}

.ActionButtons {
  display: flex;
  align-items: center;
  cursor: pointer;
    & .ArrowIcon {
    margin-left: 30px;
    transform: rotate(180deg);
    transition: transform 0.25s ease-in;
    vertical-align: super;
    &.ToggleDown {
      transform: rotate(0deg);
    }
  }
}