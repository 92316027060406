@import '../../../../../assets/styles/color';

.LoadingTable {
  --card-padding: 30px;
  --card-height: 55px;
  --card-skeleton: linear-gradient(#222226 var(--card-height), transparent 0);

  --desc-line-height: 12px;
  --desc-line-skeleton: linear-gradient(rgba(107, 109, 112, 0.15) var(--desc-line-height), transparent 0);
  --desc-line-1-width:300px;
  --desc-line-1-position: var(--card-padding) 20px;
  --desc-line-2-width:300px;
  --desc-line-2-position: 400px 20px;

  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(var(--card-height) - 40px);

  width: 100%; //demo
  height: var(--card-height);
  border: none;
  border-radius: 5px;

  &:empty::after {
    content:"";
    display:block;
    width: 100%;
    height: 100%;
    border-radius:6px;

    background-image:
      linear-gradient(
        90deg, 
        rgba($navigation-bg-color, 0) 0, 
        rgba($navigation-bg-color, .8) 50%, 
        rgba($navigation-bg-color, 0) 100%
      ),                          //animation blur
      var(--desc-line-skeleton),  //desc1
      var(--desc-line-skeleton),  //desc2
      var(--card-skeleton)        //card
    ;

    background-size:
      var(--blur-size),
      var(--desc-line-1-width) var(--desc-line-height),
      var(--desc-line-2-width) var(--desc-line-height),
      100% 100%
    ;
    
    background-position:
      -10% 50%,                      //animation
      var(--desc-line-1-position),  //desc1
      var(--desc-line-2-position),  //desc2
      0 0                           //card
    ;

    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  to {
    background-position:
      350% 50%,       
      var(--desc-line-1-position),
      var(--desc-line-2-position),
      0 0
    ;
  }
}