@import "../../../../assets/styles/color";

.ColorRangeForm {
  display: flex;
  & label.Title {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: $form-label-color;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  & .AddButton {
    margin: 25px auto auto 10px;
    flex: 0 1 60px;
    & .IconButton {
      margin-left: 7px;
      padding: 9px 0;
      cursor: pointer;
    }
  }
}
