.Container {
    margin-top: 16px;
    // padding: 32px ;
    margin-bottom: 16px;
}

.ImagePane {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    position: relative;
    overflow: auto;
    width: 1280px;
    min-height: 720px;
    // background-color: lightgreen;
    padding: 8px;
    // background-image: url(https://www.researchgate.net/profile/Brigita-Gajsek/publication/285597636/figure/fig1/AS:614024513335301@1523406260548/Layout-of-the-production-area.png);
    // background-image: url("../../../assets/images/floor_plan_test_black.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
}

.ImagePaneFullScreen {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    position: relative;
    overflow: auto;
    width: calc(100vw - 64px);
    height: calc(100vh - 128px);
    // background-color: lightgreen;
    padding: 8px;
    // background-image: url(https://www.researchgate.net/profile/Brigita-Gajsek/publication/285597636/figure/fig1/AS:614024513335301@1523406260548/Layout-of-the-production-area.png);
    // background-image: url("../../../assets/images/floor_plan_test_black.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
}
