@import "../../../../assets/styles/color";

.Label {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: $form-label-color;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.Divider {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: $border-color;
  margin: 24px 0 16px;
}

.SocketsWrapper {
  & .SlideSocket {
    cursor: pointer;
    height: 38px;
    border-radius: 4px;
    border: solid 1px $border-color;
    background-color: $border-color;
    margin-bottom: 16px;
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
    & .Socket {
      padding: 10px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      color: $sub-text-color;
      &.Error {
        color: $danger-color;
      }
      & .SocketNumber {
        flex: 1;
      }

      & .DeleteIcon {
        margin-right: 10px;
        cursor: pointer;
      }
    }
    & .Active {
      color: $primary-color;
    }
  }

  & .CollapseContainer {
    &.Close {
      overflow: hidden;
      height: 0;
    }
    &.Open {
      height: 100%;
      // transition: all 2s;
    }
  }
  
}

.AddSocketButton {
  color: $primary-color;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  border: 1px dashed $primary-color;
  text-align: center;
  padding: 10px 0;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0;

  & .AddIcon {
    margin-right: 10px;
    vertical-align: middle;
  }
  & .ButtonName {
    display: inline-block;
  }
}