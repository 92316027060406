@import '../../../../assets/styles/color';
@import "../../../../assets/styles/color";

.SymbolModal {
    position: absolute;
    z-index: 1000;
    bottom: 40px;
    width: 250px;
    border-radius: 2px;
    background-color: $light-bg-color;
    padding: 6px 0;
    color: $auth-title-color;

    &::after {
        content: "";
        position: absolute;
        left: 46%;
        top: 39px;
        border-width: 10px;
        border-style: solid;
        border-color: $light-bg-color transparent transparent transparent;
    }

    & .SymbolsContainer {
        display: flex;
        margin: 0 10px;
        & .EachSymbol {
            border-radius: 2px;
            color: $sub-text-color;
            border: 2px solid $sub-text-color;
            width: 25px;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            margin: 0px 3px;
            &.Selected {
                color: $main-text-color;
                border-color: $primary-color;
                background-color: $primary-color;
            }
        }
        & .BorderDivider {
            border-left: 2px solid $sub-text-color;
            margin: 0 4px
        }
    }
}