@import "../../../../assets/styles/color";

.Topbar {
  position: fixed;
  top: 0;
  z-index: 99;
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  align-items: center;
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 0 0 $border-color;
  background-color: $navigation-bg-color;
}

.LeftItems {
  flex: 1;
  height: 40px;
  margin-left: 60px;
  display: flex;
  align-items: center;

  & .Orgs {
    font-size: 14px;
    color: $primary-color;
    font-weight: 600;
    cursor: pointer;
    display: flex;
  }
  & .ArrowDown {
    margin-left: 10px;
    vertical-align: middle;
  }
}

.RightItems {
  display: flex;
  margin-right: 20px;
  width: 150px;
  justify-content: flex-end;
}

.RightIcon {
  margin: auto 10px;
  cursor: pointer;
  & .ProfilePicture{
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }
}

.ProfileMenu {
  cursor: pointer;
}

.DropdownContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  left: 0;
  top: 0;
}
.Dropdown {
  position: relative;
  display: inline-block;
  transition: transform .3s ease-in-out;
  & ul {
    visibility: hidden;
    opacity: 0;
    transition: visibility .3s ease-out, opacity .2s ease-out;
    list-style-type: none;
    padding: 0;
    right: 0px;
    top: 0px;
    width: 225px;
    background-color: $card-bg-color;
    font-weight: bold;
    position: absolute;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    border-radius: 4px;
  }
  & li {
    cursor: pointer;
    max-height: 42px;
    border-bottom: 1px solid $main-container-bg-color;
    color: $card-text-color;
    text-decoration: none;
    border-left: 3px solid $card-bg-color;
    border-radius: 4px;
  }
  & li:hover{
    background: $main-container-bg-color;
    border-left: 3px solid $primary-color;
    border-radius: 4px;
  }
  & ul::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: 60px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent $card-bg-color transparent;
  }
}

.DropdownIn{
  transform: translateY(45px);
  & ul{
    visibility: visible;
    opacity: 1;
    }
}

.DropdownOut{
  transform: translateY(30px);
  & ul{
    visibility: hidden;
    opacity: 0;
  }
}

.ListItem{
  margin: 10px;
}

.Logout{
  color: $danger-color;
}

.Divider {
  background-color: $border-color;
  height: 40px;
  width: 1px;
  margin: 0 30px;
}


