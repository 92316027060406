@import "../../../../assets/styles/color";

.toggle{
    border: 2px solid rgba(137, 194, 217, .4);
    border-radius: 20px;
    box-sizing: border-box;
    width: 60px;
    height: 30px;
    padding: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
}

.toggle.toggleOn{
    -webkit-transition: all .5s .15s ease-out;
    transition: all .5s .15s ease-out;
    justify-content: right;
}

.toggleButton{
    width: 22px;
    height: 22px;
    background-color: #89c2da;
    box-shadow: 0 0 16px #4b7a8d;
    border-radius: 100%; 
    -webkit-transition: all .3s .1s, width .1s, top .1s, bottom .1s;
    transition: all .3s .1s, width .1s, top .1s, bottom .1s;
}

.toggleOn .toggleButton{
    width: 22px;
    height: 22px;
    border-radius: 100%;
    -webkit-transition: all .2s .1s, right .1s;
    transition: all .2s .1s, right .1s;
}

.toggleText{
    line-height: 30px;
    text-align: center;
    // font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-weight: 600;
    -webkit-user-select: none; /* Chrome/Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    // color: #444249;
    color: #89c2da;
}