@import '../../../../../assets/styles/color';

.IconWrapper {
  margin: 28px auto auto 5px;
  flex: 0 1 30px;
  & .IconButton {
    margin-left: 7px;
    padding: 9px 0;
    cursor: pointer;
  }
}

.AddPowerPriceButton {
  color: $primary-color;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  border: 1px dashed $primary-color;
  text-align: center;
  padding: 10px 0;
  border-radius: 4px;
  cursor: pointer;
  margin: 20px 0;

  & .AddIcon {
    margin-right: 10px;
    vertical-align: middle;
  }
  & .ButtonName {
    display: inline-block;
  }
}

.ErrorMessage {
  color: $danger-color;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
}