@import '../../../assets/styles/color';

.FormulaBuilder {
  display: flex;
  flex-direction: column;
  & .TopSection {
    background-color: $navigation-bg-color;
    display: flex;
    border-bottom: 1px solid $border-color;
    & .MenuWrapper {
      flex: 1;
      display: flex;
      margin-left: 30px; 
      min-height: 67px;
      & .ActiveMenu {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: 3px solid $primary-color;
        margin-right: 40px;
        & .MenuTitle {
          font-size: 14px;
          font-weight: 600;
          color: $sub-text-color;
        }
      }
      & .Menu {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: 3px solid $navigation-bg-color;
        margin-right: 40px;
        & .MenuTitle {
          font-size: 14px;
          font-weight: 600;
          color: $sub-text-color;
        }
      }
    }
    & .AddButtonWrapper {
      margin: 14px 30px;
      width: 200px;
    }
  }

  & .CardGridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(255px, 255px));
    grid-gap: 30px;
  }

  & .FormulaTopics {
    flex: 0 1 400px;
    background-color: $navigation-bg-color;
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;

    & .FormulaFix {
      position: fixed;
      width: 400px;
      overflow: overlay;      
    }
  }
  & .FormulaDetails {
    flex: 1;
    padding: 30px;
  }
}

.FormulaBody {
  display: flex;
  min-height: calc(100vh - 128px);
}


.AddButton {
  margin-left: 5px;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  background-color: $primary-color;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $main-text-color;
  cursor: pointer;
}

.Container {
  & .Group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    & .Box {
      padding: 0 10px;
      min-width: 30px;
      height: 50px;
      border: 1px solid $form-label-color;
      background-color: $card-bg-color;
      border-radius: 2px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    } 
    & .Symbol {
      width: 20px;
      height: 20px;
      border: 1px solid $form-label-color;
      background-color: $card-bg-color;
      border-radius: 2px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .Parentheses {
      font-size: 60px;
      padding-bottom: 8px;
    }
    & .OperatorGroup {
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 130px;
        height: 1px;
        background: $sub-text-color;
        left: -200px;
        top: 50%;
        position: absolute;
      }
      &:after {
        content: "";
        display: block;
        width: 130px;
        height: 1px;
        background: $sub-text-color;
        right: -200px;
        top: 50%;
        position: absolute;
      }
    }
  }
}

.LoadingCard{
  --card-padding: 30px;
  --card-height: 82px;
  --card-skeleton: linear-gradient(#222226 var(--card-height), transparent 0);

  --desc-line-height: 12px;
  --desc-line-skeleton: linear-gradient(rgba(107, 109, 112, 0.15) var(--desc-line-height), transparent 0);
  --desc-line-1-width:100px;
  --desc-line-1-position: var(--card-padding) 27px;
  --desc-line-2-width:160px;
  --desc-line-2-position: var(--card-padding) 47px;

  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(var(--card-height) - 40px);

  width: 100%; //demo
  height: var(--card-height);
  border-bottom: 1px solid $border-color;
  border-radius: 5px;

  &:empty::after {
    content:"";
    display:block;
    width: 100%;
    height: 100%;
    border-radius:6px;
    // box-shadow: 0 10px 45px rgba(0,0,0, .1);

    background-image:
      linear-gradient(
        90deg, 
        rgba(#222226, 0) 0, 
        rgba(#222226, .8) 50%, 
        rgba(#222226, 0) 100%
      ),                          //animation blur
      var(--desc-line-skeleton),  //desc1
      var(--desc-line-skeleton),  //desc2
      var(--card-skeleton)        //card
    ;

    background-size:
      var(--blur-size),
      var(--desc-line-1-width) var(--desc-line-height),
      var(--desc-line-2-width) var(--desc-line-height),
      100% 100%
    ;
    
    background-position:
      -100% 50%,                      //animation
      var(--desc-line-1-position),  //desc1
      var(--desc-line-2-position),  //desc2
      0 0                           //card
    ;

    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  to {
    background-position:
      100% 50%,       
      var(--desc-line-1-position),
      var(--desc-line-2-position),
      0 0
    ;
  }
}