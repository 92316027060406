@import '../../../../assets/styles/color';

.FormGroup {
  margin-bottom: 38px;
  
  & label.Title {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $form-label-color;
    margin-bottom: 1rem;
  }

  & .WidgetButtonWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(110px, 110px));
    grid-gap: 8px;
    & .WidgetButton {
      cursor: pointer;
      border-radius: 4px;
      box-shadow: -10px 0 20px 0 rgba(0, 0, 0, 0.3);
      background-color: $main-container-bg-color;
      border: 1px solid $form-label-color;
      height: 110px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.Selected {
        background-color: $primary-color;
        border: 1px solid $primary-color;
      }
    }
    & .WidgetButtonLabel {
      flex: 1;
      font-size: 14px;
      font-weight: 600;
      color: $widget-text-color;
    }
  }
}