@import "../../../../assets/styles/color";

.ChatRoom {
  background-color: $main-container-bg-color;
  height: calc(100vh - 70px);
  & .TopSection {
    height: 68px;
    background-color: $navigation-bg-color;
    display: flex;
    border-bottom: 1px solid $border-color;
      & .Name {
        font-size: 18px;
        font-weight: 600;
        color: $main-text-color;
    }
  }
}
