@import '../../../../assets/styles/color';

.Sidebar {
  height: 100%;
}

.Topic {
  margin: 25px 45px;
  color: $sub-text-color;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
}

.BottomSection {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.SignOutButton {
  cursor: pointer;
  display: flex;
  height: 80px;
  color: white;
  text-decoration: none;
  padding: 0 45px;
  border-top: 1px solid $border-color;

  & .MenuTitle {
    font-size: 14px;
    line-height: 80px;
    font-weight: bold;
    margin-left: 16px;
  }

  & .MenuIcon {
    height: 20px;
    width: 20px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transform: -moz-translateY(-50%);
    transform: -webkit-translateY(-50%);
  }
}

.Credit {
  border-top: 1px solid $border-color;
  padding: 25px 45px;
  font-size: 14px;
  color: $sub-text-color;
}

.BottomMenuWrapper {
  display: flex;
  cursor: pointer;
}
.BottomMenu {
  display: flex;
  height: 53px;
  color: white;
  width: 100%;
  text-decoration: none;
  padding-left: 50px;
  flex: 1;

  & .MenuTitle {
    flex: 1;
    font-size: 14px;
    line-height: 53px;
    font-weight: bold;
    margin-left: 16px;
    white-space: nowrap;
    user-select: none;
  }

  & .MenuIcon {
    height: 20px;
    width: 20px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transform: -moz-translateY(-50%);
    transform: -webkit-translateY(-50%);
  }
}

.DropdownContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  left: 0;
  top: 0;
}
.Dropdown {
  position: relative;
  display: inline-block;
  transition: transform .3s ease-in-out;
  & ul {
    visibility: hidden;
    opacity: 0;
    transition: visibility .3s ease-out, opacity .2s ease-out;
    list-style-type: none;
    padding: 0;
    left: -40px;
    top: -11px;
    width: 150px;
    background-color: $card-bg-color;
    font-weight: bold;
    position: absolute;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    border-radius: 4px;
  }
  & li {
    cursor: pointer;
    max-height: 42px;
    // border-bottom: 1px solid $main-container-bg-color;
    color: $card-text-color;
    text-decoration: none;
    border-left: 3px solid $card-bg-color;
    border-radius: 4px;
  }
}

.DropdownIn{
  transform: translateX(45px);
  & ul{
    visibility: visible;
    opacity: 1;
    }
}

.DropdownOut{
  transform: translateX(30px);
  & ul{
    visibility: hidden;
    opacity: 0;
  }
}

.ListItem{
  margin: 10px;
}

@media screen and (max-width: 1024px) {
  .Topic {
    margin: 25px auto;
    margin-left: 20px;
  }

  .Credit {
    padding: 10px 5px;
    font-size: 10px;
  }

  .BottomMenu {
    padding-left: 34px;
  }
  
  .Sidebar:hover .MenuTitleMobile {
    display: block;
  }

  .BottomMenuWrapper .MenuTitleMobile {
    display: none;
  }
}