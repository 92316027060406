@import "../../../../assets/styles/color";

.AddNewAnomalyWrapper {
  & .AddFormWrapper {
    position: relative;
    background-color: $navigation-bg-color;
    border: 1px solid $border-color;
    padding: 25px 30px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    & .Divider {
      width: 100%;
      height: 1.5px;
      background-color: $border-color;
      margin: 30px 0;
      border: 0;
    }
    & .CloseIcon {
      cursor: pointer;
      position: absolute;
      right: 20px;
    }
    & .Title {
      color: $card-text-color;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.56;
    }
    & .Description {
      color: $sub-text-color;
      font-size: 14px;
      line-height: 1.56;
    }
    & .Flex {
      display: flex;
      flex-wrap: wrap;
      column-gap: 5px;
      row-gap: 5px;
      justify-content: space-between;
      & .LeftTextField {
        flex: 1;
        min-width: 400px;
        & label.ThresholdTitle {
          display: inline-block;
          font-size: 12px; 
          font-weight: bold;
          color: $form-label-color;
          text-transform: uppercase;
          margin-bottom: 0.5rem;
        }
      }
      & .RightTextField {
        flex: 1;
        min-width: 400px;
      }
    //   & .MiddleTextField {
    //     flex: 1;
    //     min-width: 200px;
    //     // margin-right: 5px;
    //   }
      & .countingCharacters {
        color: $form-label-color;
        text-align: right;
        font-size: 10px;
        font-weight: 500;
        margin-top: 5px;
        margin-right: 5px;
      }
    }
  }

  & .ButtonsWrapper {
    padding: 45px 30px;
    display: flex;
    justify-content: space-evenly;
  }
}

.LabelGroup{
  display: flex;
  margin-bottom: 1rem;
  & .Label {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $form-label-color;
  }
  & .Info{
    margin: auto 10px;
  }
  & .ValueTitle {
      font-size: 16px;
      font-weight: 600;
    & span {
      font-size: 14px;
      opacity: 0.75;
    }
  }
}

.ErrorMessage {
  color: $danger-color;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;  
  margin-right: 7px;
  text-align: right;
}

.Topic {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: $form-label-color;
  margin-bottom: 15px;
}