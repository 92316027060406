@import "../../../assets/styles/color";

.Org {
  background-color: $main-container-bg-color;
  height: 100vh;
  & .OrgBox {
    position: fixed;
    width: 100%;
    max-width: 900px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & .OrgBoxContainer {
      margin: auto;
      width: 80%;
      max-width: 700px;
      border-radius: 5px;
      background-color: #ffffff;
      color: $sub-text-color;
      display: flex;
      flex-direction: column;

      & .HeaderWrapper {
        border-bottom: 1px solid $card-text-color;
        padding: 24px 30px;
        & .Header {
          font-size: 20px;
          color: $auth-title-color;
          font-weight: 600;
          line-height: 1.5;
        }
        & .Description {
          font-size: 14px;
          line-height: 1.57;
        }
      }

      & .FormWrapper {
        flex: 1;
        padding: 30px 30px 0px 30px;
      }

      & .ButtonWrapper {
        padding: 20px 30px;
        border-top: 1px solid $card-text-color;
      }
    }
    & .SwapToSignOut {
      text-align: center;
      margin-top: 35px;
      font-size: 14px;
      color: $card-text-color;
      & .SwapText {
        cursor: pointer;
        color: $primary-color;
        font-weight: 600;
        text-decoration: none;
      }
    }
  }
}

.FormGroup {
  margin-bottom: 1rem;
  flex: 1;
}

.FormAddLogo {
  & input[type="file"] {
    display: none;
  }
  display: flex;
  border-bottom: 1px solid $card-text-color;
}

.CustomFileUpload {
  margin: 15px 0px;
  display: inline-block;
  padding: 0px;
  cursor: pointer;
  & .ImagePreview {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid $card-text-color;
  }
}

.AddLogo {
  margin: auto 0px 15px 15px;
  font-size: 14px;
  font-weight: 500;
}

.ShowSelectedFile {
  font-size: 14px;
  padding: 25px 10px;
  margin: auto 0px;
}
