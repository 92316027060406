@import '../../../../../../assets/styles/color';

.Switch {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ToggleWrapper {
  height: 40px;
  position: relative;
}

.toggle{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid #444249;
  border-radius: 20px;
  -webkit-transition: border-color .6s  ease-out;
  transition: border-color .6s  ease-out;
  box-sizing: border-box;
  width: 75px;
  height: 40px;
}

.toggle.toggleOn{
  border-color: rgba(137, 194, 217, .4);
  -webkit-transition: all .5s .15s ease-out;
  transition: all .5s .15s ease-out;
}

.toggleButton{
  position: absolute;
  top: 4px;
  width: 28px;
  bottom: 4px;
  right: 39px;
  background-color: #444249;
  border-radius: 19px; 
  cursor: pointer;
  -webkit-transition: all .3s .1s, width .1s, top .1s, bottom .1s;
  transition: all .3s .1s, width .1s, top .1s, bottom .1s;
}

.toggleOn .toggleButton{
  top: 3px;
  width: 65px;
  bottom: 3px;
  right: 3px;
  border-radius: 23px;
  background-color: #89c2da;
  box-shadow: 0 0 16px #4b7a8d;
  -webkit-transition: all .2s .1s, right .1s;
  transition: all .2s .1s, right .1s;
}


.toggleTextOn{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 36px;
  text-align: center;
  // font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome/Safari */    
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */


  color: rgba(0,0,0,0);
}

.toggleOn .toggleTextOn{
  color: #3b6a7d;
  -webkit-transition: color .3s .15s ;
  transition: color .3s .15s ;
}

.toggleTextOff{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 6px;
  line-height: 36px;
  text-align: center;
  // font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
  -webkit-user-select: none; /* Chrome/Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  cursor: pointer;

  color: #444249;
}

.toggleOn .toggleTextOff{
  color: rgba(0,0,0,0);
}

/* used for streak effect */
.glowComp{
  position: absolute;
  opacity: 0;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  border-radius: 6px;
  background-color: rgba(75, 122, 141, .1);
  box-shadow: 0 0 12px rgba(75, 122, 141, .2);
  -webkit-transition: opacity 4.5s 1s;
  transition: opacity 4.5s 1s;
}

.toggleOn .glowComp{
  opacity: 1;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.Description {
  font-size: 12px;
  font-weight: 500;
  color: $sub-text-color;
}