@import '../../../../../assets/styles/color';


.Value {
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  color: $main-text-color;
  font-size: 26px;
  &.Small {
    font-size: 20px;
  }
}

.Unit {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  color: $sub-text-color;
  font-size: 12px;
}
