@import '../../../../assets/styles/color';


.ReportWrapper {
    width: 1500px;
    padding: 30px 50px;
    box-sizing: border-box;

    & .HeaderSection {
        display: flex;
        align-items: center;
        padding-bottom: 30px;

        & .HeaderText {
            flex: 1;
            font-size: 32px;
            font-weight: 600;
        }

        & .MonthlySubHeader {
            font-size: 26px;
            font-weight: 600;
            text-align: right;
        }
    }

    & .Eva {
        padding: 20px;

        & .BarWrapper {
            margin-top: 10px;
            width: calc(100% - 35px);
            height: 25px;
            background-color: rgb(227, 227, 227);
            border-radius: 4px;
            margin-left: 30px;

            & .NonProdTimeWrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: absolute;
                top: 2px;

                & .EachBar {
                    background-color: white;
                    border-radius: 4px;
                    height: 21px;
                }

                & .TimeText {
                    margin-top: 5px;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }
    }
}

.SubHeader {
    font-size: 18px;
    color: 'black';
    font-weight: 600;
}

.DateText {
    font-size: 18px;
    font-weight: 500;
    text-align: right;
}

.LeftRightWrapper {
    display: flex;
    position: relative;

    & .DateTopRight {
        position: absolute;
        right: 0;
    }

    & .LeftSection {
        flex: 1;
    }

    & .RightSection {
        width: 350px;

        & .TagWrapper {
            border: 1px solid rgb(227, 227, 227);
            border-radius: 4px;
            padding: 10px;
            margin-bottom: 5px;

            & .TopSection {
                display: flex;
                justify-content: flex-start;

                & .TagTitle {
                    font-size: 14px;
                    font-weight: 600;
                    margin-right: 5px;
                }

                & .TagName {
                    font-size: 11px;
                    font-weight: 500;
                    background-color: #eee;
                    border-radius: 4px;
                    padding: 4px;
                }
            }

            & .DeviceList {
                font-size: 14px;
                color: $auth-title-color;
            }
        }
    }
}

.TableSection {
    padding: 20px;

    & table,
    th,
    td {
        border: 1px solid;
    }

    & table {
        margin-top: 5px;
        border-collapse: collapse;
        width: 100%;

        & thead {
            background-color: rgb(227, 227, 227);

            & th {
                text-align: left;
                padding: 5px 18px;
                font-size: 14px;
            }
        }

        & tr {
            height: 25px;
        }
    }
}