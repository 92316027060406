@import "../../../../assets/styles/color";

.DropdownWrapper {
  position: relative;
  display: flex;
  & .Selected {
    font-size: 14px;
    font-weight: 600;
    color: $modal-text-color;
    background-color: $main-text-color;
    min-width: 170px;
    align-self: center;
    border-radius: 4px;
    padding: 7px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .FilterIcon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
}

.DropdownMenu {
  position: absolute;
  z-index: 1000;
  top: 50px;
  right: 0;
  width: 220px;
  box-sizing: border-box;
  border: 1px solid $border-color;
  border-radius: 4px;
  background-color: $main-text-color;

  & .List {
    color: $modal-text-color;
    border-bottom: 1px solid $border-color;
    display: block;
    cursor: pointer;
    & .RadioWrapper {
      padding: 8px;
      & .RadioLabel {
        margin-left: 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        color: $modal-text-color;
        cursor: pointer;
        user-select: none;
      }
      & .RadioIcon {
        position: absolute;
        margin-top: 3px;
      }
      & input[type="radio"] {
        cursor: pointer;
        opacity: 0;
      }
    }
  }
}
