@import "../../../../assets/styles/color";

.DeviceCardList {
  & .DeviceCard {
    width: 100%;
    border-bottom: 1px solid $main-container-bg-color;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    background-color: $card-bg-color;
    margin-bottom: 20px;
    &.DeviceSearchCard {
      margin-bottom: 0;
      border-left: 0;
    }
  }
  & .DeviceAndSocketWrapper {
    border-radius: 4px;
    border-left: 4px solid $form-label-color;
    &.Active {
      border-left: 4px solid $green-color;
    }
    & .DeviceWrapper {
      display: flex;
      align-items: center;
      padding: 15px 20px;
      & .NameAndInformation {
        flex: 1;
        & .DeviceName {
          font-size: 18px;
          font-weight: 500;
          color: $card-text-color;
          line-height: 1.57;
        }
        & .OtherInformation {
          font-size: 14px;
          color: $form-label-color;
          line-height: 1.57;
        }
      }
      & .ActionButtons {
        & .ArrowIcon {
          transform: rotate(180deg);
          transition: transform 0.25s ease-in;
          vertical-align: super;
          &.Open {
            transform: rotate(0deg);
          }
        }
      }
    }
    & .CollapseContainer {
      background-color: $navigation-bg-color;
      font-size: 14px;
      font-weight: 500;
      color: $form-label-color;
      & .DeviceSocketRow {
        display: flex;
        align-items: center;
        padding: 12px 20px 12px 50px;
        border-bottom: 1px solid $border-color;
        & .DevicePictureWrapper {
          position: relative;
          height: 38px;
          & .DevicePicture {
            width: 38px;
            height: 38px;
            border-radius: 4px;
            cursor: pointer;
            &.Loading {
              opacity: 0.35;
            }
          }
          & .NotAdminDevicePicture {
            width: 38px;
            height: 38px;
            border-radius: 4px;
          }
          & .DeviceIconLoadingWrapper {
            position: absolute;
            width: 38px;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
          }
          & .ControlIcon {
            position: absolute;
            top: 25px;
            left: 25px;
            width: 20px;
            height: 20px;
          }
          & .ChangeDeviceIconTooltip {
            font-size: 10px;
            background-color: $tooltip-bg-color;
            color: $main-text-color;
            border-radius: 4px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            padding-left: 7px;
            width: 165px;
            top: 75%;
            left: 125%;
            transform: translateY(-50%) scale(0.1, 0.1);
            visibility: hidden;
            opacity: 1;
            transition: all 0.1s ease-in-out;
            &::after {
              content: "";
              position: absolute;
              top: 40%;
              left: -6px;
              border-style: solid;
              border-color: transparent #424242 transparent transparent;
            }
            &.Open {
              visibility: visible;
              opacity: 1;
              transform: translateY(-50%) scale(1, 1);
            }
          }
        }
        & .DeviceSocketInfo {
          margin-left: 20px;
          line-height: 37px;
          flex: 1;
        }
      }
    }
  }

  & .MiddleDot {
    margin: 0 10px;
  }

  & .TagsWrapper {
    display: flex;
    margin-right: 30px;
    padding-right: 30px;
    font-weight: 600;
    border-right: 1px solid $navigation-bg-color;
    &.BorderForSockets {
      border-color: $border-color;
    }
    & .MoreTags {
      font-size: 14px;
      font-weight: 600;
      text-decoration: underline;
      margin-left: 12px;
      &.Off {
        color: $auth-title-color;
      }
    }
    & .TagBox {
      background-color: $main-text-color;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 600;
      color: $auth-title-color;
      line-height: 1.5;
      margin-left: 12px;
      padding: 2px 12px;
      &.Off {
        background-color: $auth-title-color;
        color: $main-text-color;
        opacity: 0.5;
      }
    }
  }

}

.AddDeviceWrapper {
  width: 500px;
  height: 300px;
  padding: 30px;
  display: flex;
  flex-direction: column;

  & .AddDeviceHeader {
    font-size: 18px;
    font-weight: 500;
    color: $card-text-color;
    line-height: 1.57;
    margin-bottom: 20px;
  }

  & .AddDeviceBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & .Error {
      color: $danger-color;
      font-size: 12px;
    }

    & .FormGroup {
      display: flex;
      flex-direction: column;
      
      & .FormLabel {
        font-size: 14px;
        font-weight: 500;
        color: $form-label-color;
        line-height: 1.57;
        margin-bottom: 10px;
      }
    }
  }

  & .AddDeviceFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 10px;
  }
}