@import '../../../../assets/styles/color';

.ChartWrapper {
    position: relative;
    background-color: #222226;
    padding: 20px;
    border-radius: 6px;

    & .Header {
        font-size: 18px;
        color: $main-text-color;
        font-weight: 600;

        & span {
            font-weight: 200;
            font-size: 16px;
            color: $main-text-color;
        }
    }
}

.ValueConclusion {
    display: flex;
    align-items: baseline;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    & .Total {
        color: $main-text-color;
    }
    & .Production {
        margin-left: 20px;
        color: #3063D1;
    }
    & .NonProduction {
        margin-left: 20px;
        color: #A0BAC7;
    }
}

.BarStyles {
    display: flex;
    & .Option {
        color: $sub-text-color;
        font-size: 14px;
        user-select: none;
        cursor: pointer;
        &.Active {
            color: $main-text-color;
        }
    }
}

.EfficiencyKpiValue {
    margin-top: 10px;
    font-weight: 500;
    color: $sub-text-color;
    & span {
        margin-left: 10px;
        color: $main-text-color
    }
}

.LoadingText {
    color: $sub-text-color;
    font-size: 12px;
    text-align: center;
    margin: 50px 0;
}

.ParentTitle {
    font-size: 48px;
    font-weight: 600;
    margin-right: 30px;
}

.ChildTitle {
    font-size: 32px;
    font-weight: 600;
    margin-right: 30px;
}