@import '../../../../assets/styles/color';

.PoInputWrapper {
  position: relative;
  & .ScannerIconInPO {
    position: absolute;
    right: 10px;
    top: 39px;
    cursor: pointer;
  }
  & .PoVerified {
    position: absolute;
    right: 42px;
    top: 41px;
    width: 20px;
  }
  & .AutoCheck {
    position: absolute;
    right: 38px;
    top: 35px;
  }
}

.CustomDropdownContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  left: 0;
  top: 0;
}
.CustomDropdown {
  position: relative;
  display: inline-block;
  transition: transform .3s ease-in-out;
  z-index: 1000;
  & ul {
    visibility: hidden;
    opacity: 0;
    transition: visibility .3s ease-out, opacity .2s ease-out;
    list-style-type: none;
    padding: 0;
    // right: 0px;
    top: -85px;
    width: 350px;
    background-color: $card-bg-color;
    font-weight: bold;
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    border-radius: 4px;
    max-height: 190px;
    overflow: auto;
  }
  & li {
    cursor: pointer;
    max-height: 42px;
    border-bottom: 1px solid $main-container-bg-color;
    color: $card-text-color;
    text-decoration: none;
    border-left: 3px solid $card-bg-color;
    border-radius: 4px;
  }
  & li:hover{
    background-color: $primary-color;
    // border-left: 3px solid $primary-color;
    // border-radius: 4px;
  }
}

.CustomDropdownIn {
  transform: translateY(45px);
  & ul{
    visibility: visible;
    opacity: 1;
    }
}

.CustomDropdownOut {
  transform: translateY(30px);
  & ul{
    visibility: hidden;
    opacity: 0;
  }
}

.CustomListItem { 
  margin: 10px;
}