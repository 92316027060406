@import "../../../../assets/styles/color";

.TimelineRow {
  & .TimelineBar {
    width: 100%;
    overflow: visible;
    position: relative;

    & .TimelineLoading {
      height: 25px;
      border: solid 1px $navigation-bg-color;
      border-radius: 5px;
      padding-top: 5px;
    }

    & .TimelineWrapper {
      position: relative;
      margin-top: 10px;
      margin-bottom: 10px;
      width: calc(100% - 20px);
      display: flex;
      align-items: center;
      border: 1px solid $border-color;
      border-radius: 5px;

      & .Bar {
        height: 30px;
        // border-top: solid 1px $navigation-bg-color;
        // border-bottom: solid 1px $navigation-bg-color;
      }

      & .BarFirst {
        height: 30px;
        // border-top: solid 1px $navigation-bg-color;
        // border-bottom: solid 1px $navigation-bg-color;
        // border-left: solid 1px $navigation-bg-color;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      & .BarLast {
        height: 30px;
        // border-top: solid 1px $navigation-bg-color;
        // border-bottom: solid 1px $navigation-bg-color;
        // border-right: solid 1px $navigation-bg-color;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      & .BarClickAble {
        cursor: pointer;

        &:hover {
          // border: solid 1px $navigation-bg-color;
          // transform: scale(1.25);
          height: 40px;
        }
      }

      & .BarEmpty {
        background-color: $navigation-bg-color;
      }

      & .BarGreen {
        background-color: greenyellow;
      }

      & .BarOrange {
        background-color: orange;
      }

      & .BarFlashing {
        animation: 2s infinite flashing;
        -webkit-animation: flashing 2s infinite;
      }

      & .BarBlue {
        background-color: blue;
      }

      & .BarRed {
        background-color: red;
      }
    }

    & .TimelineLabel {
      display: flex;
      font-size: 10px;
      font-weight: bold;
      text-align: left;
      margin-left: -15px;
      --wrapperwidth: calc(100% - 20px);
      --labelwidth: calc(var(--wrapperwidth) / 12);
      width: calc(var(--wrapperwidth) + var(--labelwidth));

      & div {
        width: var(--labelwidth);
      }
    }
  }

  & .reason_cell {
    margin-top: -10px;
    border: solid 1px gray;
    border-radius: 5px;
    padding: 6px 0;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
  }

  & .apq_cell {
    border-radius: 4px;
    font-weight: bold;
    padding: 10px 0;
    margin: -10px 5px 0 5px;

    &.normal {
      background-color: $green-color;
    }

    &.warning {
      background-color: $orange-color;
    }

    &.danger {
      background-color: $danger-color;
    }

    &.unavailable {
      background-color: rgb(158, 160, 165);
    }
  }

  & .oee_cell {
    border-radius: 20px;
    font-weight: bold;
    padding: 10px 0;
    margin: -10px 5px 0 5px;

    &.normal {
      border: solid 1px $green-color;
      color: $green-color;
    }

    &.warning {
      border: solid 1px $orange-color;
      color: $orange-color;
    }

    &.danger {
      border: solid 1px $danger-color;
      color: $danger-color;
    }

    &.unavailable {
      border: solid 1px rgb(158, 160, 165);
      color: rgb(158, 160, 165);
    }
  }
}

@keyframes flashing {
  0% {
    opacity: 100;
  }

  25% {
    opacity: 50;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 50;
  }

  100% {
    opacity: 100;
  }
}

@-webkit-keyframes flashing {
  0% {
    opacity: 100;
  }

  25% {
    opacity: 50;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 50;
  }

  100% {
    opacity: 100;
  }
}