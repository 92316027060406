@import '../../../../assets/styles/color';

.SideDrawer {
  position: fixed;
  width: 380px;
  max-width: 70%;
  height: 100%;
  right: 0;
  top: 0;
  transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
  background-color: $navigation-bg-color;
  box-shadow: -10px 0 20px 0 rgba(0, 0, 0, 0.5);
  padding: 50px 0 15px 0;
  box-sizing: border-box;
}

.DrawerHeader {
  position: relative;
  padding-bottom: 15px;
  margin: 0 15px;
  border-bottom: 1px solid $border-color;
}

.DrawerTitle {
  font-size: 20px;
  font-weight: bold;
}

.DrawerBody {
  padding: 0 15px;
  margin: 35px 0 20px 0;
  overflow: overlay;
  height: calc(100vh - 150px);
}

.Open {
  transform: translateX(0); 
}

.Close {
  transform: translateX(100%); 
}

.CloseBtn {
  cursor: pointer;
  font-size: 22px;
  position: absolute;
  right: 0;
}

.DrawerBody::-webkit-scrollbar {
  width: 7px;
}

.DrawerBody::-webkit-scrollbar-thumb {
	border-radius: 7px;
  background-color: transparent;
}

.DrawerBody {
  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(158, 160, 165, 0.5);
	  border-radius: 7px;
  }
}