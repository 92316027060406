@import '../../../../../assets/styles/color';

.PoBox {
  display: flex;
  border-radius: 4px;
  margin-bottom: 15px;
  cursor: pointer;
  height: 80px;
  position: relative;
  & .POIndicator {
    position: absolute;
    bottom: 0;
    width: 0; 
    height: 0; 
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 20px solid #fff;
  }
  & .RunningPercentBar {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  & .PoNameSection {
    flex: 1;
    line-height: 22px;
    & .PoName {
      color: $main-text-color;
      font-size: 18px;
      font-weight: 600;
      margin-left: 30px;
    }
    & .Description {
      color: $card-text-color;
      font-size: 14px;
      font-weight: 400;
      margin-left: 30px;
    }
  }
  & .PercentSection {
    & .Percentage {
      text-align: center;
      color: $main-text-color;
      font-size: 20px;
      font-weight: 600;
    }
    & .Elapsed {
      color: $card-text-color;
      font-size: 14px;
      font-weight: 400;
    }
  }
  & .TextOnProgress {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.ArrowIcon {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid #c5c5c5;
  transform: rotate(180deg);
  transition: transform 0.25s ease-in;
  margin-left: 25px;
  margin-right: 30px;
  &.ToggleDown {
    transform: rotate(0deg);
  }
}

.MachineDetailsBox {
  border-radius: 4px;
  background-color: $card-bg-color;
  & .MachineStatus {
    text-align: center;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
  }
  & .MachineName {
    font-size: 14px;
    font-weight: 600;
    color: $card-text-color;
  }
  & .ProgressSection {
    margin: 15px 0 10px 0;
    position: relative;
    & .Upper {
      display: flex;
      & .Percent {
        font-size: 36px;
        font-weight: 600;
        position: absolute;
        right: -10px;
        top: -45px;
      }
      & .ActualTarget {
        font-size: 14px;
        margin-left: 10px;
        color: $card-text-color;
      }
    }
    & .Lower {
      display: flex;
      margin-top: 10px;
      align-items: center;
      & .Parts {
        margin-left: 10px;
        font-size: 14px;
      }
    }
  }
  & .MachineElapsed {
    font-size: 14px;
    margin-left: 10px;
    color: $card-text-color;
  }
}

.EachMachineDetailsWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 330px));
  grid-gap: 30px;
}

@media screen and (max-width: 1600px) {
  .EachMachineDetailsWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .EachMachineDetailsWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

.AheadIcon {
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 15px solid $green-color;
}
.BehindIcon {
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 15px solid $danger-color;
}

.PartsExceedsIcon {
  margin: 0 5px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: $sub-text-color;
}