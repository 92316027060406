@import "../../../../assets/styles/color";

.FilterMenu {
  position: fixed;
  z-index: 1000;
  right: 30px;
  width: 160px;
  margin-top: 30px;
  border: 1px solid $border-color;
  border-radius: 4px;
  background-color: $navigation-bg-color;

  & .List {
    color: $sub-text-color;
    border-bottom: 1px solid $border-color;
    display: block;
    cursor: pointer;
    & .RadioWrapper {
      margin: 8px;
      & .RadioLabel {
        margin-left: 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        color: $sub-text-color;
        cursor: pointer;
      }
      & .RadioIcon {
        position: absolute;
        margin-top: 3px;
      }
      & input[type="radio"] {
        cursor: pointer;
        opacity: 0;
      }
    }
  }
}
