@import '../../../../assets/styles/color';

.FormulaModal {
  color: $modal-text-color;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;

  & .Title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.21;
    margin-bottom: 34px;
  }

  & .Description {
    font-size: 16px;
    line-height: 1.6;
    opacity: 0.7;
  }

  & .YesButtonWrapper {
    width: 145px;
    margin-top: 34px;
    display: inline-block;
  }

  & .CancelButton {
    font-size: 14px;
    color: $form-label-color;
    cursor: pointer;
  }
}

.MenuWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 0;
  border-bottom: 1px solid $card-text-color;
}

.List {
  color: $modal-text-color;
  // border-bottom: 1px solid $border-color;
  display: block;
  cursor: pointer;

  & .RadioWrapper {
    padding: 8px;

    & .RadioLabel {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      color: $modal-text-color;
      cursor: pointer;
      user-select: none;
    }

    & .RadioIcon {
      position: absolute;
      margin-top: 3px;
    }

    & input[type="radio"] {
      cursor: pointer;
      opacity: 0;
    }
  }
}

.SelectTitle {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: $form-label-color;
  text-transform: uppercase;
  margin-bottom: 0.7rem;
}

.BodyContent {
  flex: 1;
  padding: 10px 20px;
  text-align: left;
}

.FooterContent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 80px;
}

.SelectTimeBox {
  flex: 1;
  width: 100%;
  padding: 7px 10px;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid $auth-input-border-color;
  box-sizing: border-box;
  outline: none;
  resize: none;

  &::-webkit-calendar-picker-indicator {
    filter: invert(0.2);
  }

  &:hover:enabled,
  &:focus:enabled {
    border-color: $primary-color;
  }
}

.CustomStartEndButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid;
  color: $primary-color;
  width: 80px;
  border-radius: 4px;
}

.customStartEndValue {
  font-size: 10px;
  color: $sub-text-color;
}

.GoBackWrapper {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  color: $sub-text-color;
  user-select: none;
  cursor: pointer;
  margin-bottom: 10px;
}