@import '../../../../assets/styles/color';

.ErrorBoundary {
  display: flex;
  height: 100vh;
  & .LeftSection {
    flex: 1;
    background-color: $light-bg-color;
    color: $navigation-bg-color;
    padding: 10% 7%;
    text-align: left;
    display: flex;
    flex-direction: column;
    & .Header {
      font-size: 50px;
      margin-bottom: 60px;
      font-weight: 600;
    }

    & .Description {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.75;
      & .Email {
        color: $auth-button-color;
        font-weight: 600;
      }
    }
  }

  & .RightSection {
    position: relative;
    width: 700px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  & .SentMessage {
    margin-top: 20px;
    color: $green-color;
    font-size: 18px;
    font-weight: 500;
  }
}