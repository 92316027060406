.Wrap {
    min-width: 64px;
}

.Box {
    cursor: move;
    width: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

