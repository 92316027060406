@import '../../../assets/styles/color';

.Hook {
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  min-height: calc(100vh - 71px);

  & .HookTopics {
    flex: 0 1 400px;
    background-color: $navigation-bg-color;
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;

    & .HookFix {
        position: fixed;
        width: 400px;
        overflow: overlay;
        height: calc(100% - 70px);

        & .TopMenuHook {
            display: flex;
            border-bottom: 1px solid $border-color;

            & .MenuWrapper {
                flex: 1;
                display: flex;
                margin-left: 30px; 
                & .Menu {
                  display: flex;
                  align-items: center;
                  border-bottom: 3px solid $primary-color;
                  & .MenuTitle {
                    font-size: 14px;
                    font-weight: 600;
                  }
                }
            }
            & .AddButtonWrapper {
                margin: 14px 30px;
                width: 170px;
            }
        }

        & .HookListWrapper {
          padding: 20px 30px;
          border-bottom: 1px solid $border-color;
          display: flex;
          align-items: center;
          cursor: pointer;
          
          & .SwitchWrapper {
            margin-right: 30px;
          }

          & .Name {
            font-size: 16px;
            font-weight: 600;
            color: $main-text-color;
            margin-bottom: 6px;
          }

          & .Description {
            font-size: 14px;
            color: $sub-text-color;
          }
        }

        & .HookListSelected {
          background-color: $main-container-bg-color;
        }
    }
  }
  & .HookDetails {
    flex: 1;
    padding: 30px;

    & .HookDetailWrapper {
      & .FormWrapper {
        position: relative;
        background-color: $navigation-bg-color;
        border: 1px solid $border-color;
        padding: 25px 30px;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
        & .Divider {
          width: 100%;
          height: 1.5px;
          background-color: $border-color;
          margin: 30px 0;
          border: 0;
        }
        & .CloseIcon {
          cursor: pointer;
          position: absolute;
          right: 20px;
        }
        & .Title {
          color: $card-text-color;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.56;
        }
        & .Description {
          color: $sub-text-color;
          font-size: 14px;
          line-height: 1.56;
        }
        & .Flex {
          display: flex;
          & .LeftTextField {
            flex: 1;
            margin-right: 15px;
            & label.ThresholdTitle {
              display: inline-block;
              font-size: 12px;
              font-weight: bold;
              color: $form-label-color;
              text-transform: uppercase;
              margin-bottom: 0.5rem;
            }
          }
          & .RightTextField {
            flex: 1;
          }
          & .AddButton {
            margin-left: 10px;
            & .IconButton {
              margin-left: 7px;
              padding: 9px 0;
              cursor: pointer;
            }
          }

          & .Label {
            display: inline-block;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            color: $form-label-color;
            margin-bottom: 0.5rem;
          }
        }
      }
    
      & .ButtonsWrapper {
        padding: 45px 30px;
        display: flex;
        justify-content: space-evenly;
      }
    }
    
    & .LabelGroup{
      display: flex;
      margin-bottom: 0.5rem;
      & .Label {
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: $form-label-color;
      }
      & .Info{
        margin: auto 10px;
      }
    }
    
    & .ErrorMessage {
      color: $danger-color;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 10px;  
      margin-right: 7px;
      text-align: right;
    }

  }
}

.LoadingCard{
    --card-padding: 30px;
    --card-height: 82px;
    --card-skeleton: linear-gradient(#222226 var(--card-height), transparent 0);
  
    --desc-line-height: 12px;
    --desc-line-skeleton: linear-gradient(rgba(107, 109, 112, 0.15) var(--desc-line-height), transparent 0);
    --desc-line-1-width:100px;
    --desc-line-1-position: var(--card-padding) 27px;
    --desc-line-2-width:160px;
    --desc-line-2-position: var(--card-padding) 47px;
  
    --blur-width: 200px;
    --blur-size: var(--blur-width) calc(var(--card-height) - 40px);
  
    width: 100%; //demo
    height: var(--card-height);
    border-bottom: 1px solid $border-color;
    border-radius: 5px;
  
    &:empty::after {
      content:"";
      display:block;
      width: 100%;
      height: 100%;
      border-radius:6px;
      // box-shadow: 0 10px 45px rgba(0,0,0, .1);
  
      background-image:
        linear-gradient(
          90deg, 
          rgba(#222226, 0) 0, 
          rgba(#222226, .8) 50%, 
          rgba(#222226, 0) 100%
        ),                          //animation blur
        var(--desc-line-skeleton),  //desc1
        var(--desc-line-skeleton),  //desc2
        var(--card-skeleton)        //card
      ;
  
      background-size:
        var(--blur-size),
        var(--desc-line-1-width) var(--desc-line-height),
        var(--desc-line-2-width) var(--desc-line-height),
        100% 100%
      ;
      
      background-position:
        -100% 50%,                      //animation
        var(--desc-line-1-position),  //desc1
        var(--desc-line-2-position),  //desc2
        0 0                           //card
      ;
  
      background-repeat: no-repeat;
      animation: loading 1.5s infinite;
    }
  }
  
  @keyframes loading {
    to {
      background-position:
        100% 50%,       
        var(--desc-line-1-position),
        var(--desc-line-2-position),
        0 0
      ;
    }
  }