@import "../../../assets/styles/color";

.TopSection {
  display: flex;
  align-items: center;
  padding-bottom: 26px;
  border-bottom: 1px solid $border-color;
  margin: 0 30px;
  justify-content: end;

  & .CustomGrid {
    margin-right: 30px;
  }

  & .ScreenButton {
    display: flex;
    cursor: pointer;

    & .ScreenWrapper {
      display: flex;
      align-items: center;
      margin-left: 20px;

      & .ScreenTitle {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
        color: $sub-text-color;
      }
    }
  }
}

.ContentSection {
  & .TimelineTableWrapper {
    margin: 30px 0;
    // height: calc(100vh - 570px);
    overflow-y: scroll;

    & table {
      width: 100%;
      text-align: center;

      & th {
        font-size: 12px;
        opacity: 0.875;
      }

      & tbody tr {
        height: 80px;
      }

      & .machine_col {
        width: 150px;
      }

      & .reason_col {
        width: 100px;
      }

      & .apq_col {
        width: 70px;
      }

      & .oee_col {
        width: 80px;
      }
    }
  }

  & .BottomDrawer {
    background-color: $main-container-bg-color;

    & .BottomDrawerTopbar {
      display: flex;
      align-items: center;
      background-color: $navigation-bg-color;
      color: $sub-text-color;
      font-weight: bold;
      padding: 12px 30px;

      & .BottomDrawerTopbarTitle {
        flex: 1 1 0%;
      }

      & .BottomDrawerTopbarMenu {
        cursor: pointer;
        margin-right: 35px;
      }
    }
  
    & .BottomDrawerContent {
      padding: 20px;
      max-height: 200px;
      overflow-y: scroll;

      & table {
        width: 100%;
        text-align: center;
        
        & th {
          height: 30px;
          vertical-align: top;
          text-transform: uppercase;
          border-bottom: solid 2px $navigation-bg-color;
          color: $widget-text-color;
          font-size: 14px;
        }

        & tr.SelectedRowFromTimeline {
          background-color: $navigation-bg-color;
          & td {
            color: $main-text-color;
            &.ReasonCol {
              opacity: 1;
            }
            & .DotStatusColor {
              opacity: 1;
            }
          }
        }

        & td {
          height: 25px;
          color: #9ea0a5;
          font-size: 14px;
          font-weight: bold;
          &.ReasonCol {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.75;
          }
          & .DotStatusColor {
            margin-right: 7px;
            width: 5px;
            height: 5px;
            background-color: #9ea0a5;
            border-radius: 5px;
            opacity: 0.75;
          }
        }
      }
    }
  }
}

.MinimizeScreen {
  display: block;
  padding-top: 55px;
}

.FullScreen {
  position: absolute;
  padding-top: 55px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 100vh;
  background-color: $main-container-bg-color;
}

.StatusRulesLegend {
  margin-top: 10px;
  margin-left: 30px;

  & .LegendTitle {
    display: inline-block;
    font-size: 12px;
    margin-right: 20px;
    font-weight: 500;
  }

  & .LegendBox {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 15px;
    font-weight: 600;
    font-size: 11px;
    text-shadow: 1px 0 0 #4b4b52, -1px 0 0 #4b4b52, 0 1px 0 #4b4b52, 0 -1px 0 #4b4b52, 1px 1px #4b4b52, -1px -1px 0 #4b4b52, 1px -1px 0 #4b4b52, -1px 1px 0 #4b4b52;
  }
}

.ShadowText {
  text-shadow: 1px 0 0 #4b4b52, -1px 0 0 #4b4b52, 0 1px 0 #4b4b52, 0 -1px 0 #4b4b52, 1px 1px #4b4b52, -1px -1px 0 #4b4b52, 1px -1px 0 #4b4b52, -1px 1px 0 #4b4b52;
}

.ShadowBox {
  box-shadow: 1px 0 0 #4b4b52, -1px 0 0 #4b4b52, 0 1px 0 #4b4b52, 0 -1px 0 #4b4b52, 1px 1px #4b4b52, -1px -1px 0 #4b4b52, 1px -1px 0 #4b4b52, -1px 1px 0 #4b4b52;
}

.TimelineNotFoundWrapper {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  & .TimelineNotFoundInfo {
    font-size: 20px;
    font-weight: 500;
    color: $card-text-color;
    margin: 45px 0;
  }

  & .ButtonWrapper {
    width: 200px;
    margin: 0 auto;
  }
}

.SortableListWrapper {
  list-style-type: none;
  padding: 0;
  margin: 0 30px;
}

li.SortableItem {
  display: flex;
  list-style-type: none;
  text-decoration: none;
  border: 1px solid gray;
  cursor: row-resize;
  padding: 10px 30px;
  border-radius: 4px;
}

.TickIcon {
  cursor: pointer !important;
  margin-right: 8px;
  width: 18px;
}

input.SelectDate[type="Date"]::-webkit-calendar-picker-indicator {
  filter: invert(0.75);
}
input.SelectDate {
  margin-right: 10px;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  font-family: inherit;
  padding: 10px 16px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 2px $border-color;
  background-color: $main-container-bg-color;
  color: $main-text-color;
  transition: border-color .15s ease-in-out;
  box-sizing: border-box;
  outline: none;
  resize: none;
  &:hover, &:focus {
    border-color: $primary-color;
  }
}