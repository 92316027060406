@import '../../../../assets/styles/color';

.Modal {
  position: fixed;
  z-index: 1001;
  background-color: #f5f5f5;
  width: 490px;
  min-height: 520px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.2);

  &.DarkMode {
    background-color: $navigation-bg-color;
  }
}

.CloseBtn {
  position: absolute;
  cursor: pointer;
  color: $modal-text-color;
  right: 10px;
  top: 0px;
  font-size: 32px;
  z-index: 9;
  &.DarkMode {
    color: $sub-text-color;
  }
}

.WhiteCloseButton {
  color: $widget-text-color;
}

@media (min-width: 600px) {
  // .Modal {
  //   width: 550px;
  //   left: calc(50% - 250px);
  //   top: 12%;
  // }
  // .CloseBtnModal {
  //   font-size: 35px
  // }
}