@import '../../../assets/styles/color';

.Chat{
  display: flex;
  & .LeftSection{
    flex: 0 1 380px;
  }
  & .RightSection{
    flex: 1;
  }
}