@import "../../../../assets/styles/color";


.WidgetContainer {
  background-color: $navigation-bg-color;
  border-radius: 4px;
  // height: 100%;
  & .WidgetHeader {
    // background-color: red;
    // height: 75px;
    // padding: 0 50px 0 30px;
    display: flex;
    // align-items: center;
    // justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid $border-color;
    padding: 8px 16px;
    // @media screen and (max-width: 1440px) {
    //   // padding: 0 40px 0 20px;
    //   padding: 8px 16px;
    // }

    & .TitleWrapper {
      // display: flex;
      // width: 100%;
      text-align: center;
      & .Title {
        // flex: 1;
        // margin: auto;
        font-size: 18px;
        font-weight: 500;
        color: $card-text-color;
        @media screen and (max-width: 1440px) {
          font-size: 14px;
        }
      }

      & .TitleNoFlex {
        margin: auto 0;
        font-size: 18px;
        font-weight: 500;
        color: $card-text-color;
      }

      & .SelectWrapper {
        margin: auto 30px;
        flex: 2;
        & .Flex{
          display: flex;
          justify-content: flex-end;
        }
      }

      & .ErrorMessage {
        color: $danger-color;
        font-size: 12px;
        font-weight: 600;
        margin: auto 0 auto 0;
      }
    }

    & .SubTitle {
      margin: auto 10px;
      font-size: 10px;
      font-weight: 500;
      color: $sub-text-color;
      text-transform: uppercase;
    }

    // & .CloseBtn {
    //   position: absolute;
    //   font-size: 30px;
    //   color: $sub-text-color;
    //   right: 16px;
    //   top: 20px;
    //   cursor: pointer;
    // }

    & .EditBtn {
      position: absolute;
      color: $sub-text-color;
      right: 30px;
      top: 29px;
      cursor: pointer;
      @media screen and (max-width: 1440px) {
        right: 20px;
      }

      & .EditIcon {
        @media screen and (max-width: 1440px) {
          width: 18px;
        }
      }
    }
  }

  & .WidgetBody {
    position: relative;
    text-align: center;
    // height: calc(100% - 76px);
    height: 100%
  }

}

.NoData{
  position: absolute;
  right: 17px;
  top: 27px;
}

.NoData:before {
  content: '!';
  text-align: center;
  height: 20px;
  width: 20px;
  display: block;
  border: 1px solid $yellow-color;
  border-radius: 50%;
  color: $yellow-color;
  font-size: 15px;
}