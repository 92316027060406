@import '../../../../assets/styles/color';

.ChangeNewManager {
  display: none;
  position: fixed;
  right: 400px;
  width: 360px;
  min-height: 330px;
  box-sizing: border-box;
  background-color: $navigation-bg-color;
  color: $sub-text-color;
  border-radius: 4px;
  padding: 30px;
  margin-top: 25px;
  &::after {
    content: "";
    position: absolute;
    top: 60px;
    left: 100%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #222226
  }
  &.Open {
    display: block;
  }

  & .Title {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
  }

  & .MembersWrapper {
    margin-top: 12px;
    & .ProfilePictureWrapper {
      display: inline-block;
      position: relative;
      & .Checked {
        position: absolute;
        bottom: 9px;
        right: 10px;
      }
      
      & .MemberProfilePicture {
        width: 38px;
        margin-bottom: 7px;
        margin-right: 12px;
        border-radius: 50%;
        cursor: pointer;
      }
      & .MoreMembers {
        width: 38px;
        height: 38px;
        border: 2px solid $form-label-color;
        border-radius: 50%;
        line-height: 37px;
        font-size: 13px;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
      }
    }
  }

   & .ChangeInfo {
     font-size: 12px;
     line-height: 1.5;
     margin-top: 10px;
     margin-bottom: 30px;
   }
}