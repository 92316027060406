@import '../../../../assets/styles/color';

.SchedulerType {
  background-color: $card-bg-color;
  border-radius: 4px;

  & .SchedulerTypeCard {
    // & tr:not(:first-child)
    border-bottom: 4px solid $main-container-bg-color;
    padding: 20px 30px;
    display: flex;

    & .SchedulerTypeInfos {
      flex: 1;
      width: 60%;
      display: flex;
      flex-direction: column;

      & .SchedulerTypeName {
        font-size: 16px;
        font-weight: 600;
        color: $card-text-color;
        margin-bottom: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 90%;
        display: flex;
        align-items: center;

        & .SchedulerColor {
          width: 20px;
          height: 20px;
          border-radius: 4px;
          margin-right: 5px;
        }

        & .SchedulerTypePlans {
          font-size: 14px;
          font-weight: 500;
          display: inline-block;
        }
      }

      & .SchedulerTypeCate {
        font-size: 12px;
        color: $sub-text-color;
        line-height: 1.57;
      }
    }
  }
}

.ActionButtons {
  display: flex;
  align-items: center;
  cursor: pointer;

  & .ArrowIcon {
    margin-left: 30px;
    transform: rotate(180deg);
    transition: transform 0.25s ease-in;
    vertical-align: super;

    &.ToggleDown {
      transform: rotate(0deg);
    }
  }
}

label.Title {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: $form-label-color;
  margin-bottom: .7rem;
}

.FormGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  & .SelectBox {
    flex: 1;
    width: 100%;
    padding: 10px 16px;
    background-color: $main-container-bg-color;
    color: $widget-text-color;
    // height: 38px;
    border-radius: 4px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid $border-color;
    box-sizing: border-box;
    outline: none;
    resize: none;

    &::-webkit-calendar-picker-indicator {
      filter: invert(0.75);
    }

    &::-webkit-calendar-picker-indicator:hover {
      filter: invert(0);
    }
  }
}

.ColorBoxWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, 20px));
  grid-gap: 5px;

  & .ColorBox {
    width: 20px;
    height: 20px;
    border-radius: 4px;

    // margin: 5px 5px;
    &:hover {
      opacity: 0.75;
      cursor: pointer;
    }
  }
}