@import '../../../../assets/styles/color';

.FormGroup {
  margin-bottom: 38px;
  position: relative;
  & label.Title {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $form-label-color;
    margin-bottom: .7rem;
    &.LabelIn {
      position: absolute;
      text-transform: initial;
      top: 15px;
      left: 16px;
    }
  }

  & .SelectBox {
    width: 100%;
    background-color: $main-container-bg-color;
    color: $widget-text-color;
    height: 45px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid $border-color;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
    resize: none;
    text-indent: 10px;
    &.TextRight {
      text-align-last: right;
    }
    &.WhiteMode {
      border: 1px solid $auth-input-border-color;
      background-color: $main-text-color;
      color: $auth-title-color;
    }
    &.Disabled {
      opacity: 0.5;
    }
  }
  & .LoadingWrapper {
    display: inline-block;
    position: absolute;
    right: 20px;
    bottom: 3px;
  }
}