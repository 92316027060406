@import "../../../../../assets/styles/color";

.Timeline {
  padding: 0 50px;

  & .HeaderWrapper {
    font-size: 14px;
    padding-top: 60px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    & .Header {
      display: flex;
      color: $sub-text-color;
      font-size: 12px;
    }
    & .Block {
      width: 30px;
      height: 12px;
      margin: auto 10px;
    }
  }

  & .Loading {
    padding-top: 130px;
  }

  & .TimelineWrapper {
    height: 150px;
    position: relative;

    & .TimelineBg {
      width: 100%;
      height: 50px;
      background-color: $main-container-bg-color;
      & .TimelineValueWrapper {
        display: flex;
        animation-duration: 0.6s;
        animation-name: changewidth;
        animation-direction: alternate;
        -webkit-animation-name: changewidth; /* Safari 4.0 - 8.0 */
        -webkit-animation-duration: 0.6s; /* Safari 4.0 - 8.0 */
        -webkit-animation-direction: alternate; /* Safari 4.0 - 8.0 */
        height: 100%;
        & .TimelineLabel {
          font-size: 12px;
          color: $sub-text-color;
          & .Start {
            position: absolute;
            top: 50%;
            left: -40px;
            -webkit-transform: rotate(-50deg); /* Safari 3-8 */
            transform: rotate(-50deg);
          }
          & .Label1 {
            position: absolute;
            top: 50%;
            left: calc(25% - 40px);
            -webkit-transform: rotate(-50deg); /* Safari 3-8 */
            transform: rotate(-50deg);
          }
          & .Middle {
            position: absolute;
            top: 50%;
            left: calc(50% - 40px);
            -webkit-transform: rotate(-50deg); /* Safari 3-8 */
            transform: rotate(-50deg);
          }
          & .Label2 {
            position: absolute;
            top: 50%;
            left: calc(75% - 40px);
            -webkit-transform: rotate(-50deg); /* Safari 3-8 */
            transform: rotate(-50deg);
          }
          & .Stop {
            position: absolute;
            top: 50%;
            right: -10px;
            -webkit-transform: rotate(-50deg); /* Safari 3-8 */
            transform: rotate(-50deg);
          }
        }

        & .TimelineValue {
          &:hover {
            opacity: 0.8;
          }
          &:hover .Title {
            visibility: visible;
            opacity: 1;
            transform: translateX(-50%) scale(1, 1);
          }
          & .Title {
            font-size: 10px;
            white-space: nowrap;
            background-color: $main-container-bg-color;
            color: $main-text-color;
            text-align: left;
            border-radius: 4px;
            padding: 7px;
            position: absolute;
            z-index: 1;
            bottom: 110%;
            visibility: hidden;
            transform: translateX(-50%) scale(0.5, 0.25);
            opacity: 0;
            transition: all 0.15s ease-in-out;
            &::after {
              content: "";
              position: absolute;
              top: 100%;
              left: 50%;
              margin-left: -6px;
              border-width: 6px;
              border-style: solid;
              border-color: #1c1c1f transparent transparent transparent;
            }

            & .Time {
              font-size: 12px;
              font-weight: 600;
              margin-bottom: 5px;
            }
            & .Value {
              font-size: 12px;
            }
          }

          & .TooltipWrapper {
            width: 100%;
            height: 100%;
          }
          & .Timestamp {
            position: absolute;
            font-size: 12px;
            font-weight: 500;
            color: $sub-text-color;
            // margin-top: 75px;
            bottom: -40px;
            left: -35px;
            -webkit-transform: rotate(-60deg); /* Safari 3-8 */
            transform: rotate(-60deg);
          }
        }
      }
    }
  }
}

@keyframes changewidth {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@-webkit-keyframes changewidth {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
