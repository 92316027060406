@import '../../../assets/styles/color';

.DocumentEditor {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    & .EditorHeader {
        // width: calc(100vh-20px);
        background-color: $navigation-bg-color;
        border-bottom: 1px solid $border-color;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 20px;
        gap: 10px;

        & .EditorBackButton {
            cursor: pointer;
        }

        & .EditorHeaderTitle {
            flex: 1;

            & input {
                border: none;
                outline: none;
                font-size: 16px;
                font-weight: 500;
                color: $main-text-color;
                background-color: transparent;
                border-bottom: 1px solid $sub-text-color;
                padding-bottom: 5px;
                margin: 0px 10px;
                width: 300px;
            }
        }

        & .EditorSaveButton {
            width: 140px;
            cursor: pointer;
        }
    }

    & .Editor {
        flex: 1;
        width: 100%;
        height: 100%;
        color: black;
        margin-bottom: 42px;
    }
}