@import '../../../../assets/styles/color';

.Container {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .PaginationWrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        justify-items: center;
        padding: 0 30px;
        gap: 10px;

        & .SkipPagination {
            display: flex;
            height: 30px;
            color: $sub-text-color;
        }

        & .Pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 30px;
        border-radius: 4px;
        background-color: $main-container-bg-color;
        color: $sub-text-color;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: $primary-color;
            color: $main-text-color;
        }

        &.PaginationActive {
            background-color: $primary-color;
            color: $main-text-color;
        }
        }
    }

    & .AmountWrapper {
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        justify-content: center;
        color: $sub-text-color;
        font-size: 14px;
        gap: 10px;

        & .SelectBox {
            background-color: $main-container-bg-color;
            color: $widget-text-color;
            height: 30px;
            width: 60px;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid $border-color;
            border-radius: 4px;
            box-sizing: border-box;
            outline: none;
            resize: none;
            text-indent: 10px;
        }
    }
}