@import "../../../../assets/styles/color";

.OrganizationAdmin {
  position: relative;
  color: $auth-title-color;
  display: flex;
  flex-direction: column;
  & .AdminList {
    height: 78px;
    width: 100%;
    border-bottom: 1px solid $card-text-color;
    display: flex;
    & .AdminImage {
      margin: auto 30px;
      & .Image {
        width: 40px;
        height: 40px;
        border-radius: 40px;
      }
    }
    & .AdminDetail {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: auto 0;
      & .Name {
        font-size: 14px;
        font-weight: 600;
      }
      & .Detail {
        font-size: 14px;
        font-weight: normal;
        color: $sub-text-color;
      }
    }
    & .AdminButton {
      flex: 0 1 120px;
      margin: auto 30px;
    }
  }
}

.AddAdminWrapper {
  margin: 16px 30px 5px 30px;
  display: flex;
  & .SearchAdmin {
    width: 100%;
  }
  & .AddButton {
    margin-left: 20px;
    width: 250px;
  }
}
