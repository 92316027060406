@import "../../../../assets/styles/color";

.AssetMap {
  margin: 30px;
  background: $navigation-bg-color;
  padding: 37px;
  & .Title {
    flex: 1;
    font-size: 18px;
    font-weight: 500;
    color: $card-text-color;
    margin: auto;
  }
  & .Button {
    display: flex;
    margin: auto;
  }

  & .TableWrapper {
    & table {
      width: 100%;
      border-collapse: collapse;
      & thead {
        text-align: left;
        color: $sub-text-color;
        font-size: 12px;
        font-weight: 600;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        & th {
          padding: 14px 10px;
        }
      }
      & tbody {
        font-size: 14px;
        font-weight: 500;
        color: $sub-text-color;
        & tr {
          cursor: pointer;
          border-bottom: 1px solid $border-color;
          &:hover {
            background-color: $main-container-bg-color;
          }
        }
        & td {
          padding: 24px 10px;
        }
      }
    }
  }
  & .ActiveList {
    background: $main-container-bg-color;
    color: $card-text-color;
  }
}
