@import "../../../../assets/styles/color";

.HeaderSection {
  display: flex;
  padding: 20px 30px;
  border-bottom: 1px solid $border-color;

  & .HeaderText {
    color: $main-text-color;
    font-size: 20px;
    font-weight: 600;
  }
  & .SubHeaderText {
    color: $sub-text-color;
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
  }
  & .HeaderButtonsWrappper {
    display: flex;
    flex: 0 1 300px;
  }

  & .CloseButton {
    position: absolute;
    color: $sub-text-color;
    right: 23px;
    top: 12px;
    font-size: 32px;
    display: flex;
    align-items: flex-start;

    & .Btn {
      cursor: pointer;
      margin: 5px;
    }
  }
}

.TabWrapper {
  display: flex;

  & .TabSection {
    flex: 1;
    color: $auth-title-color;
    border-bottom: 1px solid $border-color;
    padding: 15px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  
    &.Active {
      color: $sub-text-color;
      background-color: $main-container-bg-color;
      border-bottom: 3px solid $primary-color;
    }
  }
}

.BodySection {
  min-height: 275px;
  display: flex;
  flex: 1;

  & div {
    flex: 1;
  }

  & .BetweenSection {
    height: 10px;
    width: 100%;
  }
  
  & .Label {
    color: $sub-text-color;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 12px;
    &.SameLine {
      display: inline-block;
      text-transform: initial;
    }
  }

  & .Selected { 
    color: $primary-color;
    font-size: 12px;
    font-weight: 600;
    display: inline-block;
    margin-left: 16px;
    &.Green {
      color: $green-color;
    }
    &.Red {
      color: $danger-color;
    }
  }

  & .NetworkSelectBox {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    cursor: pointer;
  }

  & .Option {
    color: $form-label-color;
    font-size: 14px;
    font-weight: 500;
    user-select: none;
    &.Active {
      color: $card-text-color;   
    }
  }

  & .ModbusWrapper {
    display: flex;
    color: $sub-text-color;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 16px;
    border-radius: 4px;
    border: solid 1px $border-color;
    background-color: $border-color;
    margin-bottom: 15px;
  }

  & .BorderBetweenReview {
    width: 100%;
    height: 1px;
    background-color: $border-color;
    margin: 4px 0 16px 0;
  }
}

.SwitchWrapper {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  font-size: 12px;
  font-weight: bold;
  color: $form-label-color;
  border-radius: 4px;
  border: solid 2px $border-color;
  background-color: $main-container-bg-color;
  margin-bottom: 1rem;
  cursor: default;
  &:hover {
    border-color: $primary-color;
  }
}

.FooterSection {
  padding: 15px 30px;
  border-radius: 6px;
  box-shadow: -10px 0 20px 0 rgba(0, 0, 0, 0.3);
  background-color: $navigation-bg-color;
  display: flex;
  justify-content: space-between;
}

.column {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.Container {
  display: flex;
  flex: 1;
  align-items: stretch;
  align-content: stretch;

  & .MenuWrapper {
      flex: 1;
      border-right: 1px solid $border-color;
      overflow: auto;

      & .Menu {
          display: flex;
          align-items: center;
          padding: 20px;
          cursor: pointer;
          border-bottom: 1px solid $border-color;

          & .Label {
            flex: 1;
            margin: 0;
            color: $sub-text-color;
          }

          & .Btn {
            cursor: pointer;
            width: 15px;
          }
      }

      & .Active {
          background-color: $main-container-bg-color;
          & .Label {
              color: #fff;
          }
      }

      & .TabLegend {
          height: 6px;
          flex: 0 0 6px;
          margin-right: 10px;
          &.Analog {
            background-color: cadetblue;
          }
          &.Modbus {
            background-color: $primary-color;
          }
      }
  }

  & .ContentWrapper {
      flex: 2;
      padding: 30px;
      overflow: auto;

      & .Label {
          color: $sub-text-color;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 12px;
          &.SameLine {
            display: inline-block;
            text-transform: initial;
          }
      }
  }

}

.HealthRowWrapper {
  display: flex;
  padding: 10px 0;
  padding-left: 5px;
  &:not(:first-child):hover {
    background-color:  $main-container-bg-color;
  }
  & .Status {
    flex: 0 0 250px;
    text-align: center;
  }
  & .Info {
    font-size: 14px;
    font-weight: 500;
    &.On {
      color: $green-color;
    }
    &.Off {
      color: $danger-color;
    }
  }
}

@media screen and (max-width: 900px) {
  .HeaderSection {
    flex-direction: column;
  }
  .HeaderButtonsWrappper {
    flex: 1 !important;
  }
}