$primary-color: #1665d8;
$main-container-bg-color: #1c1c1f;
$navigation-bg-color: #222226;
$border-color: #2b2b30;
$card-bg-color: #2b2b30;

$main-text-color: #ffffff;
$card-text-color: #eaedf3;
$sub-text-color: #9ea0a5;
$widget-text-color: #f0f3f8;
$light-bg-color: #fbfbfd;

$auth-title-color: #3e3f42;
$auth-button-color: #4c84ff;
$auth-input-border-color: #e2e5ed;

$verified-fail-button-color: #1461d2;
$verified-success-button-color: #2f9e3e;

$form-label-color: #6b6c6f;
$selected-radio-bg-color: rgba(22, 101, 216, 0.1);

$danger-color: #e6492d;
$green-color: #34aa44;
$yellow-color: #facf55;
$orange-color: #f6ab2f;

$modal-text-color: #2f2e35;
$tooltip-bg-color: #424242;