@import "../../../../assets/styles/color";

.VerifiedSuccess {
    height: 100vh;
    background-image: url(../../../../assets/images/VerifiedEmail/done-pic@2x.png);
    background-size: 100vw 100vh;
    & .Container {
      color: $auth-title-color;
      padding: 12%;
      text-align: left;
      display: flex;
      flex-direction: column;
      & .Header {
        font-size: 50px;
        margin-bottom: 32px;
        font-weight: 600;
      }
  
      & .Description {
        font-size: 18px;
        line-height: 1.67;
      }
    }
  
  }
  
  .ButtonContainer {
    margin-top: 75px;
    width: 30%;
  }