@import '../../../../assets/styles/color';

.Tooltip {
  position: relative;
  display: inline-block;
  &:hover .Title {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) scale(1, 1);
  }
  & .Title {
    font-size: 10px;
    // white-space: nowrap;
    background-color: $tooltip-bg-color;
    color: $main-text-color;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    visibility: hidden;
    transform: translateX(-50%) scale(0.5, 0.25);
    opacity: 0;
    transition: all .15s ease-in-out;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -4px;
      border-width: 4px;
      border-style: solid;
      border-color: #424242 transparent transparent transparent;
    }
  }
  
}