@import "../../../../assets/styles/color";


.LineFormGroup {
  margin-bottom: 38px;
  display: flex;
}

label.Title {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: $form-label-color;
  margin-bottom: 10px;
}

.FormGroup {
  margin-top: 15px;
  & .FormField {
    -webkit-appearance: none;
    appearance: none;
    display: block;
    font-family: inherit;
    width: 100%;
    padding: 10px 16px;
    font-size: 14px;
    border-radius: 4px;
    border: solid 2px $border-color;
    background-color: $main-container-bg-color;
    color: $main-text-color;
    transition: border-color .15s ease-in-out;
    box-sizing: border-box;
    outline: none;
    resize: none;
    &:hover, &:focus {
      border-color: $primary-color;
    }
  }
}

.AddButton {
  color: $primary-color;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  border: 1px dashed $primary-color;
  text-align: center;
  padding: 10px 0;
  border-radius: 4px;
  cursor: pointer;
  // margin: 20px 0;

  & .AddIcon {
    margin-right: 10px;
    vertical-align: middle;
  }
  & .ButtonName {
    display: inline-block;
  }
}

.IconWrapper {
  margin: 5px auto auto 5px;
  flex: 0 1 30px;
  & .IconButton {
    margin-left: 7px;
    padding: 9px 0;
    cursor: pointer;
  }
}

.SensorWayLabel {
  color: $primary-color;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  margin: 0px 5px;
}