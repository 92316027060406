@import "../../../../assets/styles/color";

.AddNewNotificationWrapper {
  & .AddFormWrapper {
    position: relative;
    background-color: $navigation-bg-color;
    border: 1px solid $border-color;
    padding: 25px 30px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    & .Divider {
      width: 100%;
      height: 1.5px;
      background-color: $border-color;
      margin: 30px 0;
      border: 0;
    }
    & .CloseIcon {
      cursor: pointer;
      position: absolute;
      right: 20px;
    }
    & .Title {
      color: $card-text-color;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.56;
    }
    & .Description {
      color: $sub-text-color;
      font-size: 14px;
      line-height: 1.56;
    }
    & .Flex {
      display: flex;
      flex-wrap: wrap;
      column-gap: 5px;
      row-gap: 5px;
      justify-content: space-between;
      & .LeftTextField {
        flex: 1;
        min-width: 400px;
        & label.ThresholdTitle {
          display: inline-block;
          font-size: 12px; 
          font-weight: bold;
          color: $form-label-color;
          text-transform: uppercase;
          margin-bottom: 0.5rem;
        }
      }
      & .RightTextField {
        flex: 1;
        min-width: 400px;
      }
    //   & .MiddleTextField {
    //     flex: 1;
    //     min-width: 200px;
    //     // margin-right: 5px;
    //   }
      & .countingCharacters {
        color: $form-label-color;
        text-align: right;
        font-size: 10px;
        font-weight: 500;
        margin-top: 5px;
        margin-right: 5px;
      }
    }
  }

  & .ButtonsWrapper {
    padding: 45px 30px;
    display: flex;
    justify-content: space-evenly;
  }
}

.LabelGroup{
  display: flex;
  margin-bottom: 0.5rem;
  & .Label {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $form-label-color;
  }
  & .Info{
    margin: auto 10px;
  }
}

.ErrorMessage {
  color: $danger-color;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;  
  margin-right: 7px;
  text-align: right;
}

.Topic {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: $form-label-color;
  margin-bottom: 15px;
}

label.TimeTitle {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: $form-label-color;
  margin-bottom: .7rem;
}

.FormGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  & .SelectBox {
      flex: 1;
      width: 100%;
      padding: 10px 16px;
      background-color: $main-container-bg-color;
      color: $widget-text-color;
      // height: 38px;
      border-radius: 4px;
      font-family: inherit;
      font-size: 14px;
      font-weight: 500;
      border: 1px solid $border-color;
      box-sizing: border-box;
      outline: none;
      resize: none;
      &::-webkit-calendar-picker-indicator {
        filter: invert(0.75);
      }
      &::-webkit-calendar-picker-indicator:hover {
        filter: invert(0);
      }
      &:disabled {
        color: $sub-text-color;
      }
  }
  & .CalendarIcon {
    position: absolute;
    top: 11px;
    left: 18px;
  }
  & .MachineBoxWrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit,minmax(110px,110px));
      grid-gap: 8px;
      & .MachineBox {
          cursor: pointer;
          border: 1px solid $form-label-color;
          background-color: $main-container-bg-color;
          border-radius: 4px;
          height: 96px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          &.Selected {
            border: 1px solid $primary-color;
            background-color: #4c84ff77;
          }
          &.Disabled {
            background-color: $form-label-color;
            opacity: 0.5;
          }
      }
  }
}