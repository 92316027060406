
@import "../../../../assets/styles/color";

.BreakdownCodesWrapper {
  position: relative;
  & .BreakdownCodeRow {
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: 2px solid $navigation-bg-color;;
    background-color: $border-color;
    display: flex;
  }
  & .BreakdownCodeChildRow {
    cursor: pointer;
    padding: 20px 30px;
    border: 1px solid $border-color;
    background-color: $navigation-bg-color;
  }
  & .OpenChild { 
    font-size: 20px;
    font-weight: 600;
    margin-right: 8px;
    user-select: none;
    width: 15px;
    height: 25px;
  }
  & .Code {
    font-size: 12px;
    font-weight: 600;
    color: $sub-text-color;
  }
  & .Title {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    color: $main-text-color;
  }
  & .Description {
    margin-top: 5px;
    font-size: 14px;
    color: $sub-text-color;
  }
  & .ChildAmount {
    margin-left: 8px;
    font-size: 12px;
    color: $sub-text-color;
  }
}

.BreakdownCodesSelectionWrapper {
  position: relative;
}

.ScannerIconInPO {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 999;
}