@import '../../../../assets/styles/color';

.ChartWrapper {
    position: relative;
    background-color: $navigation-bg-color;
    padding: 20px;
    border-radius: 6px;

    & .Header {
        font-size: 18px;
        color: $main-text-color;
        font-weight: 600;
        margin-bottom: 10px;

        & span {
            font-weight: 500;
            font-size: 16px;
            color: $main-text-color;
        }
    }
}

.LoadingText {
    color: $sub-text-color;
    font-size: 12px;
    text-align: center;
    margin: 50px 0;
}