@import "../../../../assets/styles/color";

.ProgressBarWrapper {
  display: flex;
  align-items: center;
  & .Progress {
    width: 100%;
    background-color: $sub-text-color;
    height: 10px;
    border-radius: 5px;
    margin-right: 8px;
    &.Green {
      background-color: $green-color;
    }
    &.Blue {
      background-color: $primary-color;
    }
    &.Small {
      height: 6px;
    }
    &.Large {
      height: 20px;
      border-radius: 10px;
    }
  }
  & .Percent {
    margin-left: 5px;
    font-size: 18px;
    &.Small {
      font-size: 14px;
    }
    &.Green {
      color: $green-color;
    }
    &.Blue {
      color: $primary-color;
    }
  }
}
