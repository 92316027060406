@import "../../../../assets/styles/color";

.BarChart {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    .Label {
        font-size: 20px;
        font-weight: 500;
        min-width: 20px;
        margin: 0;
        padding: 0;
    }

    .BarWarpper {
        position: relative;
        flex: 1;
        height: 30px;
        border: 1px solid $border-color;
        border-radius: 5px;

        & .Bar {
            min-height: 30px;
            border-radius: 5px;
        }

        & .ValueLabel {
            position: absolute;
            font-weight: 500;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}