@import "../../../../assets/styles/color";

.AssetList {
  background: $navigation-bg-color;
  padding: 37px;

  & .TableWrapper {
    & table {
      width: 100%;
      border-collapse: collapse;
      & thead {
        text-align: left;
        color: $sub-text-color;
        font-size: 12px;
        font-weight: 600;
        border-bottom: 1px solid $border-color;
        & th {
          padding: 14px 10px;
        }
      }
      & tbody {
        font-size: 14px;
        font-weight: 500;
        color: $sub-text-color;
        & tr {
          border-bottom: 1px solid $border-color;
        }
        & td {
          padding: 24px 10px;
        }
      }
    }
  }
}
