@import "../../../assets/styles/color";

.FilterBar {
  width: 100%;
  display: flex;
  & .FilterName {
    margin: 0 16px 30px 16px;
    display: flex;
    & .Name {
      font-size: 14px;
      font-weight: 500;
      color: $sub-text-color;
      margin: auto 20px;
    }
  }
  & .FilterLine {
    flex: 1;
    width: 100%;
    height: 1px;
    background-color: $navigation-bg-color;
    margin: 10px 0 0 0;
  }
  & .FilterButton {
    cursor: pointer;
    margin: 0 16px 30px 16px;
    flex: 0;
  }
}

.CardGridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
  grid-gap: 30px;
}

.CardGridMobileContainer {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px;
}

@media screen and (max-width: 1024px) {
  .CardGridContainer {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}