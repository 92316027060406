@import "../../../../assets/styles/color";

.SearchBox {
  width: 200px;
  margin: 10px;
  border: 1px solid $auth-input-border-color;
  background-color: $main-text-color;
  color: $auth-title-color;
  font-family: inherit;
  padding: 10px 16px;
  font-size: 14px;
  border-radius: 4px;
}

.DeleteShapeButton{
  width: 200px;
  margin-top: 10px;
}