@import '../../../../assets/styles/color';

.EnergyDashboard {
    & .TopSection {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .DateWrapper {
            display: flex;
            align-items: baseline;

            & .ShowingDate {
                font-size: 32px;
                color: $main-text-color;
                font-weight: 600;
            }

            & .ActionForDate {
                font-size: 14px;
                color: $sub-text-color;
                padding-left: 20px;
                cursor: pointer;
                user-select: none;
            }
        }
    }

    & .NavigationSelectionSection {
        margin: 30px 0;
        display: flex;
        align-items: center;

        & .NextChild {
            font-size: 20px;
            font-weight: 600;
            margin: 0 10px;
            color: $main-text-color;
        }
    }
}

.SelectedDateWrapper {
    position: absolute;
    top: 0;
    opacity: 0;
    width: 72px;
    margin-left: 20px;

    & input[type="date"]::-webkit-calendar-picker-indicator,input[type="month"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
}

.SeeLevels {
    font-size: 14px;
    color: $sub-text-color;
    padding-left: 20px;
    cursor: pointer;
    user-select: none;
    margin-left: 10px;
}

.LandingText {
    margin: 100px;
    text-align: center;
    & .Header1 {
        margin-top: 40px;
        font-weight: 500;
        font-size: 24px;
        color: $main-text-color;
    }
    & .Header2 {
        margin-top: 20px;
        font-weight: 600;
        font-size: 42px;
        color: $main-text-color;
    }
}

.DailyReportTypeModal {
    padding: 30px;
    text-align: center;
    & .Title {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 35px;
    }
}