@import '../../../assets/styles/color';

.PcsSettings {
    & .TopSection {
        height: 68px;
        background-color: $navigation-bg-color;
        display: flex;
        border-bottom: 1px solid $border-color;

        & .MenuWrapper {
            flex: 1;
            display: flex;
            margin-left: 30px;

            & .ActiveMenu {
                cursor: pointer;
                display: flex;
                align-items: center;
                border-bottom: 3px solid $primary-color;
                margin-right: 32px;

                & .MenuTitle {
                    font-size: 14px;
                    font-weight: 600;
                    color: $sub-text-color;
                }
            }

            & .Menu {
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-right: 32px;
                border-bottom: 3px solid $navigation-bg-color;

                & .MenuTitle {
                    font-size: 14px;
                    color: $form-label-color;
                }
            }
        }

        & .AddButtonWrapper {
            position: relative;
            margin: 14px 30px;
            width: 250px;
        }
    }

    & .Topic {
        font-size: 20px;
        font-weight: 600;
        color: $main-text-color;
        border-bottom: 1px solid $sub-text-color;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    & .SettingsSection {
        display: flex;
        flex-direction: column;
        width: 450px;
    }

    & .TickIcon {
        width: 20px;
        height: 20px;
    }

    & .SettingWrapper {
        display: inline-block;
        background-color: $navigation-bg-color;
        border-radius: 4px;
        border: 1px solid $border-color;
        padding: 10px 20px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        & .Name {
            flex: 1;
            width: 300px;
            font-size: 16px;
            font-weight: 500;
            color: $main-text-color;
        }
    }
}

.SelectBox {
    flex: 1;
    width: 100%;
    padding: 0 16px;
    background-color: $main-container-bg-color;
    color: $widget-text-color;
    height: 38px;
    border-radius: 4px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid $border-color;
    box-sizing: border-box;
    outline: none;
    resize: none;

    &::-webkit-calendar-picker-indicator {
        filter: invert(0.75);
    }

    &::-webkit-calendar-picker-indicator:hover {
        filter: invert(0);
    }
}

.Modal {
    text-align: center;
    min-height: 100px;
    padding: 30px;

    & .ModalText {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.21;
        margin-bottom: 35px;
        color: $modal-text-color;
    }

    & .ButtonWrapper {
        width: 145px;
        margin-top: 20px;
        display: inline-block;
    }
}

@media screen and (max-width: 1024px) {
    .SettingsSection {
        width: 100% !important;
    }
  }