@import '../../../assets/styles/color';

.Structure {
  & .TopSection {
    height: 68px;
    background-color: $navigation-bg-color;
    display: flex;
    border-bottom: 1px solid $border-color;
    & .MenuWrapper {
      flex: 1;
      display: flex;
      margin-left: 30px; 
      & .ActiveMenu {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: 3px solid $primary-color;
        margin-right: 32px;
        & .MenuTitle {
          font-size: 14px;
          font-weight: 600;
          color: $sub-text-color;
        }
      }
      & .Menu {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 32px;
        border-bottom: 3px solid $navigation-bg-color;
        & .MenuTitle {
          font-size: 14px;
          color: $form-label-color;
        }
      }

    }
    & .AddButtonWrapper {
      margin: 14px 30px;
      width: 200px;
    }
  }

  & .MainContentPadding{
    padding: 0px;
  }
}