@import '../../../../assets/styles/color';

.PageLoader {
  position: fixed;
  z-index: 1001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Percent {
  text-align: center;
  color: $primary-color;
  font-size: 20px;
  font-weight: 600;
}