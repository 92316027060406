@import '../../../assets/styles/color';

.TopSection {
  background-color: $navigation-bg-color;
  display: flex;
  border-bottom: 1px solid $border-color;
  & .MenuWrapper {
    flex: 1;
    display: flex;
    margin-left: 30px; 
    & .ActiveMenu {
      cursor: pointer;
      display: flex;
      align-items: center;
      border-bottom: 3px solid $primary-color;
      margin-right: 32px;
      & .MenuTitle {
        font-size: 14px;
        font-weight: 600;
        color: $sub-text-color;
      }
    }
    & .Menu {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-right: 32px;
      border-bottom: 3px solid $navigation-bg-color;
      & .MenuTitle {
        font-size: 14px;
        color: $form-label-color;
      }
    }
  }
  & .AddButtonWrapper {
    display: flex;
    align-items: center;
    margin: 14px 30px 14px 0;
    width: 250px;
  }
}

.MasterDataWrapper {
  position: relative;
  & .DocMasterDataRow {
    cursor: pointer;
    padding: 16px 30px;
    border-bottom: 2px solid $navigation-bg-color;;
    background-color: $border-color;
    display: flex;
    &.Selected {
      background-color: $primary-color !important;
    }
  }
  & .MasterDataChildRow {
    cursor: pointer;
    padding: 20px 30px;
    border: 1px solid $border-color;
    background-color: $navigation-bg-color;
  }
  & .OpenChild { 
    font-size: 20px;
    font-weight: 600;
    margin-right: 8px;
    user-select: none;
    width: 15px;
    height: 25px;
  }
  & .Code {
    font-size: 16px;
    font-weight: 600;
    color: $main-text-color;
  }
  & .Title {
    font-size: 16px;
    font-weight: 500;
    color: $card-text-color;
  }
  & .Description {
    margin-top: 5px;
    font-size: 14px;
    color: $sub-text-color;
  }
  & .ChildAmount {
    margin-left: 8px;
    font-size: 12px;
    color: $sub-text-color;
  }
}