@import '../../assets/styles/color';

.Sidebar {
  position: fixed;
  top: 60px;
  left: 0px;
  width: 230px;
  height: -webkit-calc(100vh - 60px);
  height: -moz-calc(100vh - 60px);
  height: calc(100vh - 60px);
  background-color: $navigation-bg-color;
  // overflow: hidden;
  z-index: 1;
  transition: width 0.225s ease;
}

.NotificationTopbar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  font-size: 16px;
  font-weight: 500;
  background-color: #13376d;
  color: $card-text-color;
  line-height: 22px;
  position: relative;
  & .CloseButton {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }
  & .Title {
    font-size: 20px;
    font-weight: 600;
    margin-right: 10px;
    color: $main-text-color;
  }
  & .PostedDate {
    margin-top: 5px;
    font-size: 12px;
    color: $sub-text-color;
  }
}

.MainContainer {
  // position: relative;  // without this for fullscreen in widget
  margin-left: 230px;
  margin-top: 60px;
  min-height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $main-container-bg-color;
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .Sidebar {
    width: 80px;
    box-shadow: -10px 0 20px 0 rgba(0, 0, 0, 0.5);
    &:hover {
      width: 270px;
    }
  }

  .MainContainer {
    margin-left: 80px;
  }
}