@import '../../../../assets/styles/color';

.WidgetFormulaModal {
  // background-color: khaki;
  height: 90%;
  // overflow: auto;
  padding: 10px 30px;
  box-sizing: border-box;
  margin-top: 30px;
  color: $modal-text-color;
  // text-align: center;
  // position: absolute;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 99;
  & .Title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.21;
    margin-bottom: 34px;
    color: #fff;
  }
  & .Description {
    font-size: 16px;
    line-height: 1.6;
    opacity: 0.7;
    color: #fff;
  }
  & .YesButtonWrapper {
    width: 145px;
    margin-top: 34px;
    display: inline-block;
  }
  & .CancelButton {
    font-size: 14px;
    color: $form-label-color;
    cursor: pointer;
  }
  & .AddFormulaObjectButton {
    font-size: 14px;
    line-height: 1.6;
    // opacity: 0.7;
    color: #fff;
    // max-width: 660px;
    max-width: 380px;
    margin: 0 auto;
    // text-align: center;
    height: 30px;
    border: #1665d8 1px dashed;
    border-radius: 6px;
    padding: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(22, 101, 216, 0.1);
  }

  & .formulaObjectContainer {
    position: relative;
    margin-bottom: 10px;
    max-height: 300px;
    overflow: auto;
    // scroll-margin-left: 12px;
  }

  & .formulaObject {
    position: relative;
    display: flex;
    padding: 6px 0;
    box-sizing: border-box;
  }
  & .objectIndex {
    color: #fff;
    width: 20px;
    padding-top: 12px;
    margin-right: 10px;
  }

  & .objectIndex:hover {
    cursor: default;
  }

  & .formulaObject label{
    color: #fff;
    font-size: 12px;
  }

  & .formulaObject div{
    margin-right: 2px;
  }

  & .textInputField {
    background-color: #1c1c1f;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    outline: none;
    border-style: solid;
    border-width: 1px;
    padding: 10px;
    border-color: #2b2b30;
  }

  & .textInputField:focus {
    border-color: #1665d8;
  }

  & .textInputField:hover {
    border-color: #1665d8;
  }
}

.Background {
  height: 357px;
  width: 100%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
}

.FormField {
  display: block;
  font-family: inherit;
  // width: 100%;
  // width: 100px;
  // width: 100%;
  margin-top: 4px;
  padding: 8px 12px;
  font-size: 13px;
  border-radius: 4px;
  border: solid 1px $border-color;
  background-color: $main-container-bg-color;
  color: $main-text-color;
  transition: border-color .15s ease-in-out;
  box-sizing: border-box;
  outline: none;
  resize: none;
  &:hover, &:focus {
    border-color: $primary-color;
  }
}

label {
  padding-left: 2px;
  // margin-bottom: 8px;
}