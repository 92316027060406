@import '../../../../assets/styles/color';

.ProductionBatchs {
  background-color: $card-bg-color;
  border-radius: 4px;

  & .BatchCard {
    // & tr:not(:first-child)
    border-bottom: 1px solid $main-container-bg-color;
    padding: 20px 30px;
    cursor: pointer;
    display: flex;

    & .BatchInfos {
      flex: 1;
      width: 60%;
      display: flex;
      flex-direction: column;
      & .BatchName {
        font-size: 16px;
        font-weight: 600;
        color: $card-text-color;
        line-height: 1.57;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 90%;
        & .BatchPlans {
          font-size: 14px;
          font-weight: 500;
          display: inline-block;
        }
      }
      & .BatchTime {
        font-size: 14px;
        color: $form-label-color;
        line-height: 1.57;
      }
    }
  }
}

.ActionButtons {
  display: flex;
  align-items: center;
  & .ArrowIcon {
    margin-left: 30px;
    transform: rotate(180deg);
    transition: transform 0.25s ease-in;
    vertical-align: super;
    &.ToggleDown {
      transform: rotate(0deg);
    }
  }
}

.SelectBox {
  flex: 1;
  width: 100%;
  padding: 0 16px;
  background-color: $main-container-bg-color;
  color: $widget-text-color;
  height: 38px;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid $border-color;
  box-sizing: border-box;
  outline: none;
  resize: none;
    
  &::-webkit-calendar-picker-indicator {
    filter: invert(0.75);
  }
  &::-webkit-calendar-picker-indicator:hover {
    filter: invert(0);
  }
}
input[type=date]::-webkit-calendar-picker-indicator:hover {
  background-color: $border-color;
}

.DateBox {
  padding: 0 16px;
  // background-color: $main-container-bg-color;
  // color: $widget-text-color;
  height: 38px;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid $border-color;
  box-sizing: border-box;
  outline: none;
  resize: none;
  &::-webkit-calendar-picker-indicator:hover {
    background-color: whitesmoke !important;
  }
}

.Title {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: $form-label-color;
  margin-bottom: .7rem;
}

.CalendarIcon {
    position: absolute;
    top: 9px;
    left: 18px;
}


.MapPlansModal {
  position: relative;
  color: $auth-title-color;
  display: flex;
  flex-direction: column;
  min-height: 520px;
  // max-height: 720px;
  & .HeaderWrapper {
    display: flex;
    border-bottom: 1px solid $card-text-color;
    padding: 24px 40px 24px 30px;
    align-items: center;
    & .Header {
      flex: 1;
      font-size: 20px;
      color: $auth-title-color;
      font-weight: 600;
      line-height: 1.5;
    }
  }
  & .BodyWrapper {
    flex: 1;
    max-height: 630px;
      & .AddedPlansWrapper {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 30px 10px 30px;
      & > div {
        display: flex;
        flex: 1 1 190px;
      }
      & .AddedPlanBox {
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 4px;
        border: 1px solid $card-text-color;
        border-left: 3px solid $primary-color;
        font-size: 16px;
        font-weight: 600;
        color: $main-text-color;
        background-color: $primary-color;
        & .MappingPO {
          flex: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 180px;
        }
      }
    }
    & .SearchWrapper {
      padding: 20px 30px;
      background-color: $light-bg-color;
      display: flex;
      justify-content: space-between;
      & .SearchButton {
      padding: 10px 12px;
      cursor: pointer;

        &:hover {
          background-color: $card-text-color;
          border-radius: 50%;
        }
      }
    }
    & .PlanSearchResult {
      overflow: scroll;
      max-height: 480px;
    }
    & .PlanRow {
      font-size: 14px;
      font-weight: 600;
      color: $auth-title-color;
      padding: 15px 30px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid $card-text-color;
      cursor: pointer;
        &:last-child {
        border-bottom: 1px solid $card-text-color;
      }
      &:hover {
        background-color: #1665d822;
      }
    }
  }
  & .ButtonsWrapper {
    display: flex;
    background-color: $light-bg-color;
    padding: 20px;
    justify-content: space-evenly;
    align-items: center;
    & .YesButtonWrapper {
      width: 145px;
      display: inline-block;
    }
    & .CancelButton {
      font-size: 14px;
      color: $form-label-color;
      cursor: pointer;
    }
  }
}