@import '../../../../assets/styles/color';

.MachineBoxesWrapper {
  display: grid;
  // grid-template-columns: repeat(5, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(330px, 330px));
  grid-gap: 30px;
}
@media screen and (max-width: 1600px) {
  .MachineBoxesWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .MachineBoxesWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

.ViewSwitchingWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  & .ViewButton {
    cursor: pointer;
    text-align: center;
    // border-radius: 4px;
    // background-color: $navigation-bg-color;
    width: 120px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    transition: 200ms ease;
    &.Left {
      background: linear-gradient(to left, $primary-color  50%, $navigation-bg-color 50%) right;
      background-size: 200%;
      background-position: right;
    } 
    &.Right {
      background: linear-gradient(to left, $navigation-bg-color 50%, $primary-color 50%) right;
      background-size: 200%;
      background-position: right;

    }
  }
  & .SelectedLeft {
    background-position: left !important;
  }
  & .SelectedRight {
    background-position: left !important;
  }
}

.PORowsWrapper {

}