@import '../../../../../../assets/styles/color';


.SwitchWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  & .Description {
    font-size: 12px;
    font-weight: 500;
    color: $sub-text-color;
  }
}