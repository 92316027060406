@import '../../../../../assets/styles/color';

.BatchBox {
  min-width: 300px;
  background-color: $navigation-bg-color;
  border-radius: 5px;
  padding: 20px 30px;
  box-sizing: border-box;
  & .BatchName {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.56;
    color: $main-text-color;
  }
  & .ProgressWrapper {
    display: flex;
    align-items: center;
    margin: 30px 0 5px 0;
    & .Percentage {
      font-size: 30px;
      font-weight: 600;
      color: $main-text-color;
    }
    & .PercentageBarWrapper {
      margin-left: 10px;
      width: 100%;
      background-color: $navigation-bg-color;
      height: 15px;
      border-radius: 4px;
      & .PercentageBarValue {
        height: 15px;
        border-radius: 4px;
        &.Green {
          background-color: $green-color;
        }
        &.Red {
          background-color: $danger-color;
        }
      }
    }
  }
  & .AmountPO {
    color: $sub-text-color;
    font-size: 12px;
  }
  & .Divider {
    background-color: $auth-title-color;
    width: 100%;
    height: 1px;
    margin: 15px 0;
  }
  & .DateWrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    & .GreenDot {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: $green-color;
      margin-right: 10px;
    }
    & .RedDot {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: $danger-color;
      margin-right: 10px;
    }
    & .StartText {
      color: $sub-text-color;
      font-size: 12px;
      margin-right: 20px;
    }
    & .StartTime {
      color: $card-text-color;
      font-size: 15px;
    }
  }
}