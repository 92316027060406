@import '../../../../assets/styles/color';

.FormGroup {
  margin-bottom: 1rem;
  position: relative;
}

.Textarea {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  font-family: inherit;
  width: 100%;
  padding: 10px 16px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 2px $border-color;
  background-color: $main-container-bg-color;
  color: $main-text-color;
  transition: border-color 0.15s ease-in-out;
  box-sizing: border-box;
  outline: none;
  resize: none;
  &.WhiteMode {
    border: 1px solid $auth-input-border-color;
    background-color: $main-text-color;
    color: $auth-title-color;
    &.Error {
      border: solid 1px $danger-color;
    }
  }
  &:hover:enabled,
  &:focus:enabled {
    border-color: $primary-color;
  }
  &.Error {
    border: solid 2px $danger-color;
  }
  &.Disabled {
    opacity: 0.5;
  }
}

.OptionsWrapper {
  position: absolute;
  z-index: 99;
  width: 100%;
  background-color: $main-container-bg-color;
  margin-top: 2px;
  border-radius: 4px;
  overflow: auto;
  height: 200px;
  & .Option {
    padding: 6px 15px;
    font-size: 14px;
    &:hover {
      background-color: $primary-color;
      cursor: pointer;
    }
  }
}