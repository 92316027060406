@import '../../../../assets/styles/color';

.NoData{
  display: flex;
  justify-content: center;
}

.NoData:before {
  content: '!';
  height: 50px;
  width: 50px;
  display: block;
  border: 3px solid $yellow-color;
  border-radius: 50%;
  color: $yellow-color;
  font-size: 40px;
}

.LoadingText {
  color: $main-text-color;
  font-size: 18px;
}
