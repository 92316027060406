@import "../../../assets/styles/color";

.Topbar {
  position: fixed;
  top: 0;
  z-index: 99;
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  align-items: center;
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 0 0 $border-color;
  background-color: $navigation-bg-color;
  
  & .LeftItems {
    flex: 1;
    margin-left: 20%;
    display: flex;
    align-items: center;
    & .LogoText {
        font-size: 24px;
        font-weight: 600;
        color: $main-text-color;
        letter-spacing: 3px;
    }
  }
  & .RightItems {
    margin-right: 20%;
    display: flex;
    align-items: center;
    & .GoToButton {
        background-color: $primary-color;
        padding: 7px 22px;
        border-radius: 4px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }
  }
}

li {
    margin: 6px 0;
}

.ReleasesWrapper {
    margin: 0 20%;
    color: $main-text-color;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    & .LeftSection {
        width: 140px;
        text-align: right;
        padding: 0 30px;
        & .VersionTag {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            & .VersionText {
                margin-left: 14px;
            }
        }
    }

    & .RightSection {
        flex: 1;
        border-left: 2px solid $border-color;
        padding: 0 30px;
        padding-bottom: 50px;
        & .LatestRelease {
            background-color: $primary-color;
            padding: 5px 12px;
            border-radius: 12px;
            display: inline-block;
            font-weight: 600;
            margin-left: 20px;
            border-bottom-left-radius: 0;
        }
        & .HeaderVersion {
            color: $primary-color;
            font-size: 42px;
            font-weight: 600;
            & .VersionText {
                // -webkit-background-clip: text;
                // background-clip: text;
                // -webkit-text-fill-color: transparent;
                // background-image: linear-gradient(90deg, rgba(22,101,216,1) 20%, rgba(19,170,200,1) 100%);
            }
        }
        & .ContentHeader {
            font-weight: 600;
            font-size: 20px;
        }
        & .Content {
            color: $card-text-color;
            font-size: 14px;
        }
    }
}

.SubHeader {
    color: $sub-text-color;
    font-size: 12px;
    font-weight: 400;
    & .DeveloperName {
        font-weight: 600;
    }
    &.InDetails {
        display: inline-block;
        margin-left: 10px;
    }
}

.GgTag,
.GgTag::before {
    display: block;
    border: 1px solid
}
.GgTag {
    border-right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    box-sizing: border-box;
    transform: scale(var(--ggs,1));
    position: relative;
    width: 19px;
    height: 14px
}
.GgTag::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    box-sizing: content-box;
    border-radius: 100px;
    right: 1px;
    top: 4.5px
}
.GgTag::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-bottom-right-radius: 2px;
    transform: rotate(-45deg);
    top: 1.1px;
    right: -5px;
}

@media screen and (max-width: 600px) {
    .Topbar {
        & .LeftItems {
            margin-left: 20px;
        }
        & .RightItems {
            margin-right: 20px;
        }
    }
    .ReleasesWrapper {
        margin: 0 20px 0 30px;  
        & .LeftSection {
            display: none;
        }
    }
    .RightSection {
        padding: 0 15px !important;
        padding-bottom: 25px !important;
    }
}