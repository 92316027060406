@import "../../../../assets/styles/color";

.ChatList {
  background-color: $navigation-bg-color;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  height: calc(100vh - 70px);
  & .TopSection {
    height: 68px;
    background-color: $navigation-bg-color;
    display: flex;
    border-bottom: 1px solid $border-color;
    & .MenuWrapper {
      display: flex;
      margin-left: 30px;
      & .Menu {
        display: flex;
        align-items: center;
        border-bottom: 3px solid $primary-color;
        & .MenuTitle {
          font-size: 14px;
          font-weight: 600;
          color: $sub-text-color;
        }
      }
    }
  }

  & .TeamSection {
    & .Header {
      color: $sub-text-color;
      padding: 20px 30px;
      border-bottom: 1px solid $border-color;
      & .HeaderTitle{
        font-size: 12px;
        font-weight: 600;
      }
    }
    & .List {
      height: 113px;
      border-bottom: 1px solid $border-color;
      display: flex;
      cursor: pointer;
      & .MemberPicture{
        margin: 25px 30px;
      }
      & .ProfilePic{
        width: 38px;
        height: 38px;
        border-radius: 50%;
      }
      & .ProfilePicTwo{
        width: 38px;
        height: 38px;
        border-radius: 50%;
        position: absolute;
        left: 10px;
        top: -20px;
        
      }
      & .TeamDetail{
        margin: 25px 30px 25px 0;
        display: flex;
        flex-direction: column;
        & .TeamName{
          font-size: 16px;
          font-weight: 600;
          color: $main-text-color;
          margin-bottom: 6px;
        }
        & .LastChat{
          font-size: 14px;
          color: $sub-text-color;
        }
      }
    }
  }
}
