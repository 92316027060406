@import "../../../../assets/styles/color";

.VerifiedFail {
  height: 100vh;
  background-image: url(../../../../assets/images/VerifiedEmail/email-verification-failed-pic@2x.png);
  background-size: 100vw 100vh;
  & .Container {
    color: $auth-title-color;
    padding: 12%;
    text-align: left;
    display: flex;
    flex-direction: column;
    & .Header {
      font-size: 50px;
      margin-bottom: 18px;
      font-weight: 600;
    }
    & .Description {
      font-size: 18px;
      line-height: 1.67;
    }
    & .FormContainer {
      display: flex;
      flex-direction: column;
      height: 100%;
      color: $sub-text-color;
    }
  }
}

.ButtonContainer {
  width: 30%;
}

.FormGroup {
  margin-bottom: 1rem;
  width: 30%;
}

.ErrorMessage {
  color: $danger-color;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}
