@import '../../../../assets/styles/color';

.MemberDrawer {
  & .Divider {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: $border-color;
    margin: 2rem 0 30px 0;
  }
  
  & .BoxWrapper {
    display: flex;
    cursor: pointer;
    border-radius: 4px;
    border: solid 1px $border-color;
    background-color: $border-color;
    margin-bottom: 16px;    
    user-select: none;
    padding: 12px;
    color: $sub-text-color;
    &.Open {
      color: $primary-color;
    }
    & .Title {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
    }
    & .ArrowIcon {
      flex: 0;
      & img{
        margin: 0;
      }
    }
  }
}