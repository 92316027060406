@import "../../../assets/styles/color";

.ReportQueryWrapper {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  border: 1px solid $border-color;
  background-color: $navigation-bg-color;

  & .ReportTitleSection {
    padding: 22px 62px;
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;

    & span.Title {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      color: $main-text-color;
      flex: 1;
    }

    & .OptionButtonWrapper {
      text-align: right;

      & .EditReportButton {
        display: inline-block;
        width: 100px;
        margin-left: 10px;
      }
  
      & .NewReportButtonOption {
        display: inline-block;
        width: 160px;
        margin-left: 10px;
      }

      & .SavePresetButtonOption {
        display: inline-block;
        width: 160px;
      }
    }
  }
  & .SelectorsSection {
    padding: 20px 40px;
    
    & .ErrorWrapper {
      height: 170px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      color: $danger-color;
      & .DetailsError {
        margin-top: 10px;
        color: $form-label-color;
        font-size: 12px;
      }
    }

    & .Percent {
      color: $primary-color;
    }
    
    & .ChartSection {
      padding-bottom: 32px;
      border-bottom: 1px solid $border-color;
    }

    & .ChartOption {
      margin-bottom: 24px;
      display: flex;
      justify-content: flex-end;
    }

    & .ReportCoverImage {
      width: 100%;
      height: 170px;
    }

    & .LegendBox {
      display: flex;
      margin-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      align-items: center;
      flex-wrap: wrap;
    }

    & .LegendText {
      font-size: 12px;
      font-weight: 500;
      margin-left: 6px;
    }

    & .FormWrapper {
      padding: 20px 22px;
      padding-bottom: 42px;
      height: 340px;
      // padding-bottom: 30px;
      // height: 400px;
      overflow: hidden;
      & .ButtonsWrapper {
        margin-top: 30px;
        text-align: right;

        & .SavePresetButton {
          display: inline-block;
          width: 140px;
          margin-right: 20px;
        }
        & .DownloadButton {
          display: inline-block;
          width: 140px;
        }
        & .ViewButton {
          display: inline-block;
          width: 260px;
          margin-left: 20px;
        }
      }

      & .FormGroup {
        display: flex;
        & label.Title {
          display: inline-block;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          color: $form-label-color;
          margin-bottom: .7rem;
        }
      
        & .SelectBox {
          flex: 1;
          width: 100%;
          padding: 0 16px;
          background-color: $main-container-bg-color;
          color: $widget-text-color;
          height: 38px;
          border-radius: 4px;
          font-family: inherit;
          font-size: 14px;
          font-weight: 500;
          border: 1px solid $border-color;
          box-sizing: border-box;
          outline: none;
          resize: none;
          &::-webkit-calendar-picker-indicator {
            filter: invert(0.75);
          }
          &::-webkit-calendar-picker-indicator:hover {
            filter: invert(0);
          }
        }
        & .CalendarIcon {
          position: absolute;
          top: 9px;
          left: 18px;
        }
      }

      & .SettingsOption {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      & .ChevronLeft {
        box-sizing: border-box;
        position: relative;
        display: block;
        transform: scale(var(--ggs,1));
        width: 22px;
        height: 22px;
        border: 2px solid transparent;
        border-radius: 100px;
        transform: rotate(0deg);
        transition: all 500ms ease-out;
        transition-property: color, transform;
      }

      & .ChevronLeft::after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid;
        border-left: 2px solid;
        transform: rotate(45deg);
        left: 6px;
        top: 4px;
      }
    }
    
    & .ChartSection {
      padding-top: 30px;
      border-top: 1px solid $border-color;
    }
  }

  .AutoFillBox {
    background-color: $card-bg-color;
    padding: 5px;
    color: $auth-title-color;
    font-size: 12px;
    display: inline-block;
    margin-right: 6px;
    cursor: pointer;
    user-select: none;
      &.Selected {
        color: $main-text-color;
        font-weight: 500;
      }
  }
}

.NoPresetWarning {
  font-size: 18px;
  line-height: 1.6;
  color: #fff;
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px;
  border: #1665d8 2px dashed;
  border-radius: 6px;
  text-align: center;
  font-weight: 500;
}

.PresetCard {
  // background-color: lightskyblue;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 16px 48px;
  // padding-left: 48px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  border: 1px solid $border-color;
  background-color: $navigation-bg-color;
  display: flex;
  align-items: center;
  // text-align: center;

  & .PresetPictureWrapper {
    // background-color: orange;
    // position: relative;
    height: 52px;
    margin-right: 12px;
    text-align: center;
    & .PresetPicture {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      // cursor: pointer;
      &.Loading {
        opacity: 0.35;
      }
    }
    & .NotAdminPresetPicture {
      width: 38px;
      height: 38px;
      border-radius: 4px;
    }
    & .PresetIconLoadingWrapper {
      position: absolute;
      width: 38px;
      // height: 100%;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
    }
    & .ControlIcon {
      position: absolute;
      border-radius: 50%;
      top: 32px;
      left: 48px;
      width: 24px;
      height: 24px;
    }
    & .ChangePresetIconTooltip {
      font-size: 10px;
      background-color: $tooltip-bg-color;
      color: $main-text-color;
      border-radius: 4px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      padding-left: 7px;
      width: 165px;
      top: 75%;
      left: 125%;
      transform: translateY(-50%) scale(0.1, 0.1);
      visibility: hidden;
      opacity: 1;
      transition: all 0.1s ease-in-out;
      &::after {
        content: "";
        position: absolute;
        top: 40%;
        left: -6px;
        border-style: solid;
        border-color: transparent #424242 transparent transparent;
      }
      &.Open {
        visibility: visible;
        opacity: 1;
        transform: translateY(-50%) scale(1, 1);
      }
    }
  }

  & .CardDetail {
    // color: lightpink;
    flex: 1;
    // background-color: greenyellow;
    margin-right: 12px;
    padding-left: 12px;
    padding-right: 12px;
    & .PresetName {
      font-size: 18px;
      font-weight: 500;
      color: $card-text-color;
      // line-height: 1.57;
      margin-bottom: 0;
    }
    & .PresetInformation {
      font-size: 14px;
      color: $form-label-color;
      // line-height: 1.57; 
      // margin-bottom: 0;
    }
  }

  & .ActionButtons {
    display: flex;
    // background-color: lightcoral;
    margin-right: 12px;
    width: 160px;
    justify-content: space-around;
  }

  & .ViewPresetButton {
  width: 120px;
  }

}

input[type=date]::-webkit-calendar-picker-indicator:hover {
  background-color: $border-color;
}

.OEEResultWrapper {
  position: relative;

  & .Button {
    position: absolute;
    right: 62px;
    top: 15px;
  }

  & .OEEResultCard {
    margin-top: 24px;
    margin-bottom: 24px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid $border-color;
    background-color: $navigation-bg-color;
    display: flex;
    flex-direction: column;
  
    & .CardTitle {
      padding: 22px 62px;
      border-bottom: 1px solid $border-color;
      display: flex;
      gap: 20px;
      align-items: baseline;
  
      & .Title {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        padding: 0;
      }
  
      & .Description {
        font-size: 12px;
        margin: 0;
        padding: 0;
        flex: 1;
      }
    }
  
    & .CardSummary {
      padding: 22px 62px;
      display: flex;
      align-items: center;
      gap: 20px;
      height: 180px;
  
      & .OEEGauge {
        position: relative;
        width: 180px;
        height: 180px;
  
        & .OEELabelWrapper {
          margin: 0;
          padding: 0;
          position: absolute;
          display: flex;
          flex-direction: column;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
  
          & p {
            margin: 0;
            padding: 0;
          }
  
          & .Label {
            font-size: 14px;
          }
  
          & .Value {
            font-size: 24px;
            font-weight: 600;
          }
        }
      }
  
      & .APQBar {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        margin-right: 40px;
      }
  
      & .ActualDetail {
        font-size: 14px;
        flex: 2;
        border: 1px solid $border-color;
        border-radius: 5px;
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 5px;
  
        & .Row {
          padding: 10px;
          display: flex;
          flex-wrap: wrap;
        }
  
        & div {
          display: flex;
          gap: 10px;
          align-items: baseline;
  
          & p {
            padding: 0;
            margin: 0;
          }
  
          & .Detail {
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
    }
  
    & .CardLineChart {
      margin-left: 42px;
      margin-right: 62px;
      margin-bottom: 22px;
    }
  
    & .CardStatusBar {
      width: 100%;
      padding-left: 42px;
      padding-right: 42px;
      padding-bottom: 22px;
    }
  }
  & .Topic {
    font-size: 14px;
    font-weight: 500;
    color: $sub-text-color;
  }
}



.Modal {
  color: black;
  text-align: center;
  min-height: 100px;

  & .Title {
    margin-top: 40px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.21;
    margin-bottom: 34px;
  }
  & .Description {
    font-size: 16px;
    line-height: 1.6;
    opacity: 0.7;
  }
  & .ButtonWrapper {
    width: 145px;
    margin-top: 34px;
    display: inline-block;
  }
}