@import '../../../../assets/styles/color';

.DonutChartTrack{
  fill: transparent;
  stroke: $main-container-bg-color;
  stroke-width: 26;
}

.DonutChartIndicator {
  fill: transparent;
  stroke-width: 26;
  stroke-dasharray: 0 10000;
  transition: stroke-dasharray .3s ease;
}

.DonutChart {
  margin: 0 auto;
  border-radius: 50%;
  display: block;
}



