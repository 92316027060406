@import '../../../../../assets/styles/color';


.DateTime {
  & .Display {
    display: flex;
    margin: 20px 30px;
    & .Value {
      flex: 1;
      font-size: 24px;
      font-weight: 600;
      color: $primary-color;
      text-align: left;
      margin: auto;
    }
    & .Icon {
      width: 46px;
      height: 46px;
    }
  }
}