@import "../../../../assets/styles/color";

.FullWrapper {
  min-height: calc(100vh - 142px);
  &.Green {
    background-color: #34aa4455;
  }
}

.HeaderWrapper {
  display: flex;
  padding: 26px 45px;
  border-bottom: 1px solid $border-color;
  justify-content: space-between;
  align-items: center;
  & .HeaderText {
    font-size: 32px;
    font-weight: 500;
  }
  & .DescriptionHeader {
    font-size: 14px;
    font-weight: 500;
  }
  & .MachineStatus {
    flex: 1;
    display: flex;
    justify-content: center;
    & div {
      border: 1px solid;
      padding: 5px 14px;
      border-radius: 6px;
      font-weight: 600;
      color: $main-text-color;
    }
  }
}

.MiddleWrapper {
  display: flex;
  height: 50%;
  & .MiddleRight {
    // flex: 3
  }
}

.GaugeWrapper {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 20px 0;
  & .GaugeValue {
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    color: $main-text-color;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  & .GaugeDetails {
    font-size: 14px;
    opacity: 0.75;
    // color: $sub-text-color;
  }
}

.InfoBoxesWrapper {
  display: flex;
  justify-content: space-between;
  margin: 20px 45px;
}

.InfoBox {
  display: flex;
  border-radius: 4px;
  background-color: rgba(28, 28, 31, 0.2);
  padding: 20px;
  font-size: 14px;
  color: $sub-text-color;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin: 0 15px;
  & .MainText {
    text-align: center;
    color: $main-text-color;
    font-size: 23px;
    font-weight: 500;
  }
  & .SubInfos {
    width: 100%;
    margin-left: 30px;
    & .LeftSub {
      flex: 1;
      margin-right: 5px;
      text-align: right;
    }
  }
}

.Chart1 {
  padding: 20px 45px;
}

.Chart2 {
  padding: 20px 45px 50px 45px;
}

@media screen and (max-width: 400px) {
  .InfoBoxesWrapper{
    margin: 20px;
  }
  .SubInfos {
    font-size: 12px;
  }
  .Chart1 {
    padding: 15px 20px;
  }
  .Chart2 {
    padding: 15px 20px 50px 20px;
  }
  .InfoBoxesWrapper {
    flex-direction: column;
  }
  .InfoBox {
    padding: 15px;
    width: auto;
    margin-bottom: 10px;
    flex-direction: row !important;
  }
  .MainWrapper {
    width: 150px;
  }
  .SubInfos {
    margin-top: 0;
    margin-left: 30px;
  }
}

@media screen and (max-width: 900px) {
  .InfoBox {
    flex-direction: column;
  }
  .SubInfos {
    margin-top: 5px;
    margin-left: 0 !important;
  }
}