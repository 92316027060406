@import "../../../../assets/styles/color";

.ProfileContainer {
  margin: 30px;
  border-radius: 4px;
  height: 540px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px $border-color;
  background-color: $navigation-bg-color;
  font-family: Montserrat;
  color: $card-text-color;

  & .HeaderCard {
    padding: 30px 30px 10px 30px;
    text-align: center;
    & .ProfilePicture {
      position: relative;
      padding: 10px;
      & .Picture {
        border-radius: 50%;
        width: 92px;
        height: 92px;
      }
      & .Loading{
        position: absolute;
        top: 35%;
        right: 45%;
      }
      & label{
        cursor: pointer;
      }
    }
    & .EditPicture{
      position: absolute;
      top: 40%;
      left: 75%;
      & input[type="file"]{
        display: none;
      }
      & label{
        cursor: pointer;
      }
    }
    & .Name {
      margin: 7px;
      font-size: 18px;
      font-weight: 600;
    }
    & .JobTitle {
      font-size: 14px;
      font-weight: normal;
      color: $sub-text-color;
    }
  }

  & .Line {
    border-bottom: 1px solid $card-bg-color;
    margin: 0 30px 10px 30px;
  }

  & .BodyCard {
    padding: 20px 55px;
    & .ListData {
      margin-bottom: 27px;
      & .Title {
        font-size: 12px;
        font-weight: 600;
        color: $card-text-color;
        margin-bottom: 7px;
      }
      & .Text {
        font-size: 14px;
        font-weight: 500;
        color: $sub-text-color;
      }
    }
  }
}
