@import "../../../../../assets/styles/color";

.LampStyleWrapper {
  padding: 15px 15px 10px 15px;
  flex: 1;
  background-color: $main-container-bg-color;
  border: 2px solid $border-color;
  border-radius: 4px;
  cursor: pointer;
  &.Selected {
    border-color: $primary-color;
  }

  & .Title {
    color: $form-label-color;
    font-size: 14px;
    font-weight: 500;
    &.Selected {
      color: $card-text-color;
    }
  }
}


.ColorTrigger {
  display: flex;
  & label.Title {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: $form-label-color;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  & .AddButton {
    margin-top: 4px;
    flex: 0 1 60px;
    & .IconButton {
      margin-left: 7px;
      padding: 9px 0;
      cursor: pointer;
    }
  }
}

.MachineStatusCbLabel {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: $widget-text-color;
  margin-left: 5px;
  user-select: none;
}