@import '../../../assets/styles/color';

.Devices {
  & .TopSection {
    height: 68px;
    background-color: $navigation-bg-color;
    display: flex;
    border-bottom: 1px solid $border-color;
    & .MenuWrapper {
      flex: 1;
      display: flex;
      margin-left: 30px; 
      & .Menu {
        display: flex;
        align-items: center;
        border-bottom: 3px solid $primary-color;
        & .MenuTitle {
          font-size: 14px;
          font-weight: 600;
          color: $sub-text-color;
        }
      }
    }
    & .AddButtonWrapper {
      margin: 14px 30px;
      width: 200px;
    }
  }

  & .DevicesTable {
    & .SearchInput{
      width: 100%;
      height: 68px;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
      border: none;
      border-bottom: 1px solid $main-container-bg-color;
      color: $sub-text-color;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      background: url(../../../assets/icons/search.svg) no-repeat scroll 30px 50%;
      background-color: $card-bg-color;
      text-indent: 70px;
      margin-bottom: 20px;
    }
    & input[type="text"]:focus{
      outline: none;
    }
  }
}