@import "../../../../assets/styles/color";

.Label {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: $form-label-color;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.SecretInput {
  & .CheckedIcon {
    position: absolute;
    top: 5px;
  }
}

.RadioField {
  position: relative;
  margin-bottom: 1rem;
  & .RadioContainer {
    display: inline;
    margin-right: 72px;
    & .RadioLabel {
      margin-left: 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      color: $sub-text-color;
      cursor: pointer;
    }
    & .RadioIcon {
      position: absolute;
      margin-top: 3px;
    }
    & input[type="radio"] {
      cursor: pointer;
      opacity: 0;
    }
  }
}

.SecretContainer{
  position: relative;
  & .SecretIcon {
    position: absolute;
    top: 14px;
    right: 16px;
  }
  & .SecretLoading {
    position: absolute;
    top: 5px;
    right: 10px;
  }
}

.ErrorMessage {
  color: $danger-color;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}
