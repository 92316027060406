@import "../../../../assets/styles/color";

.SelectBox {
  flex: 1;
  width: 100%;
  padding: 0 16px;
  background-color: $main-container-bg-color;
  color: $widget-text-color;
  height: 38px;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid $border-color;
  box-sizing: border-box;
  outline: none;
  resize: none;
	
	&::-webkit-calendar-picker-indicator {
    filter: invert(0.75);
  }
  &::-webkit-calendar-picker-indicator:hover {
    filter: invert(0);
	}
}
input[type=date]::-webkit-calendar-picker-indicator:hover {
  background-color: $border-color;
}
.CalendarIcon {
	position: absolute;
	top: 9px;
	left: 18px;
}

.ViewButton {
	display: inline-block;
	width: 200px;
	margin-left: 20px;
}

.TableWrapper {
	margin: 30px 0;
	border-radius: 4px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
	border: 1px solid $border-color;
	background-color: $navigation-bg-color;
	overflow-x: auto;
	& table {
		border-collapse: collapse;
		width: 100%;
		text-align: left;
	}

	& tr {
		overflow-x: scroll;
	}
	
	& th, td {
		padding: 15px;
	}
	
	& th {
		font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $form-label-color;
	}

	& td {
		font-size: 14px;
    font-weight: 500;
	}
}

