@import "../../../../assets/styles/color";

.HeaderTableWrapper {
	border: 1px solid $border-color;
	  background-color: $navigation-bg-color;
    // display: flex;
    // align-items: center;
    padding: 20px 30px;
    & .LeftSection {
        flex: 1;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        & .HeaderText {
            font-size: 22px;
            font-weight: 600;
            color: $card-text-color;
            margin: 6px 0;
            margin-right: 30px;
        }
    }
}

.StatusLegendWrapper {
  display: flex;
  justify-content: flex-end;
  // @media screen and (min-width: 899px) {
  //   display: none;
  // }
  & .Legend {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 500;
    color: $card-text-color;
  }
  & .GreenDot {
    background-color: $green-color;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 6px;
  }
  & .BlueDot {
    background-color: $primary-color;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 6px;
  }
}

@media screen and (max-width: 900px) {
  .LeftSection {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .SearchInputWrapper {
    margin-top: 10px;
    width: 100%;
    
  }
  .SearchExpand {
    // margin-right: 10px !important;
  }
  .AutoFillBoxWrapper {
    display: flex;
    width: 100%;
  }
  .AutoFillBox {
    flex: 1;
    &:last-child {
      margin-right: 0;
    }
  }
}

.TableWrapper {
    // margin: 30px 0;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid $border-color;
    background-color: $navigation-bg-color;
    overflow-x: auto;
    margin-bottom: 100px;
    & table {
      border-collapse: collapse;
      width: 100%;
      text-align: left;
    }

    & tr {
      overflow-x: scroll;
      &.Selected {
        background-color: #4c84ff55 !important;
      }
      &.SelectedActual {
        border-left: 10px solid;
        &.Running {
          border-left-color: $primary-color;
        }
        &.Completed {
          border-left-color: $green-color;
        }
      }
      &.Unselected {
        color: $sub-text-color;
      }
      &.ActualRow {
        background-color: $main-container-bg-color;
        border-bottom: 1px solid $navigation-bg-color;
        &.Running {
          background-color: #4c84ff33;
          // background-color: #34aa4433;
        }
        &.Completed {
          background-color: #4c84ff33;
        }
        & td {
          padding: 15px;
          font-size: 13px;
        }
      }
      & .SwitchStartStopPo {
        cursor: pointer;
        height: 35px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.Start {
          background-color: $primary-color;
        }
        &.Stop {
          background-color: $danger-color;
        }
      }
    }
          
    & tbody tr:hover {
        background-color: #4c84ff11;
    }
    
    & th {
      padding: 15px;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      color: $form-label-color;
    }

    & td {
      font-size: 14px;
      font-weight: 500;
      padding: 25px 15px;
      box-sizing: border-box;
    }
}

.ActualStatusBox {
  display: inline-block;
  padding: 5px 10px;
  font-weight: 600;
  
  &.Running {
    color: $green-color;
  }
  &.Completed {
    color: $primary-color;
  }
}

.StatusBox {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 600;
    color: $main-text-color;
    &.Running {
      background-color: $green-color;
    }
    &.Completed {
      background-color: $primary-color;
    }
}

.AutoFillBox {
    background-color: $card-bg-color;
    padding: 6px 0;
    width: 100px;
    text-align: center;
    border-radius: 4px;
    color: $auth-title-color;
    font-size: 12px;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
    &.Selected {
      color: $main-text-color;
    }
  }

.SelectBox {
    flex: 1;
    width: 100%;
    padding: 10px 16px;
    background-color: $main-container-bg-color;
    color: $widget-text-color;
    // height: 38px;
    border-radius: 4px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid $border-color;
    box-sizing: border-box;
    outline: none;
    resize: none;
    &::-webkit-calendar-picker-indicator {
      filter: invert(0.75);
    }
    &::-webkit-calendar-picker-indicator:hover {
      filter: invert(0);
    }
}

.SearchInputWrapper {
  position: relative;
  display: flex;
  flex: 1;
  & .AdvancedSearch {
    font-weight: 500;
    font-size: 12px;
    text-decoration: underline;
    color: $primary-color;
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 7px;
  }
  & .SearchIconInput {
    position: absolute;
    top: 9px;
    left: 13px
  }
  & .SearchExpand {
    color: $card-text-color;
    font-family: inherit;
    font-weight: 500;
    font-size: 14px;
    -webkit-box-sizing: content-box;
    outline: none;
    border: solid 1px $border-color;
    padding: 5px 10px 5px 32px;
    width: 100%;
    // margin-right: 15px;
    background-color: $card-bg-color;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
  }
  // & .SearchExpand:focus {
  //   width: 100%;
  //   border-color: $primary-color;
  //   -webkit-box-shadow: 0 0 5px rgba(109,207,246,.5);
  //   -moz-box-shadow: 0 0 5px rgba(109,207,246,.5);
  //   box-shadow: 0 0 5px rgba(109,207,246,.5);
  //   padding-right: 125px;
  // }
  
  
  & input:-moz-placeholder {
    color: #999;
  }
  & input::-webkit-input-placeholder {
    color: #999;
  }
}

.StartPoIcon {
  width: 0; 
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 10px solid #fff;
  &.Big {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
  }
}
.StopPoIcon {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 1px;
  &.Big {
    width: 16px;
    height: 16px;
  }
}

.PrintButtonWrapper {
  background-color: #fff;
  border-radius: 4px;
  width: 40px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}