@import "../../../../assets/styles/color";

.CalibrationModal {
  position: relative;
  color: $auth-title-color;
  display: flex;
  flex-direction: column;

  & .HeaderWrapper {
    display: flex;
    border-bottom: 1px solid $card-text-color;
    padding: 24px 40px 24px 30px;
    align-items: center;

    & .Header {
      flex: 1;
      font-size: 20px;
      color: $auth-title-color;
      font-weight: 600;
      line-height: 1.5;
    }

    & .Description {
      font-size: 14px;
      line-height: 1.57;
    }
  }

  & .BodyWrapper {
    margin: 40px 30px;
    flex: 1;
    font-size: 16px;
    font-weight: 500;
  }

  & .FooterWrapper {
    display: flex;
    justify-content: space-evenly;
  }
}