@import '../../../../assets/styles/color';

.Privacy {
    color: $modal-text-color;
    padding: 0 30px;
    padding-top: 20px;
    letter-spacing: 0.5px;
    line-height: 25px;
    overflow: auto;
    height: 69vh;
    & .Sub {
        color: $sub-text-color;
        font-size: 12px;
        text-align: center;
    }
}

.FixedButtonsWrapper {
    width: 100%;
    bottom: 0;
    position: fixed;
    display: flex;
    justify-content: space-around;
    height: 80px;
}