.snackbar {
  visibility: hidden;
  min-width: 300px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  padding: 15px 20px;
  position: fixed;
  z-index: 900;
  right: 30px;
  top: 30px;
  font-size: 1rem;
}
.Success {
  background: #34aa44;
}
.Fail {
  background: #e6492d;
}

.Close{
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 5px;
}

.snackbar.show {
  visibility: visible;
  opacity: 1;
  -webkit-animation: fadein 0.3s;
  animation: fadein 0.3s;
}
.snackbar.hide {
  -webkit-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
}

.Message {
  margin: auto 0;
}

@-webkit-keyframes fadein {
  from {transform: scale(0.9); opacity: 0;}
  to {transform: scale(1); opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {transform: scale(1); opacity: 1;}
  to {transform: scale(0.9); opacity: 0;}
}


@keyframes fadein {
  from {transform: scale(0.9); opacity: 0;}
  to {transform: scale(1); opacity: 1;}
}


@keyframes fadeout {
  from {transform: scale(1); opacity: 1;}
  to {transform: scale(0.9); opacity: 0;}
}

