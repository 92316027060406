@import '../../../assets/styles/color';

.Team {
  & .TopSection {
    background-color: $navigation-bg-color;
    display: flex;
    border-bottom: 1px solid $border-color;
    & .MenuWrapper {
      flex: 1;
      display: flex;
      margin-left: 30px; 
      & .Menu {
        display: flex;
        align-items: center;
        border-bottom: 3px solid $primary-color;
        & .MenuTitle {
          font-size: 14px;
          font-weight: 600;
          color: $sub-text-color;
          line-height: 63px;
        }
      }
    }
    & .AddButtonWrapper {
      margin: 14px 30px;
      width: 200px;
    }
  }

  & .CardGridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(255px, 255px));
    grid-gap: 30px;
  }
}