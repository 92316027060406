@import '../../../../assets/styles/color';

.AddedWrapper {
  padding: 20px 30px;
  border-bottom: 1px solid $border-color;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.Selected {
    background-color: $main-container-bg-color;
  }

  & .SwitchWrapper {
    margin-right: 30px;
  }

  & .Name {
    font-size: 16px;
    font-weight: 600;
    color: $main-text-color;
    margin: 3px 0;

    &.Passed {
      color: $sub-text-color;
    }
  }

  & .Description {
    font-size: 14px;
    color: $sub-text-color;
  }

  & .TimeText {
    font-size: 12px;
    color: #d8d8d8;
  }
}