@import '../../../../assets/styles/color';

.OrgWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 20px 30px;
  border-bottom: 1px solid $card-text-color;
  margin-left: 5px;
  & .OrgLogo {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin: 0 20px 0 30px;
    // z-index: -1;
  }
  & .NameAndInformation {
    flex: 1;
    // z-index: -1;
    & .Name {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.57;
      color: $auth-title-color;
    }
    & .OtherInformation {
      font-size: 14px;
      line-height: 1.57;
      color: $sub-text-color;
    }
  }
  & .RightSection {
    display: flex;
    & .CurrentOrg {
      border-radius: 4px;
      color: $main-text-color;
      background-color: $primary-color;
      padding: 5px 10px;
      font-size: 12px;
      font-weight: 600;
    }
    & .OrgIconButton{
      padding: 0px 0px 0px 20px;
      margin: auto;
    }
  }
}

.FormField {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  font-family: inherit;
  width: 250px;
  margin-right: 20px;
  padding: 9px 16px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px $auth-input-border-color;
  transition: border-color .15s ease-in-out;
  box-sizing: border-box;
  outline: none;
  resize: none;
  &:hover, &:focus {
    border-color: $primary-color;
  }
}
.WithIcon {
  padding-left: 48px;
}
.Icon {
  position: absolute;
  top: 10px;
  left: 16px;
}