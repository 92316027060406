
@import "../../../../assets/styles/color";

.FormGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    & .SelectBox {
        flex: 1;
        width: 100%;
        padding: 10px 16px;
        background-color: $main-container-bg-color;
        color: $widget-text-color;
        // height: 38px;
        border-radius: 4px;
        font-family: inherit;
        font-size: 14px;
        font-weight: 500;
        border: 1px solid $border-color;
        box-sizing: border-box;
        outline: none;
        resize: none;
        &::-webkit-calendar-picker-indicator {
          filter: invert(0.75);
        }
        &::-webkit-calendar-picker-indicator:hover {
          filter: invert(0);
        }
    }
    & .CalendarIcon {
      position: absolute;
      top: 11px;
      left: 18px;
    }
    & .MachineBoxWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(110px,110px));
        grid-gap: 8px;
        & .MachineBox {
            cursor: pointer;
            border: 1px solid $form-label-color;
            background-color: $main-container-bg-color;
            border-radius: 4px;
            height: 96px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            &.Selected {
              border: 1px solid $primary-color;
              background-color: #4c84ff77;
            }
            &.Disabled {
              background-color: $form-label-color;
              opacity: 0.5;
            }
        }
    }
}

label.Title {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: $form-label-color;
  margin-bottom: .7rem;
}

.InfosGroup {
  display: flex;
  margin-bottom: 16px;
  align-items: baseline;
  & .InfoValue {
    flex: 1;
    font-size: 14px;
    font-weight: 600;
    color: $main-text-color;
  }
}

.HistoriesWrapper {
  border-radius: 4px;
  border: solid 1px $border-color;
  background-color: $border-color;
  margin-bottom: 16px;    
}

.BoxWrapper {
  display: flex;
  cursor: pointer;
  // border-radius: 4px;
  // border: solid 1px $border-color;
  // background-color: $border-color;
  user-select: none;
  padding: 12px;
  color: $sub-text-color;
  & .Title {
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  & .ArrowIcon {
    flex: 0;
    transform: rotate(0deg);
    transition: transform 0.15s ease-in;
    &.Open {
      transform: rotate(180deg);
    }
    & img{
      margin: 0;
    }
  }
}