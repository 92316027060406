@import '../../../assets/styles/color';

.AdvanceProductionPlans {
  & .TopSection {
    height: 68px;
    background-color: $navigation-bg-color;
    display: flex;
    border-bottom: 1px solid $border-color;
    & .MenuWrapper {
      flex: 1;
      display: flex;
      margin-left: 30px; 
      & .ActiveMenu {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: 3px solid $primary-color;
        margin-right: 32px;
        & .MenuTitle {
          font-size: 14px;
          font-weight: 600;
          color: $sub-text-color;
        }
      }
      & .Menu {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 32px;
        border-bottom: 3px solid $navigation-bg-color;
        & .MenuTitle {
          font-size: 14px;
          color: $form-label-color;
        }
      }
    }
    & .AddButtonWrapper {
      position: relative;
      margin: 14px 30px;
      width: 200px;
    }
  }

  & .MainContentPadding{
    padding: 0px;
  }
}

.SuccessText {
  color: $green-color;
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
}

.DropdownWrapper {
  position: relative;
  display: flex;
  & .Selected {
    font-size: 14px;
    font-weight: 600;
    color: $modal-text-color;
    background-color: $main-text-color;
    min-width: 170px;
    align-self: center;
    border-radius: 4px;
    padding: 7px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.DropdownMenu {
  position: absolute;
  z-index: 1000;
  top: 54px;
  left: 27px;
  width: 203px;
  box-sizing: border-box;
  border: 1px solid $main-container-bg-color;
  border-radius: 4px;
  background-color: $border-color;

  & .List {
    border-bottom: 1px solid $main-container-bg-color;
    display: block;
    cursor: pointer;
    color: $main-text-color;
    &:hover {
      color: $green-color;
    }
    & .Wrapper {
      padding: 10px 25px;
      & .Label {
        margin-left: 40px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        cursor: pointer;
        user-select: none;
      }
      & .Icon {
        position: absolute;
        margin-top: 2px;
      }
    }
  }
}

.Arrow {
  position: absolute;
  height: 7px;
  width: 12px;
  top: 15px;
  right: 20px;
  transform: translateY(-50%);
  transform: -moz-translateY(-50%);
  transform: -webkit-translateY(-50%);
  transition: transform 0.15s ease-in;
  transform: rotate(0);
  &.Up {
    transform: rotate(180deg);
  }
}

.ScreenButton {
  cursor: pointer;
  margin: auto 35px auto 0;
  & .ScreenWrapper {
    display: flex;
    & .ScreenTitle {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 500;
      color: $sub-text-color;
    }
  }
}
.MinimizeScreen {
  display: block;
}

.FullScreen {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100%);
  z-index: 1000;
  min-height: calc(100% - 70px);
  background-color: $main-container-bg-color;
  min-height: calc(100vh - 60px);
}