@import "../../../assets/styles/color";

.TopSection {
  background-color: $navigation-bg-color;
  display: flex;
  border-bottom: 1px solid $border-color;
  & .MenuWrapper {
    flex: 1;
    display: flex;
    margin-left: 30px; 
    & .Menu {
      display: flex;
      align-items: center;
      border-bottom: 3px solid $primary-color;
      & .MenuTitle {
        font-size: 14px;
        font-weight: 600;
        color: $sub-text-color;
        line-height: 63px;
      }
    }
  }
  & .AddButtonWrapper {
    margin: 14px 30px;
    width: 200px;
  }
}

.FilterBar {
  width: 100%;
  display: flex;
  & .FilterName {
    margin: 0 16px 30px 16px;
    display: flex;
    & .Name {
      font-size: 14px;
      font-weight: 500;
      color: $sub-text-color;
      margin: auto 20px;
    }
  }
  & .FilterLine {
    flex: 1;
    width: 100%;
    height: 1px;
    background-color: $navigation-bg-color;
    margin: 10px 0 0 0;
  }
  & .FilterButton {
    cursor: pointer;
    margin: 0 16px 30px 16px;
    flex: 0;
  }
}

.CardGridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, 17vw);
  grid-gap: 30px;
}

.CardGridMobileContainer {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px;
}