@import "../../../assets/styles/color";

.TopSection {
    height: 45px;
    background-color: $navigation-bg-color;
    display: flex;
    border-top: 1px solid $border-color;
    & .MenuWrapper {
      flex: 1;
      display: flex;
      overflow: auto;
      margin-right: 15px;
      & .ActiveMenu {
        cursor: pointer;
        display: flex;
        align-items: center;
        background-color: $primary-color;
        margin-right: 32px;
        border-radius: 4px;
        padding: 0 40px;
      & .MenuTitle {
          font-size: 14px;
          font-weight: 600;
          color: $main-text-color;
        }
      }
      & .Menu {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 32px;
        // border-bottom: 3px solid $navigation-bg-color;
        padding: 0 40px;

        & .MenuTitle {
          font-size: 14px;
          color: $form-label-color;
        }
      }
    }
}

.BottomMenuBar {
  position: fixed;
  bottom: 0;
  height: 80px;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  background-color: $navigation-bg-color;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.404);
  & .MenuWrapper {
    flex: 1;
    color: $sub-text-color;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.Selected {
      color: $primary-color;
    }
  }
}

.WrapperForSnackbar {
  position: fixed;
  right: 0;
  width: 420px;
  bottom: 90px;
  z-index: 1001;
  max-height: 500px;
  overflow: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
}

@media screen and (max-width: 900px) {
  .ActiveMenu, .Menu {
    margin-right: 16px !important;
  }
}