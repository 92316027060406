@import '../../../../../assets/styles/color';

.Text {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);

  & .Value {
    font-size: 52px;
    font-weight: 500;
    color: $primary-color;
    &.Small {
      font-size: 1.75vw;
    }
  }

  & .Unit {
    font-size: 18px;
    color: $sub-text-color;
  }
}