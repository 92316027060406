@import '../../../../assets/styles/color';

.RepeatingConfirmModal {
  margin-top: 77px;
  color: $modal-text-color;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 99;
  & .Title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.21;
    margin-bottom: 34px;
  }
  & .Description {
    font-size: 16px;
    line-height: 1.6;
    opacity: 0.7;
  }
  & .ButtonsWrapper {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & .YesButtonWrapper {
    width: 175px;
  }
  & .CancelButton {
    font-size: 14px;
    color: $form-label-color;
    cursor: pointer;
  }
}

.Background {
  height: 357px;
  width: 100%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
}