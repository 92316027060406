
@import "../../../../assets/styles/color";

.FormGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    & .SelectBox {
        flex: 1;
        width: 100%;
        padding: 10px 16px;
        background-color: $main-container-bg-color;
        color: $widget-text-color;
        // height: 38px;
        border-radius: 4px;
        font-family: inherit;
        font-size: 14px;
        font-weight: 500;
        border: 1px solid $border-color;
        box-sizing: border-box;
        outline: none;
        resize: none;
        &::-webkit-calendar-picker-indicator {
          filter: invert(0.75);
        }
        &::-webkit-calendar-picker-indicator:hover {
          filter: invert(0);
        }
    }
    & .CalendarIcon {
      position: absolute;
      top: 11px;
      left: 18px;
    }
    & .MachineBoxWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(110px,110px));
        grid-gap: 8px;
        & .MachineBox {
            cursor: pointer;
            border: 1px solid $form-label-color;
            background-color: $main-container-bg-color;
            border-radius: 4px;
            height: 96px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            &.Selected {
              border: 1px solid $primary-color;
              background-color: #4c84ff77;
            }
            &.Disabled {
              background-color: $form-label-color;
              opacity: 0.5;
            }
        }
    }
}

label.Title {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: $form-label-color;
  margin-bottom: .7rem;
}

.InfosGroup {
  display: flex;
  margin-bottom: 16px;
  align-items: baseline;
  & .InfoValue {
    flex: 1;
    font-size: 14px;
    font-weight: 600;
    color: $main-text-color;
  }
}

.HistoriesWrapper {
  border-radius: 4px;
  border: solid 1px $border-color;
  background-color: $border-color;
  margin-bottom: 16px;    
}

.BoxWrapper {
  display: flex;
  cursor: pointer;
  // border-radius: 4px;
  // border: solid 1px $border-color;
  // background-color: $border-color;
  user-select: none;
  padding: 12px;
  color: $sub-text-color;
  & .Title {
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  & .ArrowIcon {
    flex: 0;
    transform: rotate(0deg);
    transition: transform 0.15s ease-in;
    &.Open {
      transform: rotate(180deg);
    }
    & img{
      margin: 0;
    }
  }
}

.HistoriesInfo {
  border-top: solid 1px $navigation-bg-color;
  background-color: $border-color;
  color: $form-label-color;
  font-size: 14px;
  font-weight: 500;
  padding: 12px;
  & .HistoriesRow {
    display: flex;
    align-items: center;
    margin: 7px 0;
    & img {
      width: 18px;
      height: 20px;
      margin-right: 6px;
    }
  }
  & .HistoriesForTotal {
    color: #acb1c0;
    background-color: #eaedf322;
    text-align: center;
    padding: 5px;
  }
  & .HistoriesForGood {
    flex: 1;
    color: $green-color;
    background-color: #34aa4422;
    text-align: center;
    padding: 5px;
  }
  & .HistoriesForDefect {
    flex: 1;
    color: $danger-color;
    background-color: #e6492d22;
    text-align: center;
    padding: 5px;
  }
  & span {
    color: $card-text-color;
  }
}

.AddProdBtn {
  color: $primary-color;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  border: 1px dashed $primary-color;
  padding: 10px 0;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & .AddIcon {
    margin-right: 10px;
    vertical-align: middle;
  }
  & .ButtonName {
    display: inline-block;
    user-select: none;
  }
}

.DocumentWrapper {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 5px;
  border-bottom: 2px solid $border-color;
  & .Title {
    flex: 1;
    font-weight: 600;
    font-size: 14px;
  }
  & .Icon {
    cursor: pointer;
    margin-left: 16px;
    width: 16px;
    vertical-align: middle;
  }
}

.PrintButtonWrapper {
  background-color: $main-text-color;
  padding: 9px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  margin-bottom: 16px;
  a {
    text-decoration: none;
    color: #000;
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
  }
}

.SelectBox {
  flex: 1;
  width: 100%;
  padding: 0 16px;
  background-color: $main-container-bg-color;
  color: $widget-text-color;
  height: 38px;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid $border-color;
  box-sizing: border-box;
  outline: none;
  resize: none;
    
    &::-webkit-calendar-picker-indicator {
    filter: invert(0.75);
  }
  &::-webkit-calendar-picker-indicator:hover {
    filter: invert(0);
    }
}
input[type=date]::-webkit-calendar-picker-indicator:hover {
  background-color: $border-color;
}
