@import "../../../../assets/styles/color";

.OrganizationModal {
  position: relative;
  color: $auth-title-color;
  display: flex;
  flex-direction: column;
  & .HeaderWrapper {
    display: flex;
    border-bottom: 1px solid $card-text-color;
    padding: 24px 40px 24px 30px;
    align-items: center;
    & .Header {
      flex: 1;
      font-size: 20px;
      color: $auth-title-color;
      font-weight: 600;
      line-height: 1.5;
    }
    & .Description {
      font-size: 14px;
      line-height: 1.57;
    }
  }

  & .FormWrapper {
    flex: 1;
    padding: 30px 30px 20px 30px;
  }

  & .ButtonWrapper {
    padding: 20px 30px;
    border-top: 1px solid $card-text-color;
    height: 38px;

    & .ActionButton {
      display: inline-block;
      box-sizing: border-box;
      margin-left: 10px;
      &.SaveBtn {
        width: 230px;
      }
      &.DeleteBtn {
        width: 180px;
      }
    }
  }

  & .SignoutBuffon {
    width: calc(100% - 20px);
    margin-top: 20px;
    margin-left: 10px;
  }

  & .InstructionWrapper {
    padding: 30px 20px 40px 20px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 17px;
    justify-content: space-between;
    & .InstructionCard {
      //   flex: 1;
      //   min-width: 300;
      border: 2px solid #e2e5ed88;
      border-radius: 5px;
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      & .Image {
        // display: block;
        border-radius: 3px;
        margin: 8px 8px 0px 8px;
        max-height: 150px;
      }
      & .CardBody {
        display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        & .Divider {
          width: 2px;
          height: 45px;
          background-color: #eeeeee;
          margin-right: 15px;
        }
        & .CardStep {
          flex: 0 0 75px;
          text-align: center;
          & .CardSubTitle {
            color: $sub-text-color;
            font-size: 10px;
            font-weight: 600;
          }
          & .CardTitle {
            font-size: 30px;
            // margin-bottom: 15px;
            font-weight: 600;
          }
        }
        & .CardDescribeBody {
          text-align: left;
          //   align-content: center;
          margin: auto 15px auto 5px;
          //   padding:auto ;
          //   padding-top: 5px;
          padding-left: 5px;
          & .CardDescribe {
            font-size: 12px;
          }
          & .CardLink {
            padding-top: 10px;
            font-size: 11px;
            color: blue;
            & a {
              text-decoration: none;
            }
            & a:visited {
              color: blue;
            }
          }
        }
      }
    }
  }
}

.None {
  display: none;
}

.FormGroup {
  margin-bottom: 1rem;
  flex: 1;
}

.SwitchButton {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  background: none;
  cursor: pointer;
  user-select: none;
  border: none;
  color: $primary-color;
  & .IconButton {
    vertical-align: text-bottom;
    margin-right: 10px;
  }
}

.FormAddLogo {
  & input[type="file"] {
    display: none;
  }
  display: flex;
  border-bottom: 1px solid $card-text-color;
}

.CustomFileUpload {
  margin: 15px 0px;
  display: inline-block;
  padding: 0px;
  cursor: pointer;
  & .ImagePreview {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid $card-text-color;
  }
}

.AddLogo {
  margin: auto 0px 15px 15px;
  font-size: 14px;
  font-weight: 500;
}

.ShowSelectedFile {
  font-size: 14px;
  padding: 25px 10px;
  margin: auto 0px;
}

.TooltipButtonsContainer {
  display: flex;
  margin-top: 30px;
  & .TooltipButtonWrapper {
    flex: 1;
    margin: 0 20px;
  }
}

.ErrorMessage {
  color: $danger-color;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}
