@import '../../../../assets/styles/color';

.Auth {
  display: flex;
  height: 100%;
}

.LeftSection {
  flex: 1;
  & .AuthWrapper {
    padding: 100px 70px 40px 70px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    position: relative;
    & .AuthTitle {
      font-size: 22px;
      font-weight: bold;
      color: $auth-title-color;
    }
    & .AuthSubTitle {
      color: $sub-text-color;
      margin: 5px 0 20px 0;
    }
    & .FormContainer {
      display: flex;
      flex-direction: column;
      height: 100%;
      color: $sub-text-color;
      & .GewNewpassword {
        color: $primary-color;
        font-size: 12px;
        font-weight: 500;
        margin-top: 5px;
        cursor: pointer;
      }
    }
  }
}

.RightSection {
  width: 524px;
  background-color: black;
  position: relative;
  background-size: contain;
  & .Testimonial {
    position: absolute;
    bottom: 40px;
    left: 40px;
  }
  & .WorldIcon {
    vertical-align: middle;
    margin-right: 12px;
  }
  & .CeoImage {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 12px;
  }
}

.FormGroup {
  margin-bottom: 30px;
}

.SuccessMessage {
  color: $green-color;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}

.ErrorMessage {
  color: $danger-color;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}

.ForgetBg {
  width: 360px;
}

@media screen and (max-width: 1024px) {
  .ForgetBg {
    width: 320px;
    background-size: cover;
  }
}