@import '../../../../../assets/styles/color';

.MachineBox {
  min-width: 330px;
  background-color: $navigation-bg-color;
  border-radius: 15px;
    & .Top {
      padding: 20px 0;
      text-align: center;
      &.Gray {
        background-color: $navigation-bg-color;
      }
      &.Green {
        background-color: #34aa4444;
      }
      &.Red {
        background-color: #ea272a44;
      }
      & .Name {
        font-size: 26px;
        font-weight: 500;
        line-height: 1.56;
        color: $main-text-color;
        text-shadow: 1px 1px 4px #222226;
      }
      & .MachineStatusText {
        font-size: 14px;
        font-weight: 600;
        padding: 5px 0;
        margin-top: 6px;
      }
    }
    & .Description {
      font-size: 14px;
      color: $main-text-color;
      & span {
        color: $main-text-color;
        font-weight: 500;
        font-size: 16px;
      }
    }
    & .APQTextWrapper {
      display: inline-block;
      border-right: 2px solid rgb(23, 23, 23);
      padding-right: 5px;
      margin-right: 5px;
    }
    & .APQTextWrapper:last-child {
      display: inline-block;
      border: none;
      padding: 0;
      margin: 0;
    }
    & .Middle {
      padding-bottom: 15px;
      &.Gray {
        background-color: $navigation-bg-color;
      }
      &.Green {
        background-color: #34aa4444;
      }
      &.Red {
        background-color: #ea272a44;
      }
      & .GaugeWrapper {
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        & .GaugeValue {
          text-align: center;
          font-size: 22px;
          font-weight: 500;
          color: $main-text-color;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          & .Sub {
            color: $sub-text-color;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
      // & .TargetsInfoWrapper {
      //   flex: 1;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: center;
      //   & .TargetRow {
      //     margin-bottom: 15px;
      //     margin-right: 30px;
      //     & .TargetTitle {
      //       font-size: 12px;
      //       font-weight: 600;
      //       color: $sub-text-color;
      //       margin-bottom: 6px;
      //     }
      //     & .TargetValue {
      //       border-radius: 4px;
      //       border: solid 1px $border-color;
      //       background-color: $main-container-bg-color;
      //       color: $card-text-color;
      //       font-size: 14px;
      //       font-weight: 500;
      //       padding: 6px 15px;
      //     }
      //   }
      // }
    }
    & .Bottom {
      padding: 15px;
      min-height: 120px;
      // border-bottom-left-radius: 4px;
      // border-bottom-right-radius: 4px;
      &.Gray {
        background-color: $card-bg-color
      }
      &.Green {
        background-color: #34aa4477;
      }
      &.Red {
        background-color: #ea272a77;
      }
    }
  }
  