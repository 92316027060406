@import '../../../../assets/styles/color';

.AnomalyListWrapper {
  padding: 20px 30px;
  border-bottom: 1px solid $border-color;
  display: flex;
  align-items: center;
  cursor: pointer;
  &.Selected {
    background-color: $main-container-bg-color;
  }
  & .SwitchWrapper {
    margin-right: 30px;
  }
  & .Name {
    font-size: 16px;
    font-weight: 600;
    color: $main-text-color;
    margin: 3px 0;
  }
  & .Description {
    font-size: 14px;
    color: $sub-text-color;
  }

  & .LastTriggerText {
    font-size: 12px;
    color: #d8d8d8;
    margin-right: 10px;
    text-align: center;
  }

  & .HistoryIconWrapper {
    background: none;
    border-radius: 20px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    &:hover {
      background-color: $border-color;
    }
  }
}
